/**
 * @generated SignedSource<<ab8135cce81bb48c21b7a573ad3a3d22>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetUserCurrentPlanFragment$data = {
  readonly current_plan: number | null;
  readonly current_plan_status: any | null;
  readonly subs_mainplans: {
    readonly discount_amount: any;
    readonly order: any;
  } | null;
  readonly trialstart_ts: any | null;
  readonly " $fragmentType": "GetUserCurrentPlanFragment";
};
export type GetUserCurrentPlanFragment$key = {
  readonly " $data"?: GetUserCurrentPlanFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetUserCurrentPlanFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetUserCurrentPlanFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "current_plan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "current_plan_status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trialstart_ts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "subs_mainplans",
      "kind": "LinkedField",
      "name": "subs_mainplans",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "order",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "discount_amount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "subs",
  "abstractKey": null
};

(node as any).hash = "6f1f9775a1ce370864cd5bd55435a325";

export default node;
