import React, { useMemo } from 'react';
import {
  PayPalCardFieldsProvider,
  PayPalNumberField,
  PayPalExpiryField,
  PayPalCVVField,
  PayPalNameField
} from '@paypal/react-paypal-js';
import * as Sentry from '@sentry/react';
import {
  useTheme, useMediaQuery, Grid2Ct, Grid2,
} from '@languageconvo/wcl';
import { SubmitPayment } from './SubmitPayment';
import { useCapturePayment } from '../../hooks/useCapturePayment';

// payPalFields component renders PayPal hosted fields and a payment submit button
// memoize the PayPal fields to prevent unnecessary re-renders and maintain consistent field state
export const PayPalFields = ({
  createOrderMutation
}: any) => {
  const {
    commitCaptureMutation,
  } = useCapturePayment();

  // #region styles for the hosted fields

  // IMPORTANT #1: if you edit these styles, dont forget to edit the styles of the first and
  //  last name inputs. they are not "hosted fields" so do not get these styles
  //
  // IMPORTANT #2: we have to set some styles here, but others have to be set on the fields
  //  themselves. the only way to really know where to set which is trial and error. the
  //  PayPalHostedFields is the other place styles are set
  //
  // IMPORTANT #3: not all css styles are supported. see: 
  // https://developer.paypal.com/docs/checkout/advanced/style-card-fields/
  // for a list. for example, we have to use outline instaed of borderColor
  const theme = useTheme();
  const fontColor = `hsl(${theme.ourTheme.colors.text.base})`;
  const screensizeMd = useMediaQuery(theme.breakpoints.up('md'));
  let theFontSize = `${theme.ourTheme.typography.p.fontSizeRemSmallscrn}rem`;
  if (screensizeMd) {
    theFontSize = `${theme.ourTheme.typography.p.fontSizeRem}rem`;
  }
  const theFont = `${theFontSize} ${theme.ourTheme.typography.fontFamily}`;

  const bgColor = `hsla(${theme?.ourTheme.colors.app.page}, .6)`;
  const styleObj = {
    // @ts-ignore
    width: '100%',
    background: bgColor,
    height: '53.13px',
    'border-width': '1px !important',
    border: `1px solid ${theme.ourTheme.colors.cp.divider.thin} !important`,
    borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px !important`,
    paddingTop: '15px !important',
    paddingBottom: '15px !important',
    paddingLeft: '14px !important',
    paddingRight: '14px !important',
    'box-shadow': 'none !important',
    font: theFont,
    color: fontColor,
  };

  // #endregion

  function onApprove() {
    commitCaptureMutation();
  }

  function onError(e: any) {
    Sentry.captureException(
      new Error('in the new purchase flow, something went wrong'),
    );
    Sentry.captureException(e);
  }

  const HostedFields = useMemo(() => (

    <PayPalCardFieldsProvider
      style={{
        // @ts-ignore
        padding: 'none',
        '.invalid': { color: '#dc3545' },
        input: {
          // @ts-ignore
          font: theFont,
          color: fontColor,
        },
      }}
      createOrder={
        async () => {
          // eslint-disable-next-line no-console
          console.log('create order!');
          const paymentId = await createOrderMutation();
          if (paymentId !== null) {
            return paymentId;
          }
          // this will happen only if the backend returned any error in case of createOrderMutation
          return '';
        }
      }

      // eslint-disable-next-line
      onApprove={onApprove}

      // eslint-disable-next-line
      onError={onError}
    >
      {/* card number, cvv, expiry date */}
      {/* <PayPalHostedFields
        PayPalHostedField={PayPalHostedField}
      /> */}
      <Grid2Ct sx={{ mt: { xs: 0, sm: 2 } }}>
        {/* card number */}
        <Grid2
          xs={6}
          sx={{
            // the paypal <input> field has a div wrapper that adds some outside margin to the
            // left and right. so we change (decrease) our left and right padding of its container
            // to try and match our own input fields for first and last name. additionally, we
            // removed top and bottom padding because it was way too much vertical spacing
            pl: '2px', pr: '2px', pt: 0, pb: 0
          }}
        >
          <PayPalNumberField
            placeholder="4111-1111-1111-1111"
            style={{
              input: { ...styleObj },
            }}
          />
        </Grid2>
        <Grid2
          xs={6}
          sx={{
            pl: '2px', pr: '2px', pt: 0, pb: 0
          }}
        >
          <PayPalExpiryField
            placeholder="MM / YY"
            style={{
              input: { ...styleObj },
            }}
          />
        </Grid2>
        <Grid2
          xs={6}
          sx={{
            pl: '2px', pr: '2px', pt: 0, pb: 0
          }}
        >
          <PayPalCVVField
            placeholder="CVV"
            style={{
              input: { ...styleObj },
            }}
          />
        </Grid2>

        <Grid2
          xs={6}
          sx={{
            pl: '2px', pr: '2px', pt: 0, pb: 0
          }}
        >
          <PayPalNameField
            placeholder="Name on Card"
            style={{
              input: { ...styleObj },
            }}
          />
        </Grid2>
      </Grid2Ct>

      <SubmitPayment />
    </PayPalCardFieldsProvider>

    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  return HostedFields;
};
