import { graphql } from 'babel-plugin-relay/macro';

export const GroupLessonsSubscription = graphql`
  subscription GroupLessonsSubscription(
    $currentTime: timestamptz
    $endTime: timestamptz
    $langId: Int
  ) {
    ...GroupLessonsFragment
  }
`;

export const GroupLessonsFragment = graphql`
  fragment GroupLessonsFragment on subscription_root {
    appt_group_connection(
      where: {
        language_id: { _eq: $langId }
        status: { _eq: "0" }
        ends_at: {_gt: $currentTime},
        starts_at: {_lt: $endTime}
      }
      order_by: { starts_at: asc }
    ) {
      edges {
        node {
          uuid
          starts_at
          ends_at
          duration_minutes
          max_students
          num_reservations
          type
          type_conversational_level
          skills {
            description_en
            display_order
            title_en
            uuid
            skills_levels {
              display_order
              title_en
              skills_categories {
                title_en
              }
            }
          }
          appt_group_reservations(where: { canceled_at: { _is_null: true } }) {
            reserved_at
          }
          resources {
            title
          }      
        }
      }
    }
  }
`;
