import { graphql } from 'babel-plugin-relay/macro';

export const PurchasePayQuery = graphql`
  query PurchasePayQuery($paypal_payment_id: Int!) {
    ...GetPackDetails @arguments(paypal_payment_id: $paypal_payment_id)

    users_connection {
      edges {
        node {
          # the relaySubscription data, which is what the pay page will actually use to determine if the user
          # gets a discount on their purchase
          ...PurchasePaySubsdtFragment

          # this is our standard, app-level fragment for getting user's subscription information
          # this data is what's used to calculate relaySubscription. note that we get this data already in an
          # app-level subscription, but we're going to get it here again just to be sure we have updated data
          # from the db (in case the app-level subscription failed)
          ...UserSubsDetailsFragment
        }
      }
    }
  }
`;

export const PurchasePaySubsdtFragment = graphql`
  fragment PurchasePaySubsdtFragment on users {
    relaySubscription
  }
`;
