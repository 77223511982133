import React from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { useOutletContext } from 'react-router-dom';
import { GetUserRecentActionQuery } from './relay/GetUserRecentAction';
import { ManageSubscription } from './ManageSubscription2';
import { GetUserCurrentPlanFragment } from '../../../layouts/dashboard/relay/GetUserCurrentPlan';
import { SuspenseLoading } from './ManageSubscription0';

// TODO: we need to replace useOutletContext which gets the user's subscription data, with the new
//  way of using our relaySubscription resolver to get the user's subscription details

export const GetUserCurrentPlanData = ({ currentTs }: any) => {
  // #region executing queries

  // we have implemented this network-only query because whenever user lands on
  // this page from any other page we want to get the fresh data from our backend
  // this is query to `subs_mainupdate_history` table to get the user recent action
  // like(upgrade/downgrade/cancellation).
  const response: any = useLazyLoadQuery(
    GetUserRecentActionQuery,
    {},
    {
      fetchPolicy: 'network-only',

      // fetchKey is VERY IMPORTANT -- it must be set to a unique value to ensure we always
      // go to the network to get fresh data
      fetchKey: currentTs,
    },
  );

  // #endregion

  // #region for reading data 

  // reading the response to get the fragmentRefForHistoryTable. note that for users who have
  // never subscribed, edges[] will be an empty array, thus the ? after that property
  const fragmentRefForHistoryTable = response
    .subs_details_connection.edges[0]?.node.subs_mainupdate_history_connection;

  // as we have executed the subscription to subs table in the dashboard layout so
  // reading the fragmentRef `userCurrentPlanSubscriptionFrgamentRef` from outletContext, 
  const { userCurrentPlanSubscriptionFrgamentRef }: any = useOutletContext();

  // reading the data using userCurrentPlanSubscriptionFrgamentRef and 
  // GetUserCurrentPlanFragment from subs table.
  const userCurrentPlanSubscriptionData = useFragment(
    GetUserCurrentPlanFragment,
    userCurrentPlanSubscriptionFrgamentRef,
  );

  // #endregion

  return (
    userCurrentPlanSubscriptionData !== null ? (
      // reason for waiting this component is to make sure, subsciption data is avaialble as 
      // ManageSubscription component using current_plan vlaues which should be there
      <ManageSubscription
        fragmentRefForHistoryTable={fragmentRefForHistoryTable}
        userCurrentPlanSubscriptionData={userCurrentPlanSubscriptionData}
      />
    ) : <SuspenseLoading />
  );
};
