import React from 'react';
import {
  Button,
  CardStandard, Grid2, Grid2Ct, IcSvgList, Ty,
} from '@languageconvo/wcl';
import { Trans, useLingui } from '@lingui/react/macro';
import { SubscriptionStatus, SubscriptionPendingChange, SubscriptionCurrentPlan } from '../../ManageSubscriptionTypes';
import { useRecordUserActionToHistoryTable } from '../../hooks/useRecordUserAction';
import { useOurSubscriptions } from '../../../../../common/utils/subscriptions/useOurSubscriptions';
import { OurSubPlanDetails } from '../../../../../common/utils/subscriptions/OurSubscriptionsTypes';

interface Props {
  subStatus: SubscriptionStatus;
  subPendingChange: SubscriptionPendingChange;
  subCurrent: SubscriptionCurrentPlan;
}

export const NotificationManager = ({
  subStatus,
  subPendingChange,
  subCurrent,
}: Props) => {
  const { plan1Details } = useOurSubscriptions();

  return (
    <>
      {/* first, we'll show an alert at the top of the page in a few special scenarios based
        on the *status* of the subscription:
        trial period, payment is past due, and incomplete */}
      {subStatus === SubscriptionStatus.Trialing && (
      <Trialing />
      )}
      {subStatus === SubscriptionStatus.PastDue && (
      <PastDue />
      )}
      {subStatus === SubscriptionStatus.Incomplete && (
      <Incomplete />
      )}

      {/* next, we'll show a notif based on if the user has any pending changes */}
      {/* pending upgrade */}
      {subPendingChange === SubscriptionPendingChange.Upgrade && (
      <PendingUpgrade planOneDt={plan1Details} />
      )}
      {/* pending downgrade */}
      {subPendingChange === SubscriptionPendingChange.Downgrade && (
      <PendingDowngrade plan={subCurrent} />
      )}
      {/* pending cancelation */}
      {subPendingChange === SubscriptionPendingChange.Cancel && (
      <PendingCancelation plan={subCurrent} />
      )}
    </>
  );
};

// #region components for subscription status

// subscription status is "free trial period"
const Trialing = () => {
  const { t } = useLingui();
  return (
    <Grid2Ct sx={{ mb: 1 }}>
      <Grid2 xs={12}>
        <CardStandard
          titleText={t`Free Trial Period`}
          titleIcon={IcSvgList.star1}
          color="accentGreen1"
          titleIconRight={0}
        >
          <Ty cp={{ sx: { mt: 2 } }}>
            <Trans>
              Enjoy your free trial! The free trial ends (and you will be
              charged unless you cancel below) 48 hours after you first subscribed.
              You can click the
              Subscription History area below to see your payment details, including exactly
              when your trial started and will end.
            </Trans>
          </Ty>
        </CardStandard>
      </Grid2>
    </Grid2Ct>
  );
};

// subscription status is "past due"
const PastDue = () => {
  const { t } = useLingui();
  return (
    <Grid2Ct sx={{ mb: 1 }}>
      <Grid2 xs={12}>
        <CardStandard
          titleText={t`Payment Past Due`}
          titleIcon={IcSvgList.trafficcone1}
          color="accentGreen1"
          titleIconRight={0}
        >
          <Ty cp={{ sx: { mt: 2 } }}>
            <Trans>
              It looks like your payment is past due. Please click the Update Payment Method
              section below to fix the issue.
            </Trans>
          </Ty>
        </CardStandard>
      </Grid2>
    </Grid2Ct>
  );
};

// subscription status is "incomplete"
const Incomplete = () => {
  const { t } = useLingui();
  return (
    <Grid2Ct sx={{ mb: 1 }}>
      <Grid2 xs={12}>
        <CardStandard
          titleText={t`Payment Processing`}
          titleIcon={IcSvgList.clock1}
          color="accentGreen1"
          titleIconRight={0}
        >
          <Ty cp={{ sx: { mt: 2 } }}>
            <Trans>
              We&apos;re attempting to process your payment, which normally takes just a few
              minutes. If after a few hours you still see this message that might indicate
              we are having trouble charging you; in that case, click
              below to check your payment details and ensure everything looks correct.
            </Trans>
          </Ty>
        </CardStandard>
      </Grid2>
    </Grid2Ct>
  );
};

// #endregion

// #region components for pending updates to the subscription

// user has a pending upgrade
interface PendingUpgradeInterface {
  planOneDt: OurSubPlanDetails
}
const PendingUpgrade = ({ planOneDt } : PendingUpgradeInterface) => {
  const { recordUserActionToHistoryTable, isLoading } = useRecordUserActionToHistoryTable();
  const { t } = useLingui();

  return (
    <Grid2Ct sx={{ mb: 1 }}>
      <Grid2 xs={12}>
        <CardStandard
          titleText={t`Pending Upgrade`}
          titleIcon={IcSvgList.up1}
          color="accentGreen1"
          titleIconRight={0}
        >
          <Ty cp={{ sx: { mt: 2 } }}>
            <Trans>
              We&apos;re processing your request to upgrade your subscription. As soon as that
              completes this page will automatically update! Note: we&apos;ve already attempted
              to charge your payment method on file. If you want to cancel your upgrade you can
              click below, but if your payment method was already successfully charged your
              subscription will be upgraded this current billing period; after that, at the next
              billing period it will be downgraded back to your original subscription plan.
            </Trans>
          </Ty>

          <Button
            color="accentRed1"
            // if the user is undoing their cancelation, that means they want to downgrade
            // to plan 1
            onClick={() => {
              recordUserActionToHistoryTable(planOneDt.dbId);
            }}
            isLoading={isLoading}
            disabled={isLoading}
            cp={{ sx: { mt: 2 } }}
          >
            <Trans>
              Cancel Upgrade
            </Trans>
          </Button>
        </CardStandard>
      </Grid2>
    </Grid2Ct>
  );
};

// user has a pending downgrade
interface PendingDowngradeInterface {
  plan: SubscriptionCurrentPlan
}
const PendingDowngrade = ({ plan } : PendingDowngradeInterface) => {
  const { recordUserActionToHistoryTable, isLoading } = useRecordUserActionToHistoryTable();
  const { t } = useLingui();

  return (
    <Grid2Ct sx={{ mb: 1 }}>
      <Grid2 xs={12}>
        <CardStandard
          titleText={t`Pending Downgrade`}
          titleIcon={IcSvgList.down1}
          color="accentGreen1"
          titleIconRight={0}
        >
          <Ty cp={{ sx: { mt: 2 } }}>
            <Trans>
              We&apos;ve processed your request to downgrade your subscription. Your plan
              will automatically downgrade at the end of your current billing period (note: click
              the Subscription History section below to view your billing period details).
              If you didn&apos;t mean to downgrade and want to keep your current plan, click
              the button below:
            </Trans>
          </Ty>

          <Button
            color="accentPurple1"
            onClick={() => {
              recordUserActionToHistoryTable(plan.planDbId);
            }}
            isLoading={isLoading}
            disabled={isLoading}
            cp={{ sx: { mt: 2 } }}
          >
            <Trans>
              Undo Downgrade
            </Trans>
          </Button>
        </CardStandard>
      </Grid2>
    </Grid2Ct>
  );
};

// user has a pending cancelation
interface PendingCancelationInterface {
  plan: SubscriptionCurrentPlan
}
const PendingCancelation = ({ plan } : PendingCancelationInterface) => {
  const { recordUserActionToHistoryTable, isLoading } = useRecordUserActionToHistoryTable();
  const { t } = useLingui();

  return (
    <Grid2Ct sx={{ mb: 1 }}>
      <Grid2 xs={12}>
        <CardStandard
          titleText={t`Pending Cancelation`}
          titleIcon={IcSvgList.cancel1}
          color="accentGreen1"
          titleIconRight={0}
        >
          <Ty cp={{ sx: { mt: 2 } }}>
            <Trans>
              We&apos;ve processed your request to cancel your subscription. Your plan
              will automatically cancel at the end of the current billing period (note: click
              the Subscription History section below to view your billing period details).
              If you didn&apos;t mean to cancel, click the button below:
            </Trans>
          </Ty>

          <Button
            color="accentPurple1"
            onClick={() => {
              recordUserActionToHistoryTable(plan.planDbId);
            }}
            isLoading={isLoading}
            disabled={isLoading}
            cp={{ sx: { mt: 2 } }}
          >
            <Trans>
              Undo Cancelation
            </Trans>
          </Button>
        </CardStandard>
      </Grid2>
    </Grid2Ct>
  );
};

// #endregion
