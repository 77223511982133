import React from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { LayoutPosttrial2 } from './LayoutPosttrial2';
import { LessonsGroupclasssubFragment$key } from './relay/__generated__/LessonsGroupclasssubFragment.graphql';
import { LessonsQuery, LessonsGroupclasssubFragment } from './relay/Lessons';

export const LayoutPosttrial1 = () => {
  const response: any = useLazyLoadQuery(
    LessonsQuery,
    {}
  );

  const subVal: LessonsGroupclasssubFragment$key = response.subs_connection.edges[0].node;
  const data = useFragment(LessonsGroupclasssubFragment, subVal);
  let isSubbed = false;
  if (data.current_plan !== null && (data.current_plan_status === 1
     || data.current_plan_status === 2)) {
    isSubbed = true;
  }

  return (
    <LayoutPosttrial2 isSubscribed={isSubbed} />
  );
};
