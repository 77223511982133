import {
  styled,
} from '@languageconvo/wcl';
import MuxPlayer from '@mux/mux-player-react';

// mux video player styling
export const MuxPlayerCustomGroupschedhow = styled(MuxPlayer)(() => ({
  width: '100%',
  aspectRatio: 16 / 9,
}));
