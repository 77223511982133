/**
 * @generated SignedSource<<8a3e17a6baea11dc6efc4eb270994bc3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PurchaseChooseQuery$variables = {};
export type PurchaseChooseQuery$data = {
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"PurchaseChooseFragment">;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"PackagesDetail">;
};
export type PurchaseChooseQuery = {
  response: PurchaseChooseQuery$data;
  variables: PurchaseChooseQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialstart_ts",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current_plan",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current_plan_status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "subs_mainplans",
  "kind": "LinkedField",
  "name": "subs_mainplans",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "order",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discount_amount",
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PurchaseChooseQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PackagesDetail"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PurchaseChooseFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PurchaseChooseQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "pk": {
                "_neq": 2
              }
            }
          }
        ],
        "concreteType": "locationsConnection",
        "kind": "LinkedField",
        "name": "locations_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "locationsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "locations",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "package_type_id": {
                            "_eq": 1
                          }
                        }
                      }
                    ],
                    "concreteType": "packs",
                    "kind": "LinkedField",
                    "name": "packs",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "price",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": "packs(where:{\"package_type_id\":{\"_eq\":1}})"
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "locations_connection(where:{\"pk\":{\"_neq\":2}})"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lang_learning",
                    "storageKey": null
                  },
                  {
                    "name": "relaySubscription",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "subs",
                          "kind": "LinkedField",
                          "name": "subs",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/),
                            (v5/*: any*/),
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "users",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": false
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subs",
                    "kind": "LinkedField",
                    "name": "subs",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "65b84ff7fc1df82d13d7e7942deecb34",
    "id": null,
    "metadata": {},
    "name": "PurchaseChooseQuery",
    "operationKind": "query",
    "text": "query PurchaseChooseQuery {\n  ...PackagesDetail\n  users_connection {\n    edges {\n      node {\n        ...PurchaseChooseFragment\n        id\n      }\n    }\n  }\n}\n\nfragment PackagesDetail on query_root {\n  locations_connection(where: {pk: {_neq: 2}}) {\n    edges {\n      node {\n        pk\n        title\n        packs(where: {package_type_id: {_eq: 1}}) {\n          pk\n          price\n          duration\n          id\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment PurchaseChooseFragment on users {\n  lang_learning\n  ...relaySubscriptionResolver\n  ...UserSubsDetailsFragment\n}\n\nfragment UserSubsDetailsFragment on users {\n  subs {\n    current_plan\n    current_plan_status\n    trialstart_ts\n    subs_mainplans {\n      order\n      discount_amount\n      id\n    }\n    id\n  }\n}\n\nfragment relaySubscriptionResolver on users {\n  subs {\n    trialstart_ts\n    current_plan\n    current_plan_status\n    subs_mainplans {\n      order\n      discount_amount\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "372790e8863d2668eb369646bea93232";

export default node;
