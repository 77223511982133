import React, { useState, useEffect, useLayoutEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useMutation } from 'react-relay';
import { NavLink } from 'react-router-dom';
import {
  Grid2Ct, Grid2, Ty, IcSvgList, CardStandard, Link, Dialog, Alert, Ic, CardSmallColor,
} from '@languageconvo/wcl';
import scrollIntoView from 'scroll-into-view-if-needed';
import { StuSettingsUpdatetrialstep3GetavailabilityMutation } from '../../../../relay/posttrial/StuSettingsUpdatetrialstep3';
import { Step3Availability } from './StepsToSchedule4Step3/Step3Availability';
import { Step3AvailabilityLoading } from './StepsToSchedule4Step3/Step3AvailabilityLoading';
import { StuSettingsGettrialdataMutation } from '../../../../../../../common/relay/mutations/students/StuSettingsGettrialdata';
import { sitedata } from '../../../../../../../utils/sitedata';

interface Props {
  langId: number;
  interestedInGroup: boolean;
  interestedInPrivate: boolean;
}

// what is the user insterested in...private lessons, group classes, or both?
enum PageScenario {
  PrivateOnly = 'PrivateOnly',
  GroupOnly = 'GroupOnly',
  BothPrivateGroup = 'BothPrivateGroup'
}

// select and schedule a time for a free trial lesson
export const StepsToSchedule4Step3 = ({
  langId,
  interestedInGroup,
  interestedInPrivate,
}: Props) => {
  // #region general

  // which "scenario" we are displaying
  let pageScenario = PageScenario.BothPrivateGroup;
  if (interestedInPrivate === true && interestedInGroup === false) {
    pageScenario = PageScenario.PrivateOnly;
  } else if (interestedInPrivate === false && interestedInGroup === true) {
    pageScenario = PageScenario.GroupOnly;
  }

  // State to track errors in case the mutation fails
  const [error, setError] = useState();
  useEffect(() => {
    if (error) {
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // modal, private vs. group class
  const [modalStatePrivgroup, setModalStatePrivgroup] = useState<boolean>(false);
  const handleModalOpenPrivgroup = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStatePrivgroup(true);
  };
  const handleModalClosePrivgroup = () => {
    setModalStatePrivgroup(false);
  };

  // #endregion

  // #region get availability

  const [AvailabilityData] = useMutation(StuSettingsUpdatetrialstep3GetavailabilityMutation);
  // schedData will hold the data about teacher availability that we get back from our api call
  const [schedData, setSchedData] = useState<any>([]);
  const [schedulingError, setSchedulingError] = useState('');

  // If user change lng while standing on step3, and we are not offeringn any trails for that
  // selected lng, In this case, we make mutation call again with that new lng which will handle
  // everything own its own with relay.
  const [TheTrialData] = useMutation(StuSettingsGettrialdataMutation);
  const gettrialData = () => {
    TheTrialData({
      variables: {
        languageId: langId,
      },
      onCompleted() {
      // success, don't need to do anything
      },
      onError(err: any) {
        Sentry.captureException(err);
        setError(err);
      }
    });
  };

  const mutationCallToGetAvailabilityData = () => {
    AvailabilityData({
      variables: {
        languageId: langId,
      },
      onCompleted(dt: any) {
        const parsedSchedData = JSON.parse(dt.stu_appt_gettrialavailability.schedule);
        setSchedData(parsedSchedData);
        // handle scnario if no trial available for user selected lng then execute trial
        // mutaiton again for new lang that user has changed.
        if (parsedSchedData.length === 0) {
          gettrialData();
        }
      },
      onError(err: any) {
        Sentry.captureException(err);
        setError(err);
      }
    });
  };

  useEffect(() => {
    setSchedData([]);
    mutationCallToGetAvailabilityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langId]);

  // #endregion

  // #region to scroll to top

  useLayoutEffect(() => {
    const scroolToTop = document.querySelector('[data-top="top"]');
    scrollIntoView(scroolToTop!, {
      behavior: 'auto',
      // block: start attempts to scroll the element into the top of the viewport
      block: 'start',
      inline: 'nearest',
    });
  }, []);

  // #endregion

  return (
    <>
      <Grid2Ct>
        {/* only show private lesson scheduling is user is interested in private only, or both
        private and group */}
        {(pageScenario === PageScenario.PrivateOnly
        || pageScenario === PageScenario.BothPrivateGroup) ? (
          <Grid2 xs={12}>
            <CardStandard
              titleText="Schedule a 1-on-1 Trial Lesson"
              titleIcon={IcSvgList.teacher4}
              color="accentGreen1"
              titleIconRight={0}
            >
              <Grid2Ct>
                {/* title and subtitle */}
                <Grid2 xs={12} lg={8} lgOffset={2}>
                  <Alert variant="standard" title="Unlimited Free Trials" severity="success">
                    Click a time below to set up a free private lesson. We have numerous teachers
                    on our team, and we&apos;ll work with you to find a perfect fit. You can try
                    unlimited free lessons with different teachers until we find
                    that perfect teacher for you!
                  </Alert>
                </Grid2>

                <Grid2 xs={12} sm={10} smOffset={1}>
                  {schedulingError === 'SchedulingConflict' && (
                  <Alert variant="standard" title="Oops! That Time Was Taken" severity="info">
                    We apologize, it looks like another student scheduled that time before you
                    were able to. We&apos;ve reloaded the schedule to have the most up-to-date
                    times. Please choose a new time, our apologies for the inconvenience!
                  </Alert>
                  )}
                  {schedulingError === 'GeneralError' && (
                  <Alert variant="standard" title="Oops! Something went wrong" severity="info">
                    Hmm something went wrong, please try reloading the page
                  </Alert>
                  )}
                  {schedulingError === 'AlreadyScheduled' && (
                  <Alert variant="standard" title="Oops! Lesson Already Scheduled" severity="info">
                    It looks like you have already scheduled the lesson.
                    You can only schedule 1 Lesson at 1 time
                  </Alert>
                  )}
                </Grid2>

                {/* calendar of availability */}
                {schedData?.length > 0
                  ? (
                    <Step3Availability
                      sched={schedData}
                      mutationCallToGetAvailabilityData={mutationCallToGetAvailabilityData}
                      setSchedData={setSchedData}
                      setSchedulingError={setSchedulingError}
                    />
                  ) : (<Step3AvailabilityLoading />)}
              </Grid2Ct>
            </CardStandard>
          </Grid2>
          ) : null}

        {/* only show group classes section that has a "private or group?" area if the user is
        interested in both private and group */}
        {(pageScenario === PageScenario.BothPrivateGroup) ? (
          <>
            {/* group classes link */}
            <Grid2 xs={12} lg={12} xl={7} sx={{ display: 'flex' }}>
              <CardStandard
                titleText="Try Classes"
                titleIcon={IcSvgList.group1}
                titleIconRight={0}
                color="accentGreen1"
                // needed when using display flex on the parent:
                cp={{ id: 'step3', sx: { width: '100%' } }}
              >
                <Grid2Ct>
                  {/* text */}
                  <Grid2 xs={12}>
                    <Ty>
                      You, a teacher, and small group of other students &mdash; join
                      <strong> unlimited </strong>
                      classes for just
                      <strong> $29.99/month</strong>!
                      Start/cancel/restart your subscription with the click of a button anytime.
                    </Ty>
                  </Grid2>

                  {/* view schedule */}
                  <Grid2 xs={12} sm={6}>
                    <Link
                      to={sitedata.url.app.grouplsnUpcomingDt.pathFull}
                      component={NavLink}
                      linkStyle="nostyle"
                    >
                      <CardSmallColor
                        text="View Schedule"
                        icon={IcSvgList.calendar1}
                        color="accentPurple1"
                        hovercursor="pointer"
                      />
                    </Link>
                  </Grid2>

                  {/* try for free */}
                  <Grid2 xs={12} sm={6}>
                    <Link
                      to={sitedata.url.app.manageSubscriptionDt.pathFull}
                      component={NavLink}
                      linkStyle="nostyle"
                    >
                      <CardSmallColor
                        text="Try For Free!"
                        icon={IcSvgList.cart2}
                        color="accentBlue1"
                        hovercursor="pointer"
                      />
                    </Link>
                  </Grid2>
                </Grid2Ct>
              </CardStandard>
            </Grid2>

            {/* difference between group classes and private lessons
                we don't display this on xs screens bc we want mobile users to be easily able
                to see the "schedule a free trial" area
            */}
            <Grid2
              xs={12}
              lg={12}
              xl={5}
              sx={{
                display: {
                  xs: 'none', sm: 'flex', lg: 'none', xl: 'flex'
                }
              }}
            >
              <CardStandard
                titleText="Private or Group?"
                titleIcon={IcSvgList.earth1}
                color="accentGreen1"
                titleIconRight={0}
                onClickEntireCard={handleModalOpenPrivgroup}
                hovercursor="pointer"
                // needed when using display flex on the parent:
                cp={{ sx: { width: '100%' } }}
              >
                <Grid2Ct>
                  <Grid2 xs={12}>
                    <Ty>
                      Click this box to learn more about private lessons (just you and your
                      long-term teacher) vs. classes (you, a teacher, and a few other
                      students)!
                    </Ty>
                    <Ty>
                      You can mix classes and private lessons for an affordable way
                      to both learn with a teacher and practice with a group.
                    </Ty>
                  </Grid2>
                </Grid2Ct>
              </CardStandard>
            </Grid2>
          </>
        ) : null}

        {/* show this customized group classes section iff the user is interested in group
        group classes */}
        {(pageScenario === PageScenario.GroupOnly) ? (
          <>
            <Grid2 xs={12} lg={12} xl={7} sx={{ display: 'flex' }}>
              <CardStandard
                titleText="Try Classes"
                titleIcon={IcSvgList.group1}
                titleIconRight={0}
                color="accentGreen1"
                // needed when using display flex on the parent:
                cp={{ id: 'step3', sx: { width: '100%' } }}
              >
                <Grid2Ct>
                  {/* text */}
                  <Grid2 xs={12}>
                    <Ty>
                      You, a teacher, and small group of other students &mdash; join
                      <strong> unlimited </strong>
                      classes for just
                      <strong> $29.99/month</strong>!
                      Start/cancel/restart your subscription with the click of a button anytime.
                    </Ty>
                  </Grid2>

                  {/* view schedule */}
                  <Grid2 xs={12} sm={6}>
                    <Link
                      to={sitedata.url.app.grouplsnUpcomingDt.pathFull}
                      component={NavLink}
                      linkStyle="nostyle"
                    >
                      <CardSmallColor
                        text="View Schedule"
                        icon={IcSvgList.calendar1}
                        color="accentPurple1"
                        hovercursor="pointer"
                      />
                    </Link>
                  </Grid2>

                  {/* try for free */}
                  <Grid2 xs={12} sm={6}>
                    <Link
                      to={sitedata.url.app.manageSubscriptionDt.pathFull}
                      component={NavLink}
                      linkStyle="nostyle"
                    >
                      <CardSmallColor
                        text="Try For Free!"
                        icon={IcSvgList.cart2}
                        color="accentBlue1"
                        hovercursor="pointer"
                      />
                    </Link>
                  </Grid2>
                </Grid2Ct>
              </CardStandard>
            </Grid2>

            <Grid2 xs={12} lg={12} xl={5} sx={{ display: 'flex' }}>
              <CardStandard
                titleText="Effective?"
                titleIcon={IcSvgList.trophy3}
                color="accentGreen1"
                titleIconRight={0}
                // needed when using display flex on the parent:
                cp={{ sx: { width: '100%' } }}
              >
                <Grid2Ct>
                  <Grid2 xs={12}>
                    <Ty removeMb>
                      Bite-Sized Conversational Classes are a <strong>very effective</strong> way
                      to learn a language, &mdash;
                      not only do you get instruction from a native teacher, you get to practice
                      using the language with your teacher and the other students in the class!
                      Try it out, it really is 100% free to join some classes.
                    </Ty>
                  </Grid2>
                </Grid2Ct>
              </CardStandard>
            </Grid2>
          </>
        ) : null}
      </Grid2Ct>

      {/* modal, private vs group */}
      <Dialog
        isOpen={modalStatePrivgroup}
        onClose={handleModalClosePrivgroup}
        width="md"
        color="accentBlue1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty v="h2New"><Ic iconName="user-graduate" iconStyle="duotone" color="accentGreen1" />&nbsp;&nbsp;Private, Personalized Lessons</Ty>
            <Ty>
              Private lessons are you and your long-term teacher, nobody else. We help you find
              a teacher on our team who&apos;s a great fit for you, so that you can work directly
              1-on-1 with a professional, native-speaking language teacher.
            </Ty>

            <Ty v="h2New" cp={{ sx: { mt: 3 } }}><Ic iconName="users" iconStyle="duotone" color="accentPurple1" />&nbsp;&nbsp;Bite-Sized Conversational Classes</Ty>
            <Ty>
              Classes include you, a teacher, and a small group of other students. We&apos;ve
              revolutionized language learning in this regard: classes are extremely affordable
              ($29.99/month), you can attend an unlimited number of classes, and you get to not only
              learn with a teacher but also get to practice what you learned in conversation with
              other students. Classes are available every day, and you can start as soon as just a
              few minutes from now!
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};
