import React, { Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/react';
import {
  Button, Dialog, Grid2, Ty, Grid2Ct, Box, Alert,
} from '@languageconvo/wcl';
import { Trans } from '@lingui/react/macro';
import { useRecordUserActionToHistoryTable } from '../../hooks/useRecordUserAction';
import { Plan2Benefits } from '../PlanBenefits';
import { useOurSubscriptions } from '../../../../../common/utils/subscriptions/useOurSubscriptions';
import { SubscriptionPendingChange } from '../../ManageSubscriptionTypes';

interface Props {
  openDownGradePlanModal: boolean;
  setOpenDownGradePlanModal: Dispatch<SetStateAction<boolean>>;
  subPendingChange: SubscriptionPendingChange;
}

// the user has plan 2, and clicked on "downgrade" to plan1
export const DowngradeModal = ({
  openDownGradePlanModal,
  setOpenDownGradePlanModal,
  subPendingChange,
}: Props) => {
  const {
    recordUserActionToHistoryTable, isLoading,
    isError
  } = useRecordUserActionToHistoryTable();
  const { plan1Details } = useOurSubscriptions();

  // if the user has canceled their plan (the cancelation is scheduled for the future),
  // we need to show them a notif that we will first remove their cancelation and then
  // schedule their downgrade
  const isCancellationScheduled = subPendingChange === SubscriptionPendingChange.Cancel;
  let modalColor: 'accentRed1' | 'accentGreen1' = 'accentRed1';
  if (isCancellationScheduled) {
    modalColor = 'accentGreen1';
  }

  // if downgrade is in process, this modal should be able to be shown. log err if it does
  if (subPendingChange === SubscriptionPendingChange.Downgrade) {
    Sentry.captureMessage('DownGradePlanModal got that subPendingChange = Downgrade, which should not occur');
  }

  return (
    <Dialog
      isOpen={openDownGradePlanModal}
      onClose={() => setOpenDownGradePlanModal(!openDownGradePlanModal)}
      width="sm"
      color={modalColor}
    >
      <Grid2Ct>
        <Grid2 xs={12}>
          {/* if the user has canceled their plan (the cancelation is scheduled for the future),
              we show a different note than if they are doing a normal downgrade
          */}
          {isCancellationScheduled ? (
            <DowngradeAfterCancelation />
          ) : (<NormalDowngrade />)}

          {/* button */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button
              color={modalColor}
              cp={{ sx: { mb: 2 } }}
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() => recordUserActionToHistoryTable(
                plan1Details.dbId,
                openDownGradePlanModal,
                setOpenDownGradePlanModal
              )}
            >
              <Trans>
                Yes, Downgrade
              </Trans>
            </Button>
          </Box>

          {/* If mutataion call fails we are showing error in the dialog. */}
          {isError && (
            <Alert severity="error">
              <Trans>
                Something went wrong, please try that again. Your plan may not have been changed!
              </Trans>
            </Alert>
          )}
        </Grid2>
      </Grid2Ct>
    </Dialog>
  );
};

// the user does NOT have a cancelation scheduled, we'll show this
const NormalDowngrade = () => (
  <>
    <Ty v="h2New" align="center"><Trans>Are you sure you want to downgrade?</Trans></Ty>

    <Ty cp={{ sx: { mt: 3 } }}>
      <Trans>
        You&apos;ll lose access to the following:
      </Trans>
    </Ty>

    {/* benefits the user is going to lose */}
    <Grid2Ct sx={{ mt: 1 }}>
      <Grid2 xs={12}>
        <Plan2Benefits />
      </Grid2>
    </Grid2Ct>
  </>
);

// the user has a cancelation scheduled. we'll show them this
const DowngradeAfterCancelation = () => (
  <>
    <Ty v="h2New" align="center"><Trans>Downgrade instead of canceling?</Trans></Ty>

    <Ty cp={{ sx: { mt: 3 } }}>
      <Trans>
        It looks like you have your subscription scheduled for cancelation. Click the button
        below if you want to downgrade instead of canceling:
      </Trans>
    </Ty>
  </>
);
