import { graphql } from 'babel-plugin-relay/macro';

// first, we get the user's most recent subscription (subs_details table). this ensures that
// if the user had a subscription in the past that's canceled, we are *not* gong to get the history
// table rows for that subscription. then, get the most recent row of the history table for
// that subscription
export const GetUserRecentActionQuery = graphql`
  query GetUserRecentActionQuery {
    subs_details_connection(first: 1, order_by: {created_at: desc}) {
      edges {
        node {
          subs_mainupdate_history_connection(order_by: {ts_user_action: desc}, first: 1) {
            ...GetUserRecentActionFragment
          }
        }
      }
    }
  }
`;

export const GetUserRecentActionFragment = graphql`
  fragment GetUserRecentActionFragment on subs_mainupdate_historyConnection {
    edges {
      node {
        subs_mainplans {
          order
        }
        ts_processed
      }
    }
  }
`;
