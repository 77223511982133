/**
 * @generated SignedSource<<7114f7ac69b43bc49740372ab9203a3a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PurchaseMainLayoutQuery$variables = {};
export type PurchaseMainLayoutQuery$data = {
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"PurchaseMainLayoutFragment">;
      };
    }>;
  };
};
export type PurchaseMainLayoutQuery = {
  response: PurchaseMainLayoutQuery$data;
  variables: PurchaseMainLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialstart_ts",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current_plan",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current_plan_status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "subs_mainplans",
  "kind": "LinkedField",
  "name": "subs_mainplans",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "order",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discount_amount",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PurchaseMainLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PurchaseMainLayoutFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PurchaseMainLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "name": "relaySubscription",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "subs",
                          "kind": "LinkedField",
                          "name": "subs",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v4/*: any*/),
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "users",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": false
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subs",
                    "kind": "LinkedField",
                    "name": "subs",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v0/*: any*/),
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c2186c44691443fe6a3422a55e929ab",
    "id": null,
    "metadata": {},
    "name": "PurchaseMainLayoutQuery",
    "operationKind": "query",
    "text": "query PurchaseMainLayoutQuery {\n  users_connection {\n    edges {\n      node {\n        ...PurchaseMainLayoutFragment\n        id\n      }\n    }\n  }\n}\n\nfragment PurchaseMainLayoutFragment on users {\n  ...relaySubscriptionResolver\n  ...UserSubsDetailsFragment\n}\n\nfragment UserSubsDetailsFragment on users {\n  subs {\n    current_plan\n    current_plan_status\n    trialstart_ts\n    subs_mainplans {\n      order\n      discount_amount\n      id\n    }\n    id\n  }\n}\n\nfragment relaySubscriptionResolver on users {\n  subs {\n    trialstart_ts\n    current_plan\n    current_plan_status\n    subs_mainplans {\n      order\n      discount_amount\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "543f72e82c63bebd88950fbb6294ab2d";

export default node;
