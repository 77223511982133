/**
 * @generated SignedSource<<a7a08e28b5116a7212b3500b5fb3e642>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupLessonsSubscription$variables = {
  currentTime?: any | null;
  endTime?: any | null;
  langId?: number | null;
};
export type GroupLessonsSubscription$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GroupLessonsFragment">;
};
export type GroupLessonsSubscription = {
  response: GroupLessonsSubscription$data;
  variables: GroupLessonsSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentTime"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endTime"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "langId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display_order",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_en",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GroupLessonsSubscription",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "GroupLessonsFragment"
      }
    ],
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GroupLessonsSubscription",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order_by",
            "value": {
              "starts_at": "asc"
            }
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_gt",
                    "variableName": "currentTime"
                  }
                ],
                "kind": "ObjectValue",
                "name": "ends_at"
              },
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "langId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "language_id"
              },
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_lt",
                    "variableName": "endTime"
                  }
                ],
                "kind": "ObjectValue",
                "name": "starts_at"
              },
              {
                "kind": "Literal",
                "name": "status",
                "value": {
                  "_eq": "0"
                }
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "appt_groupConnection",
        "kind": "LinkedField",
        "name": "appt_group_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appt_groupEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "appt_group",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "starts_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ends_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duration_minutes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "max_students",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "num_reservations",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type_conversational_level",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "skills",
                    "kind": "LinkedField",
                    "name": "skills",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description_en",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "skills_levels",
                        "kind": "LinkedField",
                        "name": "skills_levels",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "main_skills_categories",
                            "kind": "LinkedField",
                            "name": "skills_categories",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "canceled_at": {
                            "_is_null": true
                          }
                        }
                      }
                    ],
                    "concreteType": "appt_group_reservations",
                    "kind": "LinkedField",
                    "name": "appt_group_reservations",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reserved_at",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": "appt_group_reservations(where:{\"canceled_at\":{\"_is_null\":true}})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "resources",
                    "kind": "LinkedField",
                    "name": "resources",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d4bf049c04efe741d4b5a61c347baf5",
    "id": null,
    "metadata": {},
    "name": "GroupLessonsSubscription",
    "operationKind": "subscription",
    "text": "subscription GroupLessonsSubscription(\n  $currentTime: timestamptz\n  $endTime: timestamptz\n  $langId: Int\n) {\n  ...GroupLessonsFragment\n}\n\nfragment GroupLessonsFragment on subscription_root {\n  appt_group_connection(where: {language_id: {_eq: $langId}, status: {_eq: \"0\"}, ends_at: {_gt: $currentTime}, starts_at: {_lt: $endTime}}, order_by: {starts_at: asc}) {\n    edges {\n      node {\n        uuid\n        starts_at\n        ends_at\n        duration_minutes\n        max_students\n        num_reservations\n        type\n        type_conversational_level\n        skills {\n          description_en\n          display_order\n          title_en\n          uuid\n          skills_levels {\n            display_order\n            title_en\n            skills_categories {\n              title_en\n              id\n            }\n            id\n          }\n          id\n        }\n        appt_group_reservations(where: {canceled_at: {_is_null: true}}) {\n          reserved_at\n          id\n        }\n        resources {\n          title\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "663c374133ef06c7904b80b5c712e267";

export default node;
