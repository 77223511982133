import React, { Dispatch, SetStateAction } from 'react';
import {
  Button, Dialog, Grid2, Ty, Grid2Ct, Box, Alert
} from '@languageconvo/wcl';
import { Trans } from '@lingui/react/macro';
import { ErrorsStuSubsCreatecheckoutlink } from '../../Errors/Errors';
import { SubscriptionCurrentPlan } from '../../ManageSubscriptionTypes';

interface ModalState {
  openModalForBasicPlan: boolean;
  setOpenModalForBasicPlan: Dispatch<SetStateAction<boolean>>;
  checkoutUrl: string;
  isInFlight: boolean;
  errorCode: null | ErrorsStuSubsCreatecheckoutlink;
  subCurrent:SubscriptionCurrentPlan;
}

export const SubscribeToBasicPlanModal = ({
  openModalForBasicPlan,
  setOpenModalForBasicPlan,
  isInFlight,
  checkoutUrl,
  errorCode,
  subCurrent
}: ModalState) => {
  // if any error in the api call has occurred, we cannot show the checkout button/link
  let showButton = true;
  if (errorCode !== null) {
    showButton = false;
  }

  return (
    <Dialog
      isOpen={openModalForBasicPlan}
      onClose={() => setOpenModalForBasicPlan(!openModalForBasicPlan)}
      width="sm"
      color="accentGreen1"
    >
      <Grid2Ct>
        <Grid2 xs={12}>
          {/* if the user gets to try group classes for free, display this text */}
          {subCurrent.canDoTrial && (
          <>
            <Ty v="h2New"><Trans>Free Trial Details</Trans></Ty>
            <Ty>
              <Trans>
                Once you start your free trial, you can join classes for
                free, for 2 days! If you don&apos;t enjoy them (we think that&apos;s
                unlikely!) just cancel before the 2 day period is up and your
                subscription won&apos;t start / you will NOT be charged anything.
              </Trans>
            </Ty>
          </>
          )}

          {/* group classes are spanish only right now */}
          <Ty v="h2New" cp={{ sx: { mt: 2 } }}>
            <Trans>
              Spanish only, check the schedule!
            </Trans>
          </Ty>
          <Ty>
            <Trans>
              Group classes just started very recently; we are working on adding all
              languages but to start, only Spanish is offered. Please check the
              schedule (which you can get to from the main &quot;Lessons&quot;
              page)!
            </Trans>
          </Ty>

          {/* do not share subscription */}
          <Ty v="h2New" cp={{ sx: { mt: 2 } }}>
            <Trans>
              By Subscribing, You Agree Not to Share
            </Trans>
          </Ty>
          <Ty>
            <Trans>
              Your subscription is for one person only.
              Allowing more than one person to use a subscription will be considered
              fraud. We are a small business, please help us make this new way of
              learning a language work!
            </Trans>
          </Ty>

          {/* button */}
          {showButton && (
          <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
            <Button
              color="accentGreen1"
              cp={{ sx: { mb: 2 } }}
              isLoading={checkoutUrl === '' || isInFlight}
              disabled={checkoutUrl === '' || isInFlight}
              onClick={() => {
                window.location.href = checkoutUrl;
              }}
            >
              <Trans>
                I Agree, Continue!
              </Trans>
            </Button>
          </Box>
          )}

          {/* for general errors with the api call, we display this error notif */}
          {(errorCode === ErrorsStuSubsCreatecheckoutlink.TryAgain
          || errorCode === ErrorsStuSubsCreatecheckoutlink.UnexpectedOrTimeout) && (
            <Box sx={{ mt: 3 }}>
              <Alert variant="standard" title="Oops! An Error Occurred" severity="error">
                <Ty removeMb>
                  <Trans>
                    Our apologies, something went wrong. Please close this popup and try that
                    again.
                  </Trans>
                </Ty>
              </Alert>
            </Box>
          )}
          {/* a specific error if the user is suspended/not allowed to do group classes */}
          {errorCode === ErrorsStuSubsCreatecheckoutlink.NotAllowed && (
            <Box sx={{ mt: 3 }}>
              <Alert variant="standard" title="Oops! An Error Occured" severity="error">
                <Ty removeMb>
                  <Trans>
                    Hmm, something went wrong. Please contact our customer service team.
                  </Trans>
                </Ty>
              </Alert>
            </Box>
          )}

          {/* a specific error if we get the unexpected planID is received  OR failed 
          to get portal url from Backend we are showin a message to the user
          */}
          {(errorCode === ErrorsStuSubsCreatecheckoutlink.UnexpectedPlanId
          || errorCode === ErrorsStuSubsCreatecheckoutlink.CheckoutURLNotFound) && (
          <Box sx={{ mt: 3 }}>
            <Alert variant="standard" title="Oops! An Error Occured" severity="error">
              <Ty removeMb>
                Oops - An Error Occurred. Please close this popup and try that
                again. If the issue persists, please reach out to our customer
                support team for assistance.
              </Ty>
            </Alert>
          </Box>
          )}
        </Grid2>
      </Grid2Ct>
    </Dialog>
  );
};
