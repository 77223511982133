import { graphql } from 'babel-plugin-relay/macro';

export const PurchaseChooseQuery = graphql`
  query PurchaseChooseQuery {
    ...PackagesDetail
    users_connection {
      edges {
        node {
          ...PurchaseChooseFragment
        }
      }
    }
  }
`;

export const PurchaseChooseFragment = graphql`
  fragment PurchaseChooseFragment on users {
    lang_learning
    relaySubscription

    # this is our standard, app-level fragment for getting user's subscription information
    # this data is what's used to calculate relaySubscription. note that we get this data already in an
    # app-level subscription, but we're going to get it here again just to be sure we have updated data
    # from the db (in case the app-level subscription failed)
    ...UserSubsDetailsFragment
  }
`;
