import React, { useState, useLayoutEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import { NavLink } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import {
  Grid2Ct, Grid2, Ty, CardStandard, IcSvgList, Link, Dialog, Box, useTheme,
} from '@languageconvo/wcl';
import { Step1Subscription } from './components/main/Step1Subscription';
import { Header } from './components/Header';
import {
  PageloadQuery,
  PageloadLanglearnFragment,
  PageloadTimesettingsFragment,
  PageloadRequiredsettingsFragment,
  PageloadSubscriptFragment,
} from './relay/Pageload';
import { PageloadLanglearnFragment$key } from './relay/__generated__/PageloadLanglearnFragment.graphql';
import { PageloadTimesettingsFragment$key } from './relay/__generated__/PageloadTimesettingsFragment.graphql';
import { PageloadRequiredsettingsFragment$key } from './relay/__generated__/PageloadRequiredsettingsFragment.graphql';
import { LanguageNotAvailableYet } from './components/LanguageNotAvailableYet';
import { sitedata } from '../../../utils/sitedata';
import { CurrentSubscriptionTy, SubscriptionPlanTy } from '../../../common/utils/subscriptions/OurSubscriptionsTypes';
import { HowClassesWorkNotsubbed } from './components/howclasseswork/HowClassesWorkNotsubbed';
import { MuxPlayerCustomGroupschedhow } from './GroupSchedule.style';

export const GroupSchedule2 = () => {
  // #region general

  const { t } = useTranslation(['videos']);
  const theme = useTheme();
  const bRad = `${theme?.ourTheme.borders.borderRadius.medium}px`;

  // get the lang_learning value (the language the user is learning) and their hour_cycle.
  // we need lang_learning to ensure we subscribe to lessons of the language the user is
  // learning, and hour_cycle to display lesson times correctly. we also need timezone_set
  // so we can warn the user if they have not set their time zone yet, times won't be
  // displayed correct
  const response: any = useLazyLoadQuery(
    PageloadQuery,
    {}
  );
  const fragmentRef: PageloadLanglearnFragment$key = response.users_connection.edges[0].node;
  const data = useFragment(PageloadLanglearnFragment, fragmentRef);
  const langlearnId = data.lang_learning;
  const fragmentRefTs: PageloadTimesettingsFragment$key = response.users_connection.edges[0].node;
  const dataTs = useFragment(PageloadTimesettingsFragment, fragmentRefTs);
  const hourCy = dataTs.hour_cycle;
  const timedisplaySettings = {
    hourCycle: hourCy,
  };
  const fragmentTzset: PageloadRequiredsettingsFragment$key = response
    .users_connection.edges[0].node;
  const dataTzset = useFragment(PageloadRequiredsettingsFragment, fragmentTzset);
  const isTzSet = dataTzset.timezone_set === 1;

  // daysSelector is an object which holds the day the user has selected (today, tomorrow or
  // +2 days in future) to view upcoming lessons. it also contains the timestamps that
  // define those days. see the Type for details
  const [daysSelector, setDaysSelector] = useState<DaysSelector>(fakeInitValues);
  // on pageload, we set the selected day to "today" (1)
  useLayoutEffect(() => {
    calculateAndSetDaysSelector(1, daysSelector, setDaysSelector);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // modal for info about purchasing group classes
  const [modalStateGroup, setModalStateGroup] = useState<boolean>(false);
  const handleModalOpenGroup = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStateGroup(true);
  };
  const handleModalCloseGroup = () => {
    setModalStateGroup(false);
  };

  // #endregion

  // #region is the user subscribed? data

  const fragrefUserSubscription = response.users_connection.edges[0].node;
  // get our relaySubscription data; this is data we calculate at the app level about the
  // user's subscription (whether they're subscribed, what plan they have, etc.)
  const userSubscriptionPlanData = useFragment(
    PageloadSubscriptFragment,
    fragrefUserSubscription
  );
  // determine if the user is subscribed to group classes or not. in future we may add a
  // third plan, so we use !== NoPlan !== Unknown instead of === Plan1 or Plan2
  const subdata: CurrentSubscriptionTy = userSubscriptionPlanData.relaySubscription;
  let isSubscribed = false;
  if (subdata.plan !== SubscriptionPlanTy.NoPlan
      && subdata.plan !== SubscriptionPlanTy.Unknown) {
    isSubscribed = true;
  }

  // #endregion

  // don't let code continue until we've set the real initial values for daysSelector
  if (daysSelector.selectedDay.startTs === 1) {
    return <div />;
  }

  // link to settings page
  const settingsPageUrl = sitedata.url.app.moreSettingsDt.pathFull;
  const clickableTypeObjSettingspg = {
    to: settingsPageUrl,
    component: NavLink,
  };

  // continue to subscription
  return (
    <Grid2Ct sx={{ mb: 3 }}>
      <Grid2 xs={12} xl={10} xlOffset={1}>
        <Header
          daysSelector={daysSelector}
          setDaysSelector={setDaysSelector}
          handleModalOpenGroup={handleModalOpenGroup}
        />

        {/* "how it works" card and free trial card, we'll show if the user is not subscribed
            we wrap this in an error boundary because if it fails, we don't want the entire
            class page to error! should very rarely fail, so we don't provide a fallback design
         */}
        <Sentry.ErrorBoundary fallback={<> </>}>
          <HowClassesWorkNotsubbed
            isSubscribed={isSubscribed}
            handleModalOpenGroup={handleModalOpenGroup}
          />
        </Sentry.ErrorBoundary>

        {/* if the user hasn't set their time zone, we warn them they need to do that
        otherwise class times are likely not correct */}
        {isTzSet === false && (
          <Grid2Ct>
            <Grid2 xs={12}>
              <CardStandard
                titleText="Problem - set your time zone!"
                titleIcon={IcSvgList.trafficcone1_accentOrange1}
                color="accentOrange1"
                clickableLink1={{ ...clickableTypeObjSettingspg }}
              >
                <Link
                  to={settingsPageUrl}
                  component={NavLink}
                  linkStyle="nostyle"
                >
                  <Grid2Ct sx={{ mt: 1 }}>
                    <Grid2 xs={12}>
                      <Ty>
                        It looks like you have not set your time zone yet, which means
                        class times listed here are probably not correct. Please go to
                        your settings (which you can find on the &quot;More&quot; page)
                        and select your time zone. You can click this box to get there!
                      </Ty>
                    </Grid2>
                  </Grid2Ct>
                </Link>
              </CardStandard>
            </Grid2>
          </Grid2Ct>
        )}

        { (langlearnId === 1 || langlearnId === 4) ? (
          <Step1Subscription
            languageId={langlearnId}
            tsSettings={timedisplaySettings}
            daysSelector={daysSelector}
            setDaysSelector={setDaysSelector}
            isSubscribed={isSubscribed}
          />
        ) : (<LanguageNotAvailableYet />) }

        {/* modal how classes work */}
        <Dialog
          isOpen={modalStateGroup}
          onClose={handleModalCloseGroup}
          width="md"
          color="accentGreen1"
        >
          {/* this box lets us add border radius to the video */}
          <Box sx={{ display: 'flex', overflow: 'hidden', borderRadius: bRad }}>
            <MuxPlayerCustomGroupschedhow
              playbackId={t('cpHowClassesWork1')!}
              theme="minimal"
              thumbnailTime={0}
            />
          </Box>
          <Grid2Ct sx={{ mt: 2 }}>
            <Grid2 xs={12}>
              <Ty>
                Play the video above to learn all about how our unique, bite-sized conversational
                classes can help you learn a language incredibly affordably!
              </Ty>
            </Grid2>
          </Grid2Ct>
        </Dialog>
      </Grid2>
    </Grid2Ct>
  );
};

// #region daysSelector

/* daysSelector is our variable that contains 2 pieces of important information which we
    will use throughout this page:

    1. What day has the user selected to view? Today, tomorrow, or the next day? This
        is stored in selectedDay
    2. What are the start and end timestamps of today (day1), tomorrow (day2), and
       +2 days in the future (day3)

    Notice that for each of those, we have startTs and endTs. These are the unix timestamps
    (in milliseconds -- because with seconds, luxon creates decimal values which is annoying
    to deal with). One thing we do is always ensure that selectedDay matches either day1, day2,
    or day3; selectedDay can't ever be values other than one of those three.
*/
export interface DaysSelector {
  selectedDay: {
    startTs: number;
    endTs: number;
  }
  day1: {
    startTs: number;
    endTs: number;
  }
  day2: {
    startTs: number;
    endTs: number;
  }
  day3: {
    startTs: number;
    endTs: number;
  }
}

/* Which day the user is selecting: 
  0 = the user has not selected any day at all. 0 occurs in two situations:
      1. the user opened the day selector modal
      2. our time determines a new day has started
  1 = today
  2 = tomorrow
  3 = +2 days in future
*/
type DaySelected = 1 | 2 | 3 | 0;

/* Set daysSelector in our state var
    As noted in the interface, day1 is always today, day2 is always tomorrow, day3 is always
    two days from now. And also importantly, selectedDay *must* be one of those days.
*/
export const calculateAndSetDaysSelector = (
  daySelected : DaySelected,
  daysSelector: DaysSelector,
  setDaysSelector : any,
) => {
  // calculate the start and end timestamps (unix milliseconds values) of the start
  // of today (day1), tomorrow (day2), and the next day (day3)
  const dtNow = DateTime.now();
  const day1Start = dtNow.startOf('day').toMillis();
  const day1End = dtNow.endOf('day').toMillis();
  const day2Start = dtNow.plus({ days: 1 }).startOf('day').toMillis();
  const day2End = dtNow.plus({ days: 1 }).endOf('day').toMillis();
  const day3Start = dtNow.plus({ days: 2 }).startOf('day').toMillis();
  const day3End = dtNow.plus({ days: 2 }).endOf('day').toMillis();

  // #region selectedDay

  // selectedDay is the day the user has chosen in the modal; see the DaySelected type for details

  let selectedDayStart;
  let selectedDayEnd;
  // the user has selected "today" (or the user loaded the page; we set today by default on load)
  if (daySelected === 1) {
    selectedDayStart = day1Start;
    selectedDayEnd = day1End;

    // user has selected to view tomorrow 
  } else if (daySelected === 2) {
    selectedDayStart = day2Start;
    selectedDayEnd = day2End;

    // user has selected to view 2 days from today
  } else if (daySelected === 3) {
    selectedDayStart = day3Start;
    selectedDayEnd = day3End;

    /* The user has not selected a day. This is used in two places:
          1. the user opened the day selector modal
          2. our time determines a new day has started
        We're ensuring that the user the day has selected, is one of the three days
        (today/tomorrow/+2 days) in our list. If it is not, the very likely scenario
        is that a new day has started, and for some reason our code which detects that and
        updates this daysSelector hasn't successfully run. If the day the user has selected
        is NOT one of the 3 days, we set it to one of the 3 days. This way we never have a case
        where the selected day is not one of today, tomorrow, or +2 days
    */
  } else if (daySelected === 0) {
    if (daysSelector.selectedDay.startTs === day1Start) {
      selectedDayStart = day1Start;
      selectedDayEnd = day1End;
    } else if (daysSelector.selectedDay.startTs === day2Start) {
      selectedDayStart = day2Start;
      selectedDayEnd = day2End;
    } else if (daysSelector.selectedDay.startTs === day3Start) {
      selectedDayStart = day3Start;
      selectedDayEnd = day3End;

      // the day the user has selected is not one of day1/day2/day3, so set it to today. this
      // could happen if a new day has started, or possibly the user left the page open for
      // more than 3 days but the js hasn't been running. now the user opens the modal and the
      // day they had selected is way in the past
    } else {
      selectedDayStart = day1Start;
      selectedDayEnd = day1End;
    }

    // should never happen. log, and set today as the selected days
  } else {
    Sentry.captureException(
      new Error('in calculateAndSetDaysSelector, daySelected value is unexpected'),
      {
        extra: {
          daySelectedVal: daySelected,
        }
      }
    );
    selectedDayStart = day1Start;
    selectedDayEnd = day1End;
  }

  // #endregion

  // finally, actually set daysSelector values in our state var
  const dt: DaysSelector = {
    selectedDay: {
      startTs: selectedDayStart,
      endTs: selectedDayEnd,
    },
    day1: {
      startTs: day1Start,
      endTs: day1End,
    },
    day2: {
      startTs: day2Start,
      endTs: day2End,
    },
    day3: {
      startTs: day3Start,
      endTs: day3End,
    }
  };
  setDaysSelector(dt);
};

// fake initial values for daysSelector, which the useState sets. we do because we want
// daysSelector to always have the shape defined in our DaysSelector interface rather than
// being null. why? by forcing daysSelector to *always* have the shape of DaysSelector
// interface, our code is simpler (we don't have to do daysSelector?.selectedDay with a question
// mark, we can always just do daysSelector.selectedDay)
const fakeInitValues = {
  selectedDay: {
    startTs: 1,
    endTs: 1,
  },
  day1: {
    startTs: 1,
    endTs: 1,
  },
  day2: {
    startTs: 1,
    endTs: 1,
  },
  day3: {
    startTs: 1,
    endTs: 1,
  }
};

// #endregion
