import React, { Dispatch, SetStateAction } from 'react';
import {
  Dialog, Grid2, Grid2Ct, Ty
} from '@languageconvo/wcl';
import { Trans } from '@lingui/react/macro';

interface Props {
    openHowToCancelPlanModal: boolean,
    setOpenHowToCancelPlanModal: Dispatch<SetStateAction<boolean>>,
}

// the cancel modal, when the user does not have a plan
export const CancelModalNoplan = ({
  openHowToCancelPlanModal,
  setOpenHowToCancelPlanModal
}: Props) => (
  <Dialog
    isOpen={openHowToCancelPlanModal}
    onClose={() => setOpenHowToCancelPlanModal(!openHowToCancelPlanModal)}
    width="xs"
    color="accentRed1"
  >
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty>
          <Trans>
            Once subscribed, if and when you want to cancel we make it easy to do right
            here in the website. Just click that button and you&apos;ll confirm here in this
            popup to cancel your plan.
          </Trans>
        </Ty>
      </Grid2>
    </Grid2Ct>
  </Dialog>
);
