import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  Ty,
  Grid2Ct,
  Grid2,
  CardStandard,
  IcSvgList,
} from '@languageconvo/wcl';
import { Trans, useLingui } from '@lingui/react/macro';
import { DateTime } from 'luxon';
import { useFragment } from 'react-relay';
import { CancelModal } from './components/Modals/CancelModal';
import { SubscriptionPlansCard } from './components/SubscriptionPlanCard';
import { NotificationManager } from './components/Notifications/NotificationManager';
import { GetUserRecentActionFragment$key } from './relay/__generated__/GetUserRecentActionFragment.graphql';
import { GetUserRecentActionFragment } from './relay/GetUserRecentAction';
import { CancelModalNoplan } from './components/Modals/CancelModalNoplan';
import { StripePortalModal } from './components/Modals/StripePortalModal';
import {
  SubscriptionCurrentPlan,
  SubscriptionStatus,
  SubscriptionPendingChange,
} from './ManageSubscriptionTypes';

interface Props {
  fragmentRefForHistoryTable: GetUserRecentActionFragment$key;
  userCurrentPlanSubscriptionData: any;
}

// Manage subscription page
export const ManageSubscription = ({
  fragmentRefForHistoryTable,
  userCurrentPlanSubscriptionData,
}: Props) => {
  // #region general

  const { t } = useLingui();

  // reading data of user recent action from `subs_mainupdate_history` based on this data
  // we will show notification to the user's
  const userCurrentActionData = useFragment(
    GetUserRecentActionFragment,
    fragmentRefForHistoryTable
  );

  // state to open/close cancel modal.
  const [openCancelPlanModal, setOpenCancelPlanModal] = useState<boolean>(false);

  // state to open/cloas modal when user does not have a plan
  const [openHowToCancelPlanModal, setOpenHowToCancelPlanModal] = useState<boolean>(false);

  // state to open/close modal when there is no stripe poral URL
  const [openGoToStripePortalModal, setOpenGoToStripePortalModal] = useState<boolean>(false);

  // Destructure the current plan from the userCurrentPlanSubscriptionData
  // as we need these values to show updated UI. Like on which plan user is
  const { current_plan, current_plan_status, trialstart_ts } = userCurrentPlanSubscriptionData;

  // #endregion

  // #region subCurrent and subStatus

  // subCurrent are the basic details of the user's current plan
  const subCurrent: SubscriptionCurrentPlan = {
    planDbId: null,
    canDoTrial: false,
  };

  // subStatus is the overall status (trialing, active, past due, etc.) of the user's current plan
  let subStatus: SubscriptionStatus = SubscriptionStatus.Unknown;

  // the user does currently have a stripe subscription. we determine if the status is trialing,
  // active, etc.
  if (current_plan) {
    subCurrent.planDbId = current_plan;

    if (current_plan_status === 1) {
      subStatus = SubscriptionStatus.Trialing;
    } else if (current_plan_status === 2) {
      subStatus = SubscriptionStatus.Active;
    } else if (current_plan_status === 3) {
      subStatus = SubscriptionStatus.PastDue;
    } else if (current_plan_status === 4) {
      subStatus = SubscriptionStatus.Incomplete;
    } else {
      throw new Error(
        'BIG ERROR: current_plan had a value, but current_plan_status was not an expected value'
      );
    }

    // user does not currently have a stripe subscription
  } else {
    // the user can do a free trial of group classes if the don't have a plan and this trialstart_ts
    // is null (which menans they have not done a free trial in the past)
    if (trialstart_ts === null) {
      subCurrent.canDoTrial = true;
    }
    subStatus = SubscriptionStatus.NoPlan;
  }

  // should never happen. we don't want code to continue if this does ever occur though. note that
  // if our if/else statement above is correct this can never occur so we have to ignore ts warning
  // about this not being possible
  // @ts-ignore
  if (subStatus === SubscriptionStatus.Unknown) {
    throw new Error('BIG ERROR: subStatus was Unknown, should never happen');
  }

  // #endregion

  // #region subPendingChange, if the user has an upcoming change to their plan

  let subPendingChange: SubscriptionPendingChange = SubscriptionPendingChange.None;

  // the user can only have a pending/future change scheduled if they have a current plan. if they
  // do *not* have a plan, obviously there's no way they could have an upcoming changes to a plan.
  // then, if the history table does contain a row, that means they may have some upcoming scheduled
  // change to their plan
  if (current_plan && userCurrentActionData.edges.length > 0) {
    const { ts_processed, subs_mainplans } = userCurrentActionData.edges[0].node;

    // extracting the main plan ID from the history table, other wise defaul value will be null
    const historyTableMainPlanId = subs_mainplans ? subs_mainplans.order : null;

    // Determine if 'ts_processed' timestamp is within the 24 hours by adding 24 hours to it
    //  and comparing it with the current time. If 'ts_processed' is null, assigning it with
    //  default value null.
    const timeWithin24H = ts_processed
      ? DateTime.fromISO(ts_processed).plus({ hours: 24 }) > DateTime.now()
      : null;

    // if historyTableMainPlanId is null, that indicates the user has a cancelation scheduled
    if (historyTableMainPlanId === null) {
      subPendingChange = SubscriptionPendingChange.Cancel;

      // we only expect the user's subscription status to be either "Trialing" or "Active".
      // If it's anything else, it means something unexpected happened,
      // and we are logging this error to sentry
      if (
        subStatus !== SubscriptionStatus.Trialing
        && subStatus !== SubscriptionStatus.Active
      ) {
        Sentry.captureException(
          new Error(
            `IMPORTANT! unexpected current_plan_status ${current_plan_status}`
          )
        );
      }

      // if historyTableMainPlanId is not null, they have some other pending change. either an
      // upgrade or a downgrade. first, the case of the user having an upgrade scheduled:
    } else if (historyTableMainPlanId > current_plan) {
      // here we need to show notification untill user upgrade request is withIn 24hours
      if (ts_processed === null || timeWithin24H) {
        subPendingChange = SubscriptionPendingChange.Upgrade;
      } else {
        subPendingChange = SubscriptionPendingChange.None;
      }

      // second, the case the user has a downgrade scheduled
    } else if (historyTableMainPlanId < current_plan) {
      subPendingChange = SubscriptionPendingChange.Downgrade;
    }
  }

  // #endregion

  return (
    <Grid2Ct>
      <Grid2 xs={12} xl={10} xlOffset={1}>
        {/* user notifs, e.g. they have an upcoming downgrade, they're in trial period etc */}
        <NotificationManager
          subStatus={subStatus}
          subPendingChange={subPendingChange}
          subCurrent={subCurrent}
        />

        {/* choose plans, subscription history, update payment method, cancel */}
        <Grid2Ct>
          {/* both plan cards */}
          <SubscriptionPlansCard
            subCurrent={subCurrent}
            subStatus={subStatus}
            subPendingChange={subPendingChange}
          />
          {/* Subscription history link */}
          <Grid2 xs={12} md={6}>
            <CardStandard
              titleText={t`Subscription History`}
              titleIcon={IcSvgList.knowledge2}
              color="accentPurple1"
              hovercursor="pointer"
              titleIconRight={1}
              onClickEntireCard={() => setOpenGoToStripePortalModal(true)}
            >
              <Ty removeMb>
                <Trans>Click here to view your billing history</Trans>
              </Ty>
            </CardStandard>
          </Grid2>
          {/* Update payment method */}
          <Grid2 xs={12} md={6}>
            <CardStandard
              titleText={t`Update Payment Method`}
              titleIcon={IcSvgList.creditcard1}
              color="accentBlue1"
              hovercursor="pointer"
              titleIconRight={1}
              onClickEntireCard={() => setOpenGoToStripePortalModal(true)}
            >
              <Ty removeMb>
                <Trans>Click to update your payment method</Trans>
              </Ty>
            </CardStandard>
          </Grid2>

          {/* cancel subscription */}
          <Grid2 xs={12} md={6}>
            <CardStandard
              titleIcon={IcSvgList.exit1}
              titleText={t`Cancel Subscription`}
              color="accentRed1"
              hovercursor="pointer"
              titleIconRight={1}
              onClickEntireCard={
                subStatus === SubscriptionStatus.NoPlan
                  ? () => {
                    setOpenHowToCancelPlanModal(true);
                  }
                  : () => setOpenCancelPlanModal(true)
              }
            >
              <Ty removeMb>
                <Trans>Click here to cancel your plan</Trans>
              </Ty>
            </CardStandard>
          </Grid2>

          {/* modal cancel plan, user does have a plan */}
          {openCancelPlanModal && (
            <CancelModal
              openCancelPlanModal={openCancelPlanModal}
              setOpenCancelPlanModal={setOpenCancelPlanModal}
              subCurrent={subCurrent}
              subPendingChange={subPendingChange}
            />
          )}

          {/* modal cancel plan, but user does not have a plan yet */}
          {openHowToCancelPlanModal && (
            <CancelModalNoplan
              openHowToCancelPlanModal={openHowToCancelPlanModal}
              setOpenHowToCancelPlanModal={setOpenHowToCancelPlanModal}
            />
          )}

          {openGoToStripePortalModal && (
            <StripePortalModal
              openGoToStripePortalModal={openGoToStripePortalModal}
              setOpenGoToStripePortalModal={setOpenGoToStripePortalModal}
            />
          )}
        </Grid2Ct>
      </Grid2>
    </Grid2Ct>
  );
};
