/**
 * @generated SignedSource<<f018645ab5d5c551914eb722692d1761>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetUserCurrentPlanSubscription$variables = {};
export type GetUserCurrentPlanSubscription$data = {
  readonly subs_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"GetUserCurrentPlanFragment">;
      };
    }>;
  };
};
export type GetUserCurrentPlanSubscription = {
  response: GetUserCurrentPlanSubscription$data;
  variables: GetUserCurrentPlanSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetUserCurrentPlanSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "subsConnection",
        "kind": "LinkedField",
        "name": "subs_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subs",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "GetUserCurrentPlanFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetUserCurrentPlanSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "subsConnection",
        "kind": "LinkedField",
        "name": "subs_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subs",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "current_plan",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "current_plan_status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trialstart_ts",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subs_mainplans",
                    "kind": "LinkedField",
                    "name": "subs_mainplans",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "order",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "discount_amount",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "32126b3957acc0ce81ab610644209537",
    "id": null,
    "metadata": {},
    "name": "GetUserCurrentPlanSubscription",
    "operationKind": "subscription",
    "text": "subscription GetUserCurrentPlanSubscription {\n  subs_connection {\n    edges {\n      node {\n        ...GetUserCurrentPlanFragment\n        id\n      }\n    }\n  }\n}\n\nfragment GetUserCurrentPlanFragment on subs {\n  current_plan\n  current_plan_status\n  trialstart_ts\n  subs_mainplans {\n    order\n    discount_amount\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "34faecb67da1194402492f44b48521a1";

export default node;
