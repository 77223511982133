import { useLingui } from '@lingui/react/macro';
import { OurSubPlanDetails } from './OurSubscriptionsTypes';

export const useOurSubscriptions = () => {
  const { t } = useLingui();

  const plan1Details: OurSubPlanDetails = {
    // the id in our database, very important it matches
    dbId: 1,
    // since we use this in many places throughout the app, we make title reusable
    title: t`Language Scout`,
    price: 9.99,
  };

  const plan2Details: OurSubPlanDetails = {
    dbId: 2,
    title: t`Global Explorer`,
    price: 29.99,
  };

  return { plan1Details, plan2Details };
};
