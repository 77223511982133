import React from 'react';
import {
  CardStandard, Ty, IcSvgList, Box, Link,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';
import { SmallSavingsPillSuccess, SmallSavingsPillWarn } from '../../../common/PurchaseCommon';
import { sitedata } from '../../../../../utils/sitedata';

interface Props {
  // the number of hours of the package
  timeInHour: number;
  // whether or not the user gets (true) or does not get a discount (false)
  canUserGetDiscount: boolean;
  // the amount of money they're saving/getting a discount; if canUserGetDiscount is false
  // then this will be 0, if it's true it should be > 0
  discountTotalNum: number;
  // the final price the user is paying
  totalPrice: number;
  // the language the user is purchasing
  purchasePkgLang: string;
}

export const CartDetails = ({
  timeInHour, canUserGetDiscount, discountTotalNum, totalPrice, purchasePkgLang,
}: Props) => {
  // convert the amounts to strings, for display
  const totalPriceStr = totalPrice.toFixed(2);
  const discountTotalStr = discountTotalNum.toFixed(2);

  return (
    <CardStandard
      titleText="Shopping Cart"
      titleIcon={IcSvgList.cart2}
      color="accentGreen1"
      titleIconRight={0}
    >
      <Ty align="center" cp={{ sx: { mt: 2 } }}>{purchasePkgLang} Lessons</Ty>
      <Ty align="center">{timeInHour} Hour Package</Ty>
      <Ty align="center">Total Price: ${totalPriceStr} </Ty>

      {/* justifyContent centers this small pill */}
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        {canUserGetDiscount ? (
          <SmallSavingsPillSuccess>
            <Ty align="center" v="small" removeMb>
              You saved ${discountTotalStr} with your subscription!
            </Ty>
          </SmallSavingsPillSuccess>
        ) : (
          <SmallSavingsPillWarn>
            <Ty align="center" v="small" removeMb>
              Save $2/hour with
              a <Link
                to={sitedata.url.app.manageSubscriptionDt.pathFull}
                component={NavLink}
                linkStyle="nostyle"
                // ignore eslint problem about indentation
                // eslint-disable-next-line
              ><u>subscription</u></Link>!
            </Ty>
          </SmallSavingsPillWarn>
        )}
      </Box>
    </CardStandard>
  );
};
