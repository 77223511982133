import React, {
  useEffect, useState
} from 'react';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/react';
import { useMutation } from 'react-relay';
import {
  Card, CardContent, Grid2Ct, Grid2, Ty, IcSvg, IcSvgList, Dialog, Ic, LottiePlayer,
} from '@languageconvo/wcl';
import { StuApptgroupCreateresvMutation } from '../../relay/StuApptgroupCreateresv';
import { StuApptgroupCancelresvMutation } from '../../relay/StuApptgroupCancelresv';
import { Step3DisplayButton } from './Step3DisplayButton';
import { AlignIconText } from './Display.style';
import { validateHourCycle } from '../../../../../common/utils/DatesTimes/HourCycle';
import { DaysSelector } from '../../GroupSchedule2';
import {
  createReservationErrorHandle,
  CreateReservationErrorCodes,
  CancelReservationErrorCodes,
  cancelReservationErrorHandle,
} from './Step3DisplayResrvHandler';
import GiraffeRelaxed from '../../../../../common/assets/lottie/giraffe_relaxed.json';
import { Loading } from '../Loading';

interface Props {
  groupLessons: any;
  currentTime: any;
  tsSettings: any;
  daysSelector: DaysSelector;
  isSubscribed: boolean;
}

// main component
export const Step3Display = ({
  groupLessons,
  currentTime,
  tsSettings,
  daysSelector,
  isSubscribed,
}: Props) => {
  // #region general

  // we'll set dataIsLoaded to true once we're ready to display the lessons. allows us to show
  // a loading component until then
  const [dataIsLoaded, setDataIsLoaded] = useState(false);

  // this hold the filteredData based on the selected day, {today, tomorrow, etc}
  const [filteredLessons, setFilteredLessons] = useState<any>([]);

  // will tell us if the user currently has reserved the max number of lessons
  // that they are allowed to reserve. for now this is 1, in future we may increase that
  const [maxLessonsReserved, setMaxLessonsReserved] = useState(false);

  // when the "reserve" button is clicked, we set this state to the uuid of the class
  // the user is trying to reserve. we use it to show a loading state on the reserve button
  const [reserveButtonInFlight, setReserveButtonInFlight] = useState('');

  // when the "cancel" button is clicked, we set this state to the uuid of the class
  // the user is trying to cancel their reservation for. we use it to show loading state on
  // the cancel button
  const [cancelresvButtonInFlight, setCancelresvButtonInFlight] = useState('');

  // time display settings
  const hrCy = validateHourCycle(tsSettings.hourCycle);
  const timeOpts: Intl.DateTimeFormatOptions = {
    timeStyle: 'short',
    hourCycle: hrCy,
  };

  // anytime the list of lessons changes (groupLessons), or the day the user has selected
  // changes (daysSelector), filter the list of lessons
  useEffect(() => {
    // the start and end of the day the user has selected
    const selecteddayStartTime = DateTime.fromMillis(daysSelector.selectedDay.startTs);
    const selecteddayEndTime = DateTime.fromMillis(daysSelector.selectedDay.endTs);

    // filter the list of lessons, removing any that are not on the day the user
    // has selected to view
    const newArray = groupLessons.filter((item: any) => {
      const lessonStartTime = DateTime.fromISO(item.node.starts_at);
      const lessonEndTime = DateTime.fromISO(item.node.ends_at);

      // display the lesson
      //  if the lesson ends after the start of the selected day
      //  and the start time is before the end time of the end of the selected day
      if (lessonEndTime >= selecteddayStartTime && lessonStartTime <= selecteddayEndTime) {
        return true;
      }
      return false;
    });
    setFilteredLessons(newArray);
    setDataIsLoaded(true);

    // determine if the user has reserved *any* lessons already. if they have, they
    // reached their maximum allowed (bc we only allow 1 reservation right now)
    let maxResReached = false;
    groupLessons.forEach((el: any) => {
      if (el.node.appt_group_reservations.length) {
        maxResReached = true;
      }
    });
    setMaxLessonsReserved(maxResReached);
  }, [groupLessons, daysSelector]);

  // #endregion

  // #region reservation error modals (for displaying errors with create and cancel reservation)

  /* create reservation error modal 
      The contents of the modal will be set based on what the error is. By default we
      set GeneralError just so there will be some content in the modal in case something
      goes wrong
  */
  const [resrvModalError, setResrvModalError] = useState<string>(
    CreateReservationErrorCodes.GeneralError
  );
  const [resrvModalState, setResrvModalState] = useState<boolean>(false);
  const handleResrvModalClose = () => {
    setResrvModalState(false);
  };

  /* cancel reservation error modal
      The contents of this modal are always the same, so we just need a state to handle
      showing/hiding it
  */
  const [cnclresrvModalState, setCnclresrvModalState] = useState<boolean>(false);
  const handleCnclresrvModalClose = () => {
    setCnclresrvModalState(false);
  };

  // #endregion

  // #region create and cancel reservation mutations

  // making a reservation mutation
  const [StudentReservation] = useMutation(StuApptgroupCreateresvMutation);
  const createReservation = (uuid: any) => {
    setReserveButtonInFlight(uuid);
    StudentReservation({
      variables: {
        lessonUuid: uuid,
      },
      onCompleted() {
        // the reservation was successful. the group lesson data in relay will come not from
        // this mutation response, but from the subscription. that takes a moment. so here
        // we keep the "loading" state of the button going for a bit. note that usually the
        // relay data will update *before* this timeout finishes, which is perfectly fine. that
        // is what we want. in that case, the ui will change -- the reserve button will be
        // hidden and the join/cancel buttons shown. that makes a seamless ux; the button stays
        // in loading state until the relay data changes to denote that the lesson has been reserved
        setTimeout(() => {
          setReserveButtonInFlight('');
        }, 2000);
      },
      onError(err: any) {
        setReserveButtonInFlight('');
        createReservationErrorHandle(err, setResrvModalState, setResrvModalError);
      },
    });
  };

  //  canceling group class reservation mutation
  const [CancelReservation] = useMutation(StuApptgroupCancelresvMutation);
  const cancelReservation = (uuid: any) => {
    setCancelresvButtonInFlight(uuid);
    CancelReservation({
      variables: {
        lessonUuid: uuid,
      },
      onCompleted() {
        // see the note about setReserveButtonInFlight in the createReservation function, the same
        // logic applies here for cancelations
        setTimeout(() => {
          setCancelresvButtonInFlight('');
        }, 2000);
      },
      onError(err: any) {
        setCancelresvButtonInFlight('');
        cancelReservationErrorHandle(err, setCnclresrvModalState);
      },
    });
  };

  // #endregion

  // this prevents the ui from very briefly showing "no lessons exist!". we are showing the
  // same loader shown higher up in the code flow, until data is fully ready
  if (!dataIsLoaded) {
    return <Loading />;
  }

  return (
    <>
      {
      filteredLessons.length
        ? filteredLessons.map((item: any) => (
          <Grid2Ct key={item.node.uuid}>
            <IndividualLesson
              lsn={item}
              tzOpts={timeOpts}
              currentTime={currentTime}
              createRes={createReservation}
              rsvButtonInFlight={reserveButtonInFlight}
              cancelRes={cancelReservation}
              cnclresvButtonInFlight={cancelresvButtonInFlight}
              daysSelector={daysSelector}
              maxLessonsReserved={maxLessonsReserved}
              isSubscribed={isSubscribed}
            />
          </Grid2Ct>
        )) : <NoLessonsExist />
      }

      {/* modal for create reservation errors */}
      <Dialog
        isOpen={resrvModalState}
        onClose={handleResrvModalClose}
        width="xs"
        color="accentRed1"
      >
        <ResrvModalContents
          resrvModalError={resrvModalError}
        />
      </Dialog>

      {/* modal for cancel reservation errors */}
      <Dialog
        isOpen={cnclresrvModalState}
        onClose={handleCnclresrvModalClose}
        width="xs"
        color="accentRed1"
      >
        <CnclresrvModalContents />
      </Dialog>
    </>
  );
};

// #region subcomponents

// #region display individual lesson

interface IndividualLessonTy {
  lsn: any;
  tzOpts: any;
  currentTime: any;
  createRes: any;
  rsvButtonInFlight: string;
  cancelRes: any;
  cnclresvButtonInFlight: string;
  daysSelector: DaysSelector;
  maxLessonsReserved: boolean;
  isSubscribed: boolean;
}

// display an individual lesson card with time, lesson title, button, etc.
const IndividualLesson = ({
  lsn,
  tzOpts,
  currentTime,
  createRes,
  rsvButtonInFlight,
  cancelRes,
  cnclresvButtonInFlight,
  daysSelector,
  maxLessonsReserved,
  isSubscribed,
}: IndividualLessonTy) => {
  const allDetails = lsn.node;
  const startsAt = DateTime.fromISO(`${lsn.node.starts_at}`);
  const startsAtDisplay = startsAt.toLocaleString(tzOpts);
  const startsAtMillis = startsAt.toMillis();
  const endsAtDisplay = DateTime.fromISO(`${lsn.node.ends_at}`).toLocaleString(tzOpts);
  const seatsReserved = lsn.node.num_reservations;
  const seatsTotal = lsn.node.max_students;

  // #region determine display title, icon

  // TODO: icon and title should come from a centralized place, right now this same code is
  //  duplicated in the classroom

  let titlePart1 = '';
  let titlePart2 = '';
  let theIcon = IcSvgList.knowledge2;

  // skill class
  if (lsn.node.type === 1) {
    titlePart1 = lsn.node.skills.skills_levels.skills_categories.title_en;
    titlePart2 = `Skill #${lsn.node.skills.display_order} - ${lsn.node.skills.title_en}`;

    // conversational practice class
  } else if (lsn.node.type === 2) {
    if (lsn.node.type_conversational_level === 1) {
      titlePart1 = 'Conversational Practice - For Beginners';
    } else if (lsn.node.type_conversational_level === 2) {
      titlePart1 = 'Conversational Practice - Intermediate & Advanced';
    }
    theIcon = IcSvgList.translate1;

    // question and answer class
  } else if (lsn.node.type === 3) {
    titlePart1 = 'Question & Answer';
    theIcon = IcSvgList.question1;
  }

  // #endregion

  // if the lesson started the previous day, we'll display a note to the user
  let startedPreviousDay = <span />;
  let paddingBot = {};
  if (startsAtMillis < daysSelector.selectedDay.startTs) {
    startedPreviousDay = <StartedPrevDay />;
    paddingBot = { pb: 0 };
  }

  // isReserved, will be true if the user has this lesson reserved
  let isReserved = false;
  if (lsn.node.appt_group_reservations.length) {
    isReserved = true;
  }

  return (
    <Grid2 xs={12} sx={{ mt: 1 }}>
      <Card>
        <CardContent>
          <Grid2Ct>
            {/* time, level, title, reserved */}
            <Grid2 xs={12} md={8} lg={8} xl={9}>
              {/* time */}
              <Grid2Ct>
                <Grid2 xs={12} sx={{ ...paddingBot }}>
                  <AlignIconText>
                    <IcSvg icon={IcSvgList.clock1} height="24px" width="24px" />&nbsp;&nbsp;&nbsp;
                    {/* text. if the user is not subscribed, then we will just show
                    the start time */}
                    <Ty removeMb>
                      {isSubscribed ? (<>{startsAtDisplay} - {endsAtDisplay}</>)
                        : (<>{startsAtDisplay}&nbsp;</>)}
                    </Ty>
                  </AlignIconText>
                </Grid2>

                {/* a note if the lesson started the previous day */}
                {startedPreviousDay}
              </Grid2Ct>

              {/* seats reserved. hide if user is not subscribed */}
              {isSubscribed ? (
                <Grid2Ct sx={{ mt: 0 }}>
                  <Grid2 xs={12}>
                    <AlignIconText>
                      <IcSvg icon={IcSvgList.group1} height="24px" width="24px" />&nbsp;&nbsp;&nbsp;
                      <Ty removeMb>
                        {seatsReserved} of {seatsTotal} Seats Reserved
                      </Ty>
                    </AlignIconText>
                  </Grid2>
                </Grid2Ct>
              ) : (null)}

              {/* title */}
              <Grid2Ct sx={{ mt: 0 }}>
                <Grid2 xs={12}>
                  <AlignIconText>
                    <IcSvg icon={theIcon} height="24px" width="24px" />&nbsp;&nbsp;&nbsp;
                    <Ty removeMb>
                      {lsn.node.type === 1 && (
                        <>{titlePart1}<br />{titlePart2}</>
                      )}
                      {(lsn.node.type === 2 || lsn.node.type === 3) && (
                        titlePart1
                      )}
                    </Ty>
                  </AlignIconText>
                </Grid2>
              </Grid2Ct>

              {/* if the student has reserved a seat in this class */}
              {isReserved ? (
                <Grid2Ct sx={{ mt: 0 }}>
                  <Grid2 xs={12}>
                    <AlignIconText>
                      <IcSvg icon={IcSvgList.desk1} height="24px" width="24px" />&nbsp;&nbsp;&nbsp;
                      <Ty removeMb>
                        You have a reserved seat in this class!
                      </Ty>
                    </AlignIconText>
                  </Grid2>
                </Grid2Ct>
              ) : (<div />)}
            </Grid2>

            {/* main button on top, and cancel button on bottom
                  the main top button can be "join now!", "reserve", "lesson full", or "ending soon"
             */}
            <Step3DisplayButton
              isReserved={isReserved}
              lessonDetails={allDetails}
              currentTime={currentTime}
              createReservation={createRes}
              reserveButtonInFlight={rsvButtonInFlight}
              cancelReservation={cancelRes}
              cancelresvButtonInFlight={cnclresvButtonInFlight}
              maxLessonsReserved={maxLessonsReserved}
            />
          </Grid2Ct>
        </CardContent>
      </Card>
    </Grid2>
  );
};

// if the lesson started the previous day, this will display some text
const StartedPrevDay = () => (
  <Grid2 xs={12} sx={{ paddingTop: 0, paddingLeft: '45px' }}>
    <Ty v="small">(started previous day)</Ty>
  </Grid2>
);

// #endregion

// #region create / cancel reservation error modal contents

// create reservation errors modal
interface ResrvModalContentsProps {
  resrvModalError: string,
}
const ResrvModalContents = ({
  resrvModalError,
} : ResrvModalContentsProps) => {
  // #region create reservation errors

  if (resrvModalError === CreateReservationErrorCodes.GeneralError) {
    return (
      <ResrvModalContentsContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;A Problem Occurred</Ty>
        <Ty>
          It looks like something went wrong. Please reload the page and check
          to see if your reservation was made.
        </Ty>
      </ResrvModalContentsContainer>
    );
  }

  if (resrvModalError === CreateReservationErrorCodes.LessonTooFarInAdvance) {
    return (
      <ResrvModalContentsContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;A Problem Occurred</Ty>
        <Ty>
          It looks like the class you tried to reserve a seat in starts more than 3 hours from now.
          At the moment, the furthest in advance a reservation can be made is 3 hours. In the near
          future, an optional (low cost) add-on to your subscription will be available that will
          allow you to make multiple reservations up to 3 days in advance!
        </Ty>
      </ResrvModalContentsContainer>
    );
  }

  if (resrvModalError === CreateReservationErrorCodes.StudentBlockedFromGroupLessons) {
    return (
      <ResrvModalContentsContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;A Problem Occurred</Ty>
        <Ty>
          Hmm, it seems like there&apos;s a problem with your classes subscription. Please
          contact our customer service team.
        </Ty>
      </ResrvModalContentsContainer>
    );
  }

  if (resrvModalError === CreateReservationErrorCodes.LessonNoLongerExists) {
    return (
      <ResrvModalContentsContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;A Problem Occurred</Ty>
        <Ty>
          Our apologies, it looks like this lesson might have been canceled. It should disappear
          from the page very soon, but if it doesn&apos;t please try reloading the page.
        </Ty>
      </ResrvModalContentsContainer>
    );
  }

  if (resrvModalError === CreateReservationErrorCodes.TooManyReservationsThisLesson) {
    return (
      <ResrvModalContentsContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;A Problem Occurred</Ty>
        <Ty>
          Unfortunately, you&apos;ve reserved then canceled your reservation for this lesson
          twice already, so you can&apos;t reserve it again.
        </Ty>
      </ResrvModalContentsContainer>
    );
  }

  if (resrvModalError === CreateReservationErrorCodes.NoActiveSubscription) {
    return (
      <ResrvModalContentsContainer>
        <Ty>
          Welcome to Bite-Sized Conversational Classes! To join a class, you&apos;ll
          need to subscribe. Head
          to the Purchase page to do that.
        </Ty>
      </ResrvModalContentsContainer>
    );
  }

  if (resrvModalError === CreateReservationErrorCodes.ReserveLimitReached) {
    return (
      <ResrvModalContentsContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;A Problem Occurred</Ty>
        <Ty>
          It looks like you already have a reservation in another class. Currently,
          you can only reserve a seat in one class at a time. In the near future we&apos;ll
          be adding an optional (low cost) add-on to subscriptions that will allow
          you to reserve up to 3 classes in advance.
        </Ty>
      </ResrvModalContentsContainer>
    );
  }

  if (resrvModalError === CreateReservationErrorCodes.LessonEndsSoon) {
    return (
      <ResrvModalContentsContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;A Problem Occurred</Ty>
        <Ty>
          It looks like that lesson has already ended, or is going to end soon so it
          can&apos;t be reserved. It should disappear from
          the page soon but if it doesn&apos;t, try reloading the page.
        </Ty>
      </ResrvModalContentsContainer>
    );
  }

  if (resrvModalError === CreateReservationErrorCodes.OtherLessonOverlap) {
    return (
      <ResrvModalContentsContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;A Problem Occurred</Ty>
        <Ty>
          It looks like  you have a reserved seat in another class that overlaps this one. Once
          that first lesson ends, you can reserve a seat / join this class.
        </Ty>
      </ResrvModalContentsContainer>
    );
  }

  if (resrvModalError === CreateReservationErrorCodes.LimitReachedForCurrentWeek) {
    return (
      <ResrvModalContentsContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;A Problem Occurred</Ty>
        <Ty>
          It looks like you&apos;ve already attended one class this week. The week resets on
          Monday morning. If you&apos;d like to attend more than one class per week, consider
          our unlimited subscription!
        </Ty>
      </ResrvModalContentsContainer>
    );
  }

  if (resrvModalError === CreateReservationErrorCodes.LessonFull) {
    return (
      <ResrvModalContentsContainer>
        <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;A Problem Occurred</Ty>
        <Ty>
          Unfortunately it looks like this lesson has filled up. That may change! Remember that
          the page updates automagically so take a look, you can watch and see if another
          student cancels.
        </Ty>
      </ResrvModalContentsContainer>
    );
  }

  // #endregion

  // #region cancel reservation errors

  if (resrvModalError === CancelReservationErrorCodes.GeneralError) {
    return (
      <ResrvModalContentsContainer>
        <span>cancel reservation general error</span>
      </ResrvModalContentsContainer>
    );
  }

  // #endregion

  // log error, should never happen
  Sentry.captureException(
    new Error('resrvModalError was unknown'),
    {
      extra: {
        resrvModalError,
      }
    }
  );

  // default return, no contents
  return (
    <ResrvModalContentsContainer>
      <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;A Problem Occurred</Ty>
      <Ty>Hmm, something went wrong. Please reload the page to check your reservation.</Ty>
    </ResrvModalContentsContainer>
  );
};

// cancel reservation errors modal
const CnclresrvModalContents = () => (
  <ResrvModalContentsContainer>
    <Ty><Ic color="accentRed1" iconName="triangle-exclamation" iconStyle="duotone" />&nbsp;&nbsp;A Problem Occurred</Ty>
    <Ty>Hmm, something went wrong. Please reload the page to check your reservation.</Ty>
  </ResrvModalContentsContainer>
);

const ResrvModalContentsContainer = ({ children }: any) => (
  <Grid2Ct>
    <Grid2 xs={12}>
      {children}
    </Grid2>
  </Grid2Ct>
);

// #endregion

// #region no lessons exist

const NoLessonsExist = () => (
  <Grid2Ct>
    <Grid2 xs={12} sx={{ mt: 1 }}>
      <Card>
        <CardContent>
          <Grid2Ct sx={{ minHeight: '400px' }}>
            {/* left side text */}
            <Grid2
              xs={12}
              md={5}
              mdOffset={2}
              lg={6}
              lgOffset={1}
              xl={4}
              xlOffset={2}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Ty align="center" removeMb v="h2New">
                It looks like there aren&apos;t any more group
                classes scheduled on this day
              </Ty>
            </Grid2>

            {/* right side lottie animation. justifyContent to horizontally center
            alignItems to vertically push lottie down to the bottom of the container */}
            <Grid2
              xs={12}
              md={4}
              lg={5}
              xl={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: { xs: 4, md: 0 },
                mb: { xs: 4, md: 0 },
              }}
            >
              <LottiePlayer animationSrc={GiraffeRelaxed} animationHeight={250} />
            </Grid2>
          </Grid2Ct>
        </CardContent>
      </Card>
    </Grid2>
  </Grid2Ct>
);

// #endregion

// #endregion
