// errors that can occur during create stripe checkout url
export enum ErrorsStuSubsCreatecheckoutlink {
  // Occurs when one or more Stripe variable required by this API call
  // are missing from our environment variables
  // This can also occur when our code failed to update Stripe customer id in subs table
  TryAgain = 'TryAgain',
  // Occurs when user's account is suspended
  NotAllowed = 'NotAllowed',
  // Occurs when API call timeout or something unexpected happend in backend code
  UnexpectedOrTimeout = 'UnexpectedOrTimeout',
  // Occurs if we got unepexected plan id in this case we are 
  // showing error to the user and not hiding the button
  UnexpectedPlanId = 'UnexpectedPlanId',
  // Failed to get checkout urls from backend.
  CheckoutURLNotFound = 'CheckoutURLNotFound'
}
