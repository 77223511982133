import React from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { PurchaseChoosePage2 } from './PurchaseChoosePage2';
import { PurchaseChooseQuery } from './relay/PurchaseChoose';

export const PurchaseChoosePage1 = ({ setPaypageLoading }: any) => {
  const response: any = useLazyLoadQuery(
    PurchaseChooseQuery,
    { },
  );

  return (
    <PurchaseChoosePage2
      fragmentReference={response}
      setPaypageLoading={setPaypageLoading}
    />
  );
};
