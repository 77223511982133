import React, { useState, useEffect } from 'react';
import {
  Grid2, Box, CardSmallColor, Dialog, IcSvgList, Grid2Ct, styled, WCLThemeProps,
  Ty, IcSvg, Ic,
} from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { useFragment } from 'react-relay';
import { PackagesDetail } from './relay/PackagesDetail';
import { sitedata } from '../../../../utils/sitedata';
import { PurchaseChooseFragment } from './relay/PurchaseChoose';
import { SmallcardMaxWidthContain, SmallSavingsPillWarn, SmallSavingsPillSuccess } from '../../common/PurchaseCommon';
import { CurrentSubscriptionTy } from '../../../../common/utils/subscriptions/OurSubscriptionsTypes';
import { ChoosePackageContainer } from './PurchaseChoose.style';

export const PurchaseChoosePage2 = ({ fragmentReference, setPaypageLoading }: any) => {
  // #region general

  // this will be for navigation to next (/pay) page
  const navigate = useNavigate();
  // language the user currently has selected
  const [selectedlanguageId, setSelectedLanguageId] = useState();

  // package related vars
  // currently selected package. we set the default (20 hour pkg) below
  const [selectedpkgName, setSelectedpkgName] = useState<string>();
  // to show packages based on each selected lanaguage in dropdown.
  const [packagesArray, setPackagesArray] = useState<PackagesArrayItem[]>([]);
  // this state will hold the pkgId which is selected by user
  const [selectedpkgId, setSelectedpkgId] = useState<number>();
  // this state will hold the hour length of the package which is selected by the user
  const [selectedpkgHours, setSelectedpkgHours] = useState<number>(0);

  // price releated vars
  // calculating the per hour price of each lang.
  const [selectedpkgPerHourPrice, setSelectedpkgPerHourPrice] = useState(0);
  // setting selected package price to send in mutation
  const [selectedpkgPrice, setSelectedpkgPrice] = useState<number>(0);

  // #endregion

  // #region reading lang learn data from relay store

  /**
   * here we are reading the language id (studentLangLearnId) that student has currently selected
   * Reason: we need this id in 2 main places
   *    1 - from the sitedata we have to find the language name and flag using this
   *        id we have a check below, if the element id matches this "studentLangLearnId"
   *        we store that elements information in a variable so we can render that on UI.
   *    2 - whenever there is a change in relay store we get the updated "studentLangLearnId"
   *        and we have to prepare the packages for that particular language, so to handle that
   *        situation we use this "studentLangLearnId"
  */
  const fragmentRefForUserLangLearn = fragmentReference.users_connection.edges[0].node;
  const data = useFragment(PurchaseChooseFragment, fragmentRefForUserLangLearn);
  const studentLangLearnId = data.lang_learning;

  // #endregion

  // #region user subscription data, determine hourly discount amount

  // the standard data from relay store regarding whether the user has a subsription, discount, etc
  const subData: CurrentSubscriptionTy = data.relaySubscription;

  // isStdEligibleForDiscount will be true if the user gets a discount on private lessons, and
  // discountPerHour tells us how much they get off per hour
  const isStdEligibleForDiscount = subData.privateDiscountGets;
  const discountPerHour = subData.privateDiscountAmt;

  // #endregion

  // #region calculate main display prices

  // calculate the discounted price per hour by subtracting the discount from the 
  // original price per hour, if the user gets a discount. the result is rounded to 2 decimal
  // places for consistent display
  let pricePerHour = selectedpkgPerHourPrice;
  if (discountPerHour > 0) {
    const displayPricePerHourCalc = pricePerHour - discountPerHour;
    pricePerHour = displayPricePerHourCalc;
  }
  // note that this creates a *string* representation of the number, rounded to 2 decimals
  const displayPricePerHour = pricePerHour.toFixed(2);

  // calculate the total discount by multiplying the discount per hour by the total number of hours
  // this represents the full discount amount applied for the given duration
  const totalDiscount = discountPerHour * selectedpkgHours;
  // total price after discount. note that toFixed creates a string
  const displayTotalPriceAfterDiscount = (selectedpkgPrice - totalDiscount).toFixed(2);

  // #endregion

  // #region reading packages details data from relay store

  const packagesDetailsData = useFragment(PackagesDetail, fragmentReference);
  const packagesData = packagesDetailsData.locations_connection.edges;

  // initialize an empty array to store languages, here we are preparing the data
  // which we will use to make packages for select component
  const languages: any = [];
  if (packagesData && packagesData.length) {
    packagesData?.forEach((info: any) => {
      if (info) {
        const newObj = {
          id: info?.node?.pk,
          name: info?.node?.title,
          value: info?.node?.packs,
        };

        // Pushing the new object to the languages array
        languages.push(newObj);
      }
    });
  }

  // #endregion

  // #region preparing data for inital page load

  // generic function which takes an array and return 20 Hours package as default.
  // we are calling this function from useEffect below, which will run on pageload 
  // as well as whenever there is a change occur in user lang learn field.
  // Reason: we wanted to display some states on the UI and this function will set
  // the values for those fields
  const userSelectedLanguage = (array: PackagesArrayItem[]): void => {
    const twentyHourPkg = array
      .filter((item: PackagesArrayItem) => (item.value.durationInHr === 20));
    // setting 20 hour pkg id by default if user don't change his pkg
    setSelectedpkgId(twentyHourPkg[0].value.pkgId);
    setSelectedpkgPrice(twentyHourPkg[0].value.price);
    setSelectedpkgName(twentyHourPkg[0].name);
    setSelectedpkgPerHourPrice(twentyHourPkg[0].value.pricePerHour);
    setSelectedpkgHours(twentyHourPkg[0].value.durationInHr);
  };

  // this function will take in an array of packages and make them
  // the format we want for our select component. So, we can show these as select 
  // options in select dropdown
  interface PackagesArrayItem {
    name: string;
    value: {
      duration: number;
      durationInHr: number;
      pkgId: number;
      price: number;
      pricePerHour: number;
      showBestPrice: boolean;
    }
  }
  const createPackagesArrayForSelectComponent = (array: any): PackagesArrayItem[] => {
    const tempArray = array.map((info: any) => {
      const durationInSeconds = info.duration;
      const durationInHours = durationInSeconds / 3600;
      const name = `${durationInHours} Hour Package`;
      const thePricePerHr = info.price / durationInHours;
      let bestprice = false;
      if (durationInHours >= 20) {
        bestprice = true;
      }

      // Creating an object with price duration and pkgId information
      const value = {
        price: info.price,
        pricePerHour: thePricePerHr,
        duration: durationInSeconds,
        durationInHr: durationInHours,
        pkgId: info.pk,
        showBestPrice: bestprice,
      };
      return { name, value };
    });

    // sort by duration, smallest to largest
    tempArray.sort((a: PackagesArrayItem, b: PackagesArrayItem) => {
      const durationA = a.value.duration;
      const durationB = b.value.duration;
      if (durationA < durationB) {
        return -1;
      }
      if (durationA > durationB) {
        return 1;
      }
      return 0;
    });

    return tempArray;
  };

  // this is the only useEffect which will call functions to prepare the
  // default value for packages which we will display as selected by default 
  // and prepare all the options of packages to pass in select component as options
  useEffect(() => {
    // Checking if languages is truthy
    if (languages) {
      // Filtering the languages array to find the preferred language
      const prefLang = languages.filter(
        (item: any) => (
          item.id === studentLangLearnId
        )
      );

      // setting default language id
      setSelectedLanguageId(prefLang[0]?.id);

      //  Checking if the preferred language has a value
      //  then we Map the preferred language value to create a new array of packages
      if (prefLang[0]?.value.length) {
        const newPackages = createPackagesArrayForSelectComponent(prefLang[0]?.value);
        setPackagesArray(newPackages);
        // generic function which takes an array and return 20 Hours package as default
        userSelectedLanguage(newPackages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentLangLearnId]);

  // #endregion

  // #region handle change for packages dropdown

  // user clicks to select a package. we get the package details and set them to our state vars
  const handlePackageSelect = (event: any) => {
    // find the package id that the user clicked on
    const thePkgId = parseInt(event.currentTarget.getAttribute('data-pkgid'), 10);
    let foundThePackage = false;
    packagesArray.forEach((el) => {
      if (el.value.pkgId === thePkgId) {
        foundThePackage = true;
        setSelectedpkgId(el.value.pkgId);
        setSelectedpkgPrice(el.value.price);
        setSelectedpkgName(el.name);
        setSelectedpkgPerHourPrice(el.value.pricePerHour);
        setSelectedpkgHours(el.value.durationInHr);
      }
    });

    // log to sentry if we did not find the package
    if (!foundThePackage) {
      Sentry.captureException(
        new Error('BIG PROBLEM: handlePackageSelect did not find the package the user clicked on!'),
        {
          extra: {
            pkgid: thePkgId,
            langid: selectedlanguageId,
          }
        }
      );
    }

    // close the modal
    handleModalClosePkg();
  };

  // #endregion

  // #region mutation call for continue button

  /** when user clicks on the continue button, we redirect user to
   * purchase -> pay page with packId as param
  */
  const handleContinue = () => {
    navigate(`${sitedata.url.app.purchasePay}${selectedpkgId}`);
    setPaypageLoading(true);
  };

  // #endregion

  // #region modals

  // select package
  const [modalStatePkg, setModalStatePkg] = useState<boolean>(false);
  const handleModalOpenPkg = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStatePkg(true);
  };
  const handleModalClosePkg = () => {
    setModalStatePkg(false);
  };

  // is subscribed, saving $ on private lessons
  const [modalStateSavingyes, setModalStateSavingyes] = useState<boolean>(false);
  const handleModalOpenSavingyes = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStateSavingyes(true);
  };
  const handleModalCloseSavingyes = () => {
    setModalStateSavingyes(false);
  };

  // is not subscribed, not saving $ on private lessons
  const [modalStateSavingno, setModalStateSavingno] = useState<boolean>(false);
  const handleModalOpenSavingno = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStateSavingno(true);
  };
  const handleModalCloseSavingno = () => {
    setModalStateSavingno(false);
  };
  // #endregion

  return (
    <>
      {/* main contents */}
      <Grid2Ct sx={{ mt: { xs: 1, sm: 3 } }}>
        {/* select pacakge, hourly price, total price -- large screens, horizontal */}
        <Grid2 xs={12} sx={{ display: { xs: 'none', xl: 'block' } }}>
          <Grid2Ct>
            {/* select package. this is kind of a fake <select> element */}
            {/* this first flex is for making this grid and the price/hourly total grid
              equal heights  */}
            <Grid2 xs={12} xl={5} xlOffset={1} sx={{ display: 'flex' }}>
              {/* width 100 is needed since parent is a flex. alignItems vertically centers
                this div, justifyContent horizontally centers it */}
              <Box sx={{
                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
              }}
              >
                <ChoosePackageContainer onClick={handleModalOpenPkg}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Ty removeMb>{selectedpkgName}</Ty>
                    <Box sx={{ display: 'flex', alignItems: 'center', ml: '5px' }}>
                      <Ic iconName="angle-down" iconStyle="solid" />
                    </Box>
                  </Box>
                </ChoosePackageContainer>
              </Box>
            </Grid2>

            {/* price, hourly and total - on large screens, horizontally aligned */}
            {/* display flex so that this grid and the select package grid above have the same
              height */}
            <Grid2 xs={12} xl={5} xlOffset={1} sx={{ display: 'flex' }}>
              {/* need width 100 when parent is flex */}
              <Box sx={{ width: '100%' }}>
                <Ty removeMb v="h2New">
                  ${displayPricePerHour} / Hour
                </Ty>
                <Ty v="h2New" cp={{ sx: { mt: '3px' } }}>
                  ${displayTotalPriceAfterDiscount} Total
                </Ty>
                {isStdEligibleForDiscount ? (
                  <Box sx={{ cursor: 'pointer' }} onClick={handleModalOpenSavingyes}>
                    <SmallSavingsPillSuccess>
                      <Ty align="center" v="small" removeMb>
                        <Ic iconName="star" iconStyle="duotone" color="accentGreen1" /> You&apos;re Saving!
                      </Ty>
                    </SmallSavingsPillSuccess>
                  </Box>
                ) : (
                  <Box sx={{ cursor: 'pointer' }} onClick={handleModalOpenSavingno}>
                    <SmallSavingsPillWarn>
                      <Ty align="center" v="small" removeMb>
                        <Ic iconName="star" iconStyle="duotone" color="accentYellow1" /> Save $2/Hour
                      </Ty>
                    </SmallSavingsPillWarn>
                  </Box>
                )}
              </Box>
            </Grid2>
          </Grid2Ct>
        </Grid2>

        {/* select pacakge, hourly price, total price -- smaller screens, centered */}
        <Grid2 xs={12} sx={{ display: { xs: 'block', xl: 'none' } }}>
          <Grid2Ct sx={{ mb: 2 }}>
            <Grid2 xs={12}>
              {isStdEligibleForDiscount ? (
                <Box sx={{ cursor: 'pointer' }} onClick={handleModalOpenSavingyes}>
                  {/* float the pill to the right side of the screen */}
                  <Box sx={{ marginLeft: 'auto', width: 'fit-content' }}>
                    <SmallSavingsPillSuccess>
                      <Ty align="center" v="small" removeMb>
                        <Ic iconName="star" iconStyle="duotone" color="accentGreen1" /> You&apos;re Saving!
                      </Ty>
                    </SmallSavingsPillSuccess>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ cursor: 'pointer' }} onClick={handleModalOpenSavingno}>
                  {/* float the pill to the right side of the screen */}
                  <Box sx={{ marginLeft: 'auto', width: 'fit-content' }}>
                    <SmallSavingsPillWarn>
                      <Ty align="center" v="small" removeMb>
                        <Ic iconName="star" iconStyle="duotone" color="accentYellow1" /> Save $2/Hour
                      </Ty>
                    </SmallSavingsPillWarn>
                  </Box>
                </Box>
              )}
            </Grid2>
          </Grid2Ct>

          <Ty removeMb v="h2New" align="center">
            ${displayPricePerHour} / Hour
          </Ty>
          <Ty v="h2New" cp={{ sx: { mt: 1 } }} align="center">
            ${displayTotalPriceAfterDiscount} Total
          </Ty>
          {/* width 100 is needed since parent is a flex. justifyContent horizontally centers it */}
          <Box sx={{
            display: 'flex', alignItems: 'center', justifyContent: 'center'
          }}
          >
            <ChoosePackageContainer onClick={handleModalOpenPkg}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Ty removeMb>{selectedpkgName}</Ty>
                <Box sx={{ display: 'flex', alignItems: 'center', ml: '5px' }}>
                  <Ic iconName="angle-down" iconStyle="solid" />
                </Box>
              </Box>
            </ChoosePackageContainer>
          </Box>
        </Grid2>

        {/* continue to checkout */}
        <Grid2 xs={12} sx={{ mt: { xs: 2, lg: 3 } }}>
          <SmallCardContainer>
            <CardSmallColor
              text="Continue to Checkout"
              icon={IcSvgList.cart2}
              color="accentGreen1"
              hovercursor="pointer"
              onClick={() => handleContinue()}
            />
          </SmallCardContainer>
        </Grid2>
      </Grid2Ct>

      {/* choose package modal */}
      <Dialog
        isOpen={modalStatePkg}
        onClose={handleModalClosePkg}
        width="sm"
        color="accentPurple1"
      >
        <Grid2Ct>
          {/* text, don't show on xs. reason being, we want users on phones to be able to see
              the 20 hour package that has "lowest per hour price!" without having to scroll
           */}
          <Grid2 xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Ty align="center">
              Click on the package you want to purchase!
            </Ty>
          </Grid2>

          {packagesArray.map((el) => {
            const { discountedPricePerHour, totalDiscountedPrice } = calculateDiscountedPrice(
              el.value.pricePerHour,
              discountPerHour,
              el.value.durationInHr
            );
            return (
              <Grid2 xs={12}>
                <PackageContainer
                  onClick={(event) => handlePackageSelect(event)}
                  data-pkgid={el.value.pkgId}
                >
                  {/* for sm and larger screens */}
                  <Grid2Ct sx={{ display: { xs: 'none', sm: 'flex' } }}>
                    {/* package name (e.g. "20 Hour Package") */}
                    <Grid2 xs={12} sm={6}>
                      <AlignIconText>
                        <IcSvg icon={IcSvgList.box1} height="24px" width="24px" />&nbsp;&nbsp;&nbsp;
                        <Ty v="h2New" removeMb>
                          {el.name}
                        </Ty>
                      </AlignIconText>

                      {/* show "lowest price per hour!" for 20 and 50 hr packages */}
                      {el.value.showBestPrice ? (
                        <AlignIconText>
                          <IcSvg icon={IcSvgList.star1} height="16px" width="16px" />&nbsp;&nbsp;
                          <Ty removeMb>
                            Lowest price per hour!
                          </Ty>
                        </AlignIconText>
                      ) : null}
                    </Grid2>

                    {/* price per hour, total price */}
                    <Grid2 xs={12} sm={6}>
                      <Ty removeMb align="right">
                        {isStdEligibleForDiscount ? (
                          <>
                            ${discountedPricePerHour} / Hour
                            <br />
                          </>
                        ) : (
                          <>
                            ${el.value.pricePerHour.toFixed(2)} / Hour
                            <br />
                          </>
                        )}
                        {isStdEligibleForDiscount ? (
                          <>
                            ${totalDiscountedPrice} Total
                          </>
                        ) : (
                          <>${el.value.price} Total</>
                        )}

                      </Ty>
                    </Grid2>
                  </Grid2Ct>

                  {/* for xs screens */}
                  <Grid2Ct sx={{ display: { xs: 'flex', sm: 'none' } }}>
                    {/* package name (e.g. "20 Hour Package") */}
                    <Grid2 xs={12}>
                      <AlignIconText>
                        <IcSvg icon={IcSvgList.box1} height="24px" width="24px" />&nbsp;&nbsp;&nbsp;
                        <Ty v="h2New" removeMb>
                          {el.name}
                        </Ty>
                      </AlignIconText>
                    </Grid2>

                    {/* price per hour, total price. we remove top padding to condense things enough
                      so that on most phones, the "lowest price per hour!" for the 20 hour package
                      is visible without scrolling down
                   */}
                    <Grid2 xs={12} sx={{ pt: 0 }}>
                      <Ty removeMb>
                        ${el.value.pricePerHour.toFixed(2)} / Hour
                      </Ty>
                      <Ty removeMb>
                        ${el.value.price} Total
                      </Ty>
                    </Grid2>

                    {/* show "lowest price per hour!" for 20 and 50 hr packages */}
                    {el.value.showBestPrice ? (
                      <Grid2 xs={12}>
                        <AlignIconText>
                          <IcSvg icon={IcSvgList.star1} height="12px" width="12px" />&nbsp;&nbsp;&nbsp;
                          <Ty removeMb>
                            Lowest price per hour!
                          </Ty>
                        </AlignIconText>
                      </Grid2>
                    ) : null}
                  </Grid2Ct>
                </PackageContainer>
              </Grid2>
            );
          })}
        </Grid2Ct>
      </Dialog>

      {/* modal subscribed, saving money */}
      <Dialog
        isOpen={modalStateSavingyes}
        onClose={handleModalCloseSavingyes}
        width="xs"
        color="accentGreen1"
      >
        You&apos;re saving ${discountPerHour}/hour on every private lesson with your
        subscription. Thanks for being a subscriber!
      </Dialog>

      {/* modal not subscribed, not saving money */}
      <Dialog
        isOpen={modalStateSavingno}
        onClose={handleModalCloseSavingno}
        width="xs"
        color="accentYellow1"
      >
        Save $1 - $2/hour on every private lesson you take, when you subscribe
        to one of our bite-sized conversational class plans!
      </Dialog>
    </>
  );
};

// #region subcomponents

// #region container for each small card
const SmallCardContainer = ({ children }: any) => (
  <Box display="flex" justifyContent="center">
    <SmallcardMaxWidthContain>
      {children}
    </SmallcardMaxWidthContain>
  </Box>
);

// #endregion

// #region choose package modal contents

const AlignIconText = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const PackageContainer = styled(Box)<WCLThemeProps>(({ theme }) => ({
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentPurple1}`,
  borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
  padding: '16px 20px 16px 20px',
  cursor: 'pointer',
}));

// #endregion

// loading state
export const ChoosePackageLoading = () => (
  <Grid2Ct sx={{ mt: { xs: 1, sm: 2 } }}>
    <Grid2 xs={12} sx={{ mt: '130px' }}>
      <SmallCardContainer>
        <CardSmallColor
          text=""
          icon={IcSvgList.cart2}
          color="accentGreen1"
        />
      </SmallCardContainer>
    </Grid2>
  </Grid2Ct>
);

// #endregion

// #region for calculating the discounted price
interface DiscountedPrice {
  discountedPricePerHour: number;
  totalDiscountedPrice: number;
}

/**
 * Calculates the discounted price based on the original price per hour, 
 * the discount applied per hour, 
 * and the total duration in hours. The result includes both the per-hour discounted price and the 
 * total discounted price for the given duration, both rounded to two decimal places.
 *
 * @param pricePerHour - The original price per hour, before applying any discount.
 * @param discountPerHour - The discount amount that is subtracted from the original price per hour.
 * @param durationInHr - The total number of hours for which the discount is applied.
 * @returns An object containing:
 *   - `discountedPricePerHour`: The price per hour after applying the discount, rounded 
 * to two decimal places.
 *   - `totalDiscountedPrice`: The total price for the given duration after 
 * applying the discount, rounded to two decimal places.
 */
export const calculateDiscountedPrice = (
  pricePerHour: number,
  discountPerHour: number,
  durationInHr: number
): DiscountedPrice => {
  const discountedPricePerHour = pricePerHour - discountPerHour;
  const totalDiscountedPrice = discountedPricePerHour * durationInHr;

  return {
    // Round to 2 decimal places
    discountedPricePerHour: parseFloat(discountedPricePerHour.toFixed(2)),
    // Round to 2 decimal places
    totalDiscountedPrice: parseFloat(totalDiscountedPrice.toFixed(2)),
  };
};

// #endregion
