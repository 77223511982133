/**
 * @generated SignedSource<<3be69595c97f87de82280d3d9b4c1736>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetUserRecentActionQuery$variables = {};
export type GetUserRecentActionQuery$data = {
  readonly subs_details_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly subs_mainupdate_history_connection: {
          readonly " $fragmentSpreads": FragmentRefs<"GetUserRecentActionFragment">;
        };
      };
    }>;
  };
};
export type GetUserRecentActionQuery = {
  response: GetUserRecentActionQuery$data;
  variables: GetUserRecentActionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "created_at": "desc"
    }
  }
],
v2 = [
  (v0/*: any*/),
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "ts_user_action": "desc"
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetUserRecentActionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "subs_detailsConnection",
        "kind": "LinkedField",
        "name": "subs_details_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subs_detailsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subs_details",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "subs_mainupdate_historyConnection",
                    "kind": "LinkedField",
                    "name": "subs_mainupdate_history_connection",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "GetUserRecentActionFragment"
                      }
                    ],
                    "storageKey": "subs_mainupdate_history_connection(first:1,order_by:{\"ts_user_action\":\"desc\"})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "subs_details_connection(first:1,order_by:{\"created_at\":\"desc\"})"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetUserRecentActionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "subs_detailsConnection",
        "kind": "LinkedField",
        "name": "subs_details_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subs_detailsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subs_details",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "subs_mainupdate_historyConnection",
                    "kind": "LinkedField",
                    "name": "subs_mainupdate_history_connection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subs_mainupdate_historyEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "subs_mainupdate_history",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "subs_mainplans",
                                "kind": "LinkedField",
                                "name": "subs_mainplans",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "order",
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ts_processed",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "subs_mainupdate_history_connection(first:1,order_by:{\"ts_user_action\":\"desc\"})"
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "subs_details_connection(first:1,order_by:{\"created_at\":\"desc\"})"
      }
    ]
  },
  "params": {
    "cacheID": "e38d2d131e8675c92058a3f0ffffd8d5",
    "id": null,
    "metadata": {},
    "name": "GetUserRecentActionQuery",
    "operationKind": "query",
    "text": "query GetUserRecentActionQuery {\n  subs_details_connection(first: 1, order_by: {created_at: desc}) {\n    edges {\n      node {\n        subs_mainupdate_history_connection(order_by: {ts_user_action: desc}, first: 1) {\n          ...GetUserRecentActionFragment\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment GetUserRecentActionFragment on subs_mainupdate_historyConnection {\n  edges {\n    node {\n      subs_mainplans {\n        order\n        id\n      }\n      ts_processed\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "37500c5967c0fc69a9634e49f1d854d2";

export default node;
