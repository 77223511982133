import React, { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { PurchasePayPage0 } from './components/PurchasePayPage0';
import { PayPageLoading } from '../../common/PayPageLoading';
import { ErrBoundaryPaypage } from './components/ErrBoundaryPaypage';

export const PurchasePrivatePay = () => {
  // eslint-disable-next-line
  const x = 1;

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <ErrBoundaryPaypage err={error} />
      )}
    >
      <Suspense fallback={<PayPageLoading />}>
        {/* purchase -> pay page lazyload query component */}
        <PurchasePayPage0 />
      </Suspense>
    </Sentry.ErrorBoundary>
  );
};
