import React, { Suspense, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { Trans } from '@lingui/react/macro';
import {
  Grid2Ct, Grid2, Ty, Ic, CardStandard, IcSvgList, Dialog, Link, Box, useTheme,
} from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useFragment } from 'react-relay';
import { PurchaseChoosePage1 } from './purchaseprivate/choose/PurchaseChoosePage1';
import { ChoosePackageLoading } from './purchaseprivate/choose/PurchaseChoosePage2';
import { PrivateCredits } from './privatecredits/PrivateCredits';
import { sitedata } from '../../utils/sitedata';
import { PayPageLoading } from './common/PayPageLoading';
import { useEventLogger } from '../../common/utils/eventmanager/eventmanager';
import { PurchaseGroup1 } from './purchasegroup/PurchaseGroup1';
import { PurchaseMainLayoutFragment$key } from './relay/__generated__/PurchaseMainLayoutFragment.graphql';
import { PurchaseMainLayoutFragment } from './relay/PurchaseMainLayout';
import { CurrentSubscriptionTy, SubscriptionPlanTy } from '../../common/utils/subscriptions/OurSubscriptionsTypes';
import { PurchaseHowSubsWork } from './howsubswork/PurchaseHowSubsWork';
import { MuxPlayerCustomPurchhowsub } from './howsubswork/PurchaseHowSubsWork.style';

// TODO: the PurchaseChoosePage1 component requirse data about the user's
//  subscription. we should get that data in a component above this one [that part is done now]
//  and pass it down to that component
interface Props {
  fragrefUserSubscription: PurchaseMainLayoutFragment$key;
}

export const PurchaseMainLayout3 = ({
  fragrefUserSubscription
}: Props) => {
  // #region general

  const { t } = useTranslation(['videos']);
  const theme = useTheme();
  const bRad = `${theme?.ourTheme.borders.borderRadius.medium}px`;

  // get our relaySubscription data; this is data we calculate at the app level about the
  // user's subscription (whether they're subscribed, what plan they have, etc.)
  const userSubscriptionPlanData = useFragment(
    PurchaseMainLayoutFragment,
    fragrefUserSubscription
  );
    // determine if the user is subscribed to group classes or not. in future we may add a
    // third plan, so we use !== NoPlan !== Unknown instead of === Plan1 or Plan2
  const subdata: CurrentSubscriptionTy = userSubscriptionPlanData.relaySubscription;
  let isSubscribed = false;
  if (subdata.plan !== SubscriptionPlanTy.NoPlan
      && subdata.plan !== SubscriptionPlanTy.Unknown) {
    isSubscribed = true;
  }

  const [paypageLoading, setPaypageLoading] = useState(false);

  // record users when they visit the purchase page
  const { evtStartSessionRecording } = useEventLogger();
  evtStartSessionRecording();

  // link to purchase history page
  const urlPurchasehistory = sitedata.url.app.purchaseHistoryDt.pathFull;
  const clickableTypeObjMessagesPurchasehistory = {
    to: urlPurchasehistory,
    component: NavLink,
  };

  // link to teacher tip page
  const urlTipteacher = sitedata.url.app.purchaseTip;
  const clickableTypeObjMessagesTipteacher = {
    to: urlTipteacher,
    component: NavLink,
  };

  // if the user just successfully subscribed to group classes via stripe, they are returned
  // to this purchase page with a query string: /purchase?subs=random_text_from_stripe
  // in this case we'll show them a success message
  const [searchParams] = useSearchParams();
  const subSuccess = searchParams.get('subs');
  let userSubscribedSuccessfully = false;
  if (subSuccess && subSuccess.length > 3) {
    userSubscribedSuccessfully = true;
  }

  // #endregion

  // #region modals

  // modal for info about purchasing private lessons
  const [modalStatePrivatelsn, setModalStatePrivatelsn] = useState<boolean>(false);
  const handleModalOpenPrivatelsn = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStatePrivatelsn(true);
  };
  const handleModalClosePrivatelsn = () => {
    setModalStatePrivatelsn(false);
  };

  // modal for info about purchasing group classes
  const [modalStateGroup, setModalStateGroup] = useState<boolean>(false);
  const handleModalOpenGroup = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStateGroup(true);
  };
  const handleModalCloseGroup = () => {
    setModalStateGroup(false);
  };

  // modal for info about private lesson credits
  const [modalStatePrivatelsncred, setModalStatePrivatelsncred] = useState<boolean>(false);
  const handleModalOpenPrivatelsncred = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStatePrivatelsncred(true);
  };
  const handleModalClosePrivatelsncred = () => {
    setModalStatePrivatelsncred(false);
  };

  // #endregion

  if (paypageLoading) {
    return (
      <PayPageLoading />
    );
  }

  // layout of the purchase home page
  return (
    <>
      {/* thank you message if the user just successfully subscribed to group classes */}
      {userSubscribedSuccessfully === true && (
        <Grid2Ct sx={{ mb: 1 }}>
          <Grid2 xs={12} md={6} mdOffset={3}>
            <CardStandard
              titleText="Thank you!"
              titleIcon={IcSvgList.gift1}
              color="accentGreen1"
              titleIconRight={0}
            >
              <Grid2Ct sx={{ mt: 1 }}>
                <Grid2 xs={12}>
                  <Ty>
                    Thank you! You should see this page automatically update shortly, showing
                    the plan you subscribed to and giving you access to your subscription benefits.
                  </Ty>
                </Grid2>
              </Grid2Ct>
            </CardStandard>
          </Grid2>
        </Grid2Ct>
      )}

      {/* purchase private lessons, purchase group classes */}
      <Grid2Ct>
        {/* purchase private lessons
            on sm and larger screens, we make this the same height as the group lessons box using
            display flex. on xs, they are not the same height
        */}
        <Grid2 xs={12} md={6} sx={{ display: { xs: 'block', sm: 'flex' } }}>
          <CardStandard
            titleText="Private, Personalized Lessons"
            titleIcon={IcSvgList.teacher5}
            color="accentPurple1"
            titleIconRight={2}
            onClickRightIcon={handleModalOpenPrivatelsn}
            // width 100% is needed when using display flex
            cp={{ sx: { width: '100%' } }}
          >
            <Suspense fallback={<ChoosePackageLoading />}>
              <PurchaseChoosePage1 setPaypageLoading={setPaypageLoading} />
            </Suspense>
          </CardStandard>
        </Grid2>

        {/* purchase group classes subscription
            on sm and larger screens, we make this the same height as the group classes box using
            display flex. on xs, they are not the same height
        */}
        <Grid2 xs={12} md={6} sx={{ display: { xs: 'block', sm: 'flex' } }}>
          <CardStandard
            titleText="Bite-Sized Conversational Classes"
            titleIcon={IcSvgList.group1}
            color="accentBlue1"
            titleIconRight={2}
            onClickRightIcon={handleModalOpenGroup}
              // width 100% is needed when using display flex
            cp={{ sx: { width: '100%' } }}
          >
            <Sentry.ErrorBoundary fallback={<ErrorBoundaryGroupSubscription />}>
              <Suspense fallback={<div />}>
                <PurchaseGroup1 isSubscribed={isSubscribed} />
              </Suspense>
            </Sentry.ErrorBoundary>
          </CardStandard>
        </Grid2>
      </Grid2Ct>

      {/* if the user is not subscribed, show a "how subscriptions work" div */}
      <PurchaseHowSubsWork
        isSubscribed={isSubscribed}
        handleModalOpenGroup={handleModalOpenGroup}
      />

      {/* private lesson credits remaining, purchase history link, tip teacher link */}
      <Grid2Ct sx={{ mt: 1 }}>
        {/* credits available
            note: we do *not* make this equal height to history link, because the user could have
            many different languages which makes this card very tall
         */}
        <Grid2 xs={12} md={6}>
          <CardStandard
            titleText="Private Lessons Remaining"
            titleIcon={IcSvgList.coin1}
            titleIconRight={2}
            color="accentYellow1"
            onClickRightIcon={handleModalOpenPrivatelsncred}
          >
            {/* we wrap this in an error boundary; it's calling old php code that is pretty
                slow, so it's definitely possible we'll get errors (especially timeouts) and
                we don't want the entire page to error in that case
             */}
            <Sentry.ErrorBoundary fallback={<ErrorBoundaryPrivateCredits />}>
              <PrivateCredits />
            </Sentry.ErrorBoundary>
          </CardStandard>
        </Grid2>

        {/* purchase history link and tip teacher link */}
        <Grid2 xs={12} md={6}>
          <Grid2Ct>
            {/* purchase history link */}
            <Grid2 xs={12}>
              <CardStandard
                titleText="Purchase History"
                titleIcon={IcSvgList.knowledge2}
                color="accentPurple1"
                clickableLink1={{ ...clickableTypeObjMessagesPurchasehistory }}
              >
                <Link
                  to={urlPurchasehistory}
                  component={NavLink}
                  linkStyle="nostyle"
                >
                  <Grid2Ct>
                    <Grid2 xs={12} display="flex">
                      <Ty removeMb><Trans>View your complete purchase history</Trans></Ty>
                    </Grid2>
                  </Grid2Ct>
                </Link>
              </CardStandard>
            </Grid2>

            {/* tip teacher link */}
            <Grid2 xs={12}>
              <CardStandard
                titleText="Tip Teacher"
                titleIcon={IcSvgList.teacher4}
                color="accentGreen1"
                cp={{ sx: { width: '100%' } }}
                clickableLink1={{ ...clickableTypeObjMessagesTipteacher }}
              >
                <Link
                  to={urlTipteacher}
                  component={NavLink}
                  linkStyle="nostyle"
                >
                  <Grid2Ct>
                    <Grid2 xs={12} display="flex">
                      <Ty removeMb>Never expected, always greatly appreciated!</Ty>
                    </Grid2>
                  </Grid2Ct>
                </Link>
              </CardStandard>
            </Grid2>
          </Grid2Ct>
        </Grid2>
      </Grid2Ct>

      {/* private lesson info modal */}
      <Dialog
        isOpen={modalStatePrivatelsn}
        onClose={handleModalClosePrivatelsn}
        width="lg"
        color="accentPurple1"
      >
        <Grid2Ct>
          <Grid2 xs={12} sm={10} smOffset={1}>
            <Ty v="h2New">
              <Ic iconName="square-info" iconStyle="duotone" color="accentBlue1" />&nbsp;&nbsp;What do we mean by &quot;private lessons&quot;?
            </Ty>
            <Ty>
              Private lessons are just you and a teacher. You can, if you want, invite other people
              that you know...at no extra cost! But no other students will be there, it is a private
              lesson just for you.
            </Ty>

            <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
              <Ic iconName="bag-shopping" iconStyle="duotone" color="accentOrange1" />&nbsp;&nbsp;If I buy for example a 10 hour package, can I schedule lessons of different lengths?
            </Ty>
            <Ty>
              Definitely. No matter which package you buy, you can schedule lessons that are 30
              minutes long, or 1 hour long, or 1.5 hours, etc. Scheduling is extremely flexible and
              you can schedule each lesson differently.
            </Ty>

            <Ty v="h2New" cp={{ sx: { mt: 3 } }}>
              <Ic iconName="calendar-days" iconStyle="duotone" color="accentGreen1" />&nbsp;&nbsp;How long do I have to use the lessons?
            </Ty>
            <Ty>
              You have an entire year to use your lessons, so plenty of time! If after 1 year
              you have not used them all yet, you can reactivate them by purchasing a new package.
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>

      {/* modal how classes work */}
      <Dialog
        isOpen={modalStateGroup}
        onClose={handleModalCloseGroup}
        width="md"
        color="accentGreen1"
      >
        {/* this box lets us add border radius to the video */}
        <Box sx={{ display: 'flex', overflow: 'hidden', borderRadius: bRad }}>
          <MuxPlayerCustomPurchhowsub
            playbackId={t('cpHowClassesWork1')!}
            theme="minimal"
            thumbnailTime={0}
          />
        </Box>
        <Grid2Ct sx={{ mt: 2 }}>
          <Grid2 xs={12}>
            <Ty>
              Play the video above to learn all about how our unique, bite-sized conversational
              classes can help you learn a language incredibly affordably!
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>

      {/* private lesson credits */}
      <Dialog
        isOpen={modalStatePrivatelsncred}
        onClose={handleModalClosePrivatelsncred}
        width="xs"
        color="accentYellow1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty>
              If you have purchased private lessons, here you&apos;ll be able to see how many
              lesson hours you have remaining!
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};

// if the private lessons query fails, we'll show this. that query is old php code so is more
// brittle than most
const ErrorBoundaryPrivateCredits = () => (
  <Grid2Ct>
    <Grid2
      xs={12}
      sm={10}
      smOffset={1}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Ty align="center">
        <Ic iconName="triangle-exclamation" color="accentOrange1" iconStyle="duotone" />&nbsp;&nbsp;Hmm, something
        went wrong. Please reload the page, and if you continue seeing this error *please*
        let us know!
      </Ty>
    </Grid2>
  </Grid2Ct>
);

// if the group subscriptions gave some error we will show this boundary in that card
const ErrorBoundaryGroupSubscription = () => (
  <Grid2Ct>
    <Grid2
      xs={12}
      sm={10}
      smOffset={1}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Ty align="center">
        <Ic iconName="triangle-exclamation" color="accentOrange1" iconStyle="duotone" />&nbsp;&nbsp;Hmm, something
        went wrong. Please reload the page, and if you continue seeing this error *please*
        let us know!
      </Ty>
    </Grid2>
  </Grid2Ct>
);
