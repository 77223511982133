import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  Box, CardSmallColor, CardStandard, Grid2, IcSvgList, Ty, Grid2Ct,
} from '@languageconvo/wcl';
import { useMutation } from 'react-relay';
import { Trans, useLingui } from '@lingui/react/macro';
import { StuSubsCreatecheckoutlinkForManageSubsPage } from '../relay/StuSubsCreatecheckoutlinkForManageSubsPage';
import { handleErrorForCreateCheckoutLink } from '../utils/checkoutErrorHandler';
import { SubscribeToBasicPlanModal } from './Modals/SubscribeToBasicPlanModal';
import { SubscribeToPremiumPlanModal } from './Modals/SubscribeToPremiumPlanModal';
import { UpgradeModal } from './Modals/UpgradeModal';
import { DowngradeModal } from './Modals/DowngradeModal';
import { CurrentplanModal } from './Modals/CurrentplanModal';
import { Plan1Benefits, Plan2Benefits } from './PlanBenefits';
import { useOurSubscriptions } from '../../../../common/utils/subscriptions/useOurSubscriptions';
import { SubscriptionCurrentPlan, SubscriptionStatus, SubscriptionPendingChange } from '../ManageSubscriptionTypes';
import { ErrorsStuSubsCreatecheckoutlink } from '../Errors/Errors';

interface Props {
  subCurrent: SubscriptionCurrentPlan;
  subStatus: SubscriptionStatus;
  subPendingChange: SubscriptionPendingChange;
}

// component responsible for showing plans card and dynamic text on plan card buttons
// in this component we are doing two main things creating checkout urls if user is not subscribed
// and handling upgrade/downgrade if user is subscribed to any plan
export const SubscriptionPlansCard = ({
  subCurrent,
  subStatus,
  subPendingChange,
}: Props) => {
  // #region general

  const { plan1Details, plan2Details } = useOurSubscriptions();
  const { t } = useLingui();

  // #endregion

  // #region state variables

  // state for opening and closing the modal
  const [openModalForBasicPlan, setOpenModalForBasicPlan] = useState<boolean>(false);

  const [openModalForPremiumPlan, setOpenModalForPremiumPlan] = useState<boolean>(false);

  // state for disabling the button if the mutation call to
  // create checkout url is in flight
  const [isInFlight, setIsInFlight] = useState<boolean>(false);

  // state to store the checkoutUrl, because we have to use the url
  // to redirect user to stripe checkout page
  const [checkoutUrl, setCheckoutUrl] = useState('');

  // state to store the error code returned from backend in any api call failure
  // in the create checkout link api calls
  const [errorCodeCreatecheckouturl, setErrorCodeCreatecheckouturl] = useState<
    null | ErrorsStuSubsCreatecheckoutlink>(null);

  // state to open and close current plan modal
  const [openCurrentPlanModal, setOpenCurrentPlanModal] = useState<boolean>(false);

  // #endregion

  // #region states to manage user actions: upgrade, downgrade, or cancel subscription

  // state to open/close the Upgrade Plan modal.
  const [openUpgradePlanModal, setOpenUpgradeModal] = useState<boolean>(false);

  // state to open/close the downgrade Plan modal.
  const [openDownGradePlanModal, setOpenDownGradePlanModal] = useState<boolean>(false);

  // #endregion

  // #region for handling user subscription(subscribing, upgrade and downgrade)

  // mutation call for generating checkout url based on user selectd plan,
  const [commitStuSubsCreatecheckoutlink] = useMutation(
    StuSubsCreatecheckoutlinkForManageSubsPage
  );

  // `handleClickSubscribeToPlan` function responsible for executing creating checkout url mutation
  // as user click on any plan based on plan id we are opening our basic and premium plan modal
  const handleClickSubscribeToPlan = (planId: number) => {
    // this allows us to show the button is loading
    setIsInFlight(true);

    // remove any previous error codes so that, if the user had an error, then closed and reopened
    // the modal, that previous error wont show anymore
    setErrorCodeCreatecheckouturl(null);

    // here, we decide whether to open the modal for the basic plan, or the premium plan; each has
    // different wording and different links to stripe checkout
    if (planId === plan1Details.dbId) {
      setOpenModalForBasicPlan(true);
    } else if (planId === plan2Details.dbId) {
      setOpenModalForPremiumPlan(true);
    } else {
      // IMPORTANT! this should never occur, logging here so we now our code is not
      // working as expected
      Sentry.captureException(
        new Error(
          `IMPORTANT! Unexpected Plan ID received in subscribe to plan modal${planId}`
        ),
        {
          extra: {
            plan: planId,
          }
        }
      );
    }

    // executing the mutation call
    commitStuSubsCreatecheckoutlink({
      variables: {},
      onCompleted: (res: any) => {
        // making sure that the checkout url exist
        if (res?.stu_subs_createcheckoutlink?.checkoutUrls) {
          const checkoutURls = res.stu_subs_createcheckoutlink.checkoutUrls;

          // setting the checkout url so we can access it in modal, and redirect user to
          // stripe checkout page using this url.
          // planId === 1 --> Basic Plan
          // planId === 2 --> Premium Plan
          if (planId === plan1Details.dbId) {
            // if planId is 1, we will redirect the user with basic plan url
            setCheckoutUrl(checkoutURls.basic);

            // if planId is 2, we will redirect the user with premium plan url
          } else if (planId === plan2Details.dbId) {
            setCheckoutUrl(checkoutURls.premium);

            // if neither, should never happen
          } else {
            setErrorCodeCreatecheckouturl(ErrorsStuSubsCreatecheckoutlink.UnexpectedPlanId);

            Sentry.captureException(
              new Error(
                `IMPORTANT! Unexpected Plan ID: ${planId}`
              ),
              {
                extra: {
                  error: res,
                },
              }
            );
          }

          // this should never occur -- the case that we get a successful respond from the backend
          // but the checkout urls do not exist in the data.
        } else {
          // show the user an error and log
          setErrorCodeCreatecheckouturl(ErrorsStuSubsCreatecheckoutlink.CheckoutURLNotFound);
          Sentry.captureException(
            new Error(
              "IMPORTANT! create checkout link returned 200 but didn't found the checkout url in it"
            ),
            {
              extra: {
                error: res,
              },
            }
          );
        }

        // once, the api call gives success/failed we have to enable the button again
        setIsInFlight(false);
      },
      onError(error) {
        // function to handleError in case of mutation failure
        handleErrorForCreateCheckoutLink(error, setErrorCodeCreatecheckouturl);

        // once, the api call gives success/failed we have to enable the button again
        setIsInFlight(false);
      },
    });
  };

  // user is downgrading to plan 1
  const handleDowngrade = () => {
    // opening downgrade plan modal
    setOpenDownGradePlanModal(true);
  };

  // user is upgrading to plan 2
  const handleUpgrade = () => {
    // opening upgrade plan modal
    setOpenUpgradeModal(true);
  };

  // #endregion

  // #region button situation for plans 1 and 2, and text/icons

  enum ButtonSituationsPlan1 {
    // should never occur; log an error if it does
    Unknown = 'Unknown',
    // the user does not have any plan, and can try for free
    FreeTrial = 'FreeTrial',
    // the user does not have any plan, but has used their free trial already
    Subscribe = 'Subscribe',
    // denotes that the user currently has plan 1
    CurrentPlan = 'CurrentPlan',
    // user has plan 2, so they have the option to downgrade to this plan 1
    Downgrade = 'Downgrade',
    // if there is a pending upgrade or downgrade, we will not show a button at all
    PendingChange = 'PendingChange',
  }

  enum ButtonSituationsPlan2 {
    // should never occur; log an error if it does
    Unknown = 'Unknown',
    // the user does not have any plan, and can try for free
    FreeTrial = 'FreeTrial',
    // the user does not have any plan, but has used their free trial already
    Subscribe = 'Subscribe',
    // denotes that the user currently has plan 1
    CurrentPlan = 'CurrentPlan',
    // user has plan 1, we'll want to show them an option to upgrade to this plan 2
    Upgrade = 'Upgrade',
    // if there is a pending upgrade or downgrade, we will not show a button at all
    PendingChange = 'PendingChange',
  }

  // the default, these should get changed by the code below. if they don't, log an error
  let plan1ButtonSituation: ButtonSituationsPlan1 = ButtonSituationsPlan1.Unknown;
  let plan2ButtonSituation: ButtonSituationsPlan2 = ButtonSituationsPlan2.Unknown;

  // the user has a pending upgrade or downgrade. in this situation, we'll only show the
  // "current plan" button on the user's current plan; we won't show upgrade/downgrade
  // options since the user already has a future change scheduled
  if (
    subPendingChange === SubscriptionPendingChange.Upgrade
    || subPendingChange === SubscriptionPendingChange.Downgrade
  ) {
    if (subCurrent.planDbId === plan1Details.dbId) {
      plan1ButtonSituation = ButtonSituationsPlan1.CurrentPlan;
      plan2ButtonSituation = ButtonSituationsPlan2.PendingChange;
    } else if (subCurrent.planDbId === plan2Details.dbId) {
      plan1ButtonSituation = ButtonSituationsPlan1.PendingChange;
      plan2ButtonSituation = ButtonSituationsPlan2.CurrentPlan;
    }

    // the user is not subscribed to any plan, and they can do a free trial
  } else if (subStatus === SubscriptionStatus.NoPlan && subCurrent.canDoTrial) {
    plan1ButtonSituation = ButtonSituationsPlan1.FreeTrial;
    plan2ButtonSituation = ButtonSituationsPlan2.FreeTrial;

    // the user is not subscribed to any plan, and they cannot do a free trial
  } else if (subStatus === SubscriptionStatus.NoPlan && !subCurrent.canDoTrial) {
    plan1ButtonSituation = ButtonSituationsPlan1.Subscribe;
    plan2ButtonSituation = ButtonSituationsPlan2.Subscribe;

    // if user does have current plan, we show a button "currrent plan" under their current
    // plan and either an upgrade or downgrade button
  } else if (subStatus !== SubscriptionStatus.NoPlan) {
    if (subCurrent.planDbId === plan1Details.dbId) {
      plan1ButtonSituation = ButtonSituationsPlan1.CurrentPlan;
      plan2ButtonSituation = ButtonSituationsPlan2.Upgrade;
    } else if (subCurrent.planDbId === plan2Details.dbId) {
      plan1ButtonSituation = ButtonSituationsPlan1.Downgrade;
      plan2ButtonSituation = ButtonSituationsPlan2.CurrentPlan;
    }
  }

  // log error if plan1 or plan2 button situation is Unkown
  if (plan1ButtonSituation === ButtonSituationsPlan1.Unknown
    || plan2ButtonSituation === ButtonSituationsPlan2.Unknown
  ) {
    Sentry.captureException(
      new Error(
        'IMPORTANT! plan1ButtonSituation or plan2ButtonSituation was unknown'
      ),
      {
        extra: {
          plan1: plan1ButtonSituation,
          plan2: plan2ButtonSituation,
          planId: subCurrent.planDbId,
          canTrial: subCurrent.canDoTrial,
        },
      }
    );
  }

  // text and icons for the buttons
  const currentplanIcon = IcSvgList.star1;
  const currentplanText = t`My Current Plan`;
  const tryfreeIcon = IcSvgList.star1;
  const tryfreeText = t`Start Free Trial`;
  const subscribeIcon = IcSvgList.cart2;
  const subscribeText = t`Subscribe`;
  const downgradeIcon = IcSvgList.down1;
  const downgradeText = t`Downgrade`;
  const upgradeIcon = IcSvgList.up1;
  const upgradeText = t`Upgrade`;

  // #endregion

  return (
    <>
      {/* plan 1 card. display flex, so it will have equal height to plan 2 card */}
      <Grid2 xs={12} md={6} key="plan1card" sx={{ display: 'flex' }}>
        <CardStandard
          titleText={plan1Details.title}
          titleIcon={IcSvgList.adult1}
          color="accentOrange1"
          titleIconRight={0}
          // when using flex on parent, 100% width is needed
          cp={{ sx: { width: '100%' } }}
        >
          <Grid2Ct>
            <Grid2 xs={12}>
              {/* price */}
              <Ty v="h1New" align="center" cp={{ sx: { mt: 2 } }}>
                ${plan1Details.price} <Trans>/month</Trans>
              </Ty>

              {/* benefits */}
              <Grid2Ct sx={{ mt: 2 }}>
                <Grid2 xs={12}>
                  <Plan1Benefits />
                </Grid2>
              </Grid2Ct>

              {/* new button */}
              {/* if the user can start a free trial for plan 1 */}
              {plan1ButtonSituation === ButtonSituationsPlan1.FreeTrial ? (
                <ButtonContainer>
                  <CardSmallColor
                    text={tryfreeText}
                    icon={tryfreeIcon}
                    color="accentGreen1"
                    hovercursor="pointer"
                    onClick={() => {
                      handleClickSubscribeToPlan(plan1Details.dbId);
                    }}
                  />
                </ButtonContainer>
              ) : null}

              {/* if the user can subscribe to plan 1 */}
              {plan1ButtonSituation === ButtonSituationsPlan1.Subscribe ? (
                <ButtonContainer>
                  <CardSmallColor
                    text={subscribeText}
                    icon={subscribeIcon}
                    color="accentGreen1"
                    hovercursor="pointer"
                    onClick={() => {
                      handleClickSubscribeToPlan(plan1Details.dbId);
                    }}
                  />
                </ButtonContainer>
              ) : null}

              {/* if plan 1 is the current plan */}
              {plan1ButtonSituation === ButtonSituationsPlan1.CurrentPlan ? (
                <ButtonContainer>
                  <CardSmallColor
                    text={currentplanText}
                    icon={currentplanIcon}
                    color="accentGreen1"
                    hovercursor="pointer"
                    onClick={() => {
                      setOpenCurrentPlanModal(true);
                    }}
                  />
                </ButtonContainer>
              ) : null}

              {/* if the user can downgrade to plan 1 */}
              {plan1ButtonSituation === ButtonSituationsPlan1.Downgrade ? (
                <ButtonContainer>
                  <CardSmallColor
                    text={downgradeText}
                    icon={downgradeIcon}
                    color="accentGreen1"
                    hovercursor="pointer"
                    onClick={() => {
                      handleDowngrade();
                    }}
                  />
                </ButtonContainer>
              ) : null}
            </Grid2>
          </Grid2Ct>
        </CardStandard>
      </Grid2>

      {/* plan 2 card. display flex so it has equal height to card 1 */}
      <Grid2 xs={12} md={6} key="plan2card" sx={{ display: 'flex' }}>
        <CardStandard
          titleText={plan2Details.title}
          titleIcon={IcSvgList.tourist2}
          color="accentOrange1"
          titleIconRight={0}
          // when using flex on parent, 100% width is needed
          cp={{ sx: { width: '100%' } }}
        >
          <Grid2Ct>
            <Grid2 xs={12}>
              {/* price */}
              <Ty v="h1New" align="center" cp={{ sx: { mt: 2 } }}>
                ${plan2Details.price} <Trans>/month</Trans>
              </Ty>

              {/* benefits */}
              <Grid2Ct sx={{ mt: 2 }}>
                <Grid2 xs={12}>
                  <Plan2Benefits />
                </Grid2>
              </Grid2Ct>

              {/* new button */}
              {/* if the user can start a free trial for plan 2 */}
              {plan2ButtonSituation === ButtonSituationsPlan2.FreeTrial ? (
                <ButtonContainer>
                  <CardSmallColor
                    text={tryfreeText}
                    icon={tryfreeIcon}
                    color="accentGreen1"
                    hovercursor="pointer"
                    onClick={() => {
                      handleClickSubscribeToPlan(plan2Details.dbId);
                    }}
                  />
                </ButtonContainer>
              ) : null}

              {/* if the user can subscribe to plan 2 */}
              {plan2ButtonSituation === ButtonSituationsPlan2.Subscribe ? (
                <ButtonContainer>
                  <CardSmallColor
                    text={subscribeText}
                    icon={subscribeIcon}
                    color="accentGreen1"
                    hovercursor="pointer"
                    onClick={() => {
                      handleClickSubscribeToPlan(plan2Details.dbId);
                    }}
                  />
                </ButtonContainer>
              ) : null}

              {/* if plan 2 is the current plan */}
              {plan2ButtonSituation === ButtonSituationsPlan2.CurrentPlan ? (
                <ButtonContainer>
                  <CardSmallColor
                    text={currentplanText}
                    icon={currentplanIcon}
                    color="accentGreen1"
                    hovercursor="pointer"
                    onClick={() => {
                      setOpenCurrentPlanModal(true);
                    }}
                  />
                </ButtonContainer>
              ) : null}

              {/* if the user can upgrade to plan 2 */}
              {plan2ButtonSituation === ButtonSituationsPlan2.Upgrade ? (
                <ButtonContainer>
                  <CardSmallColor
                    text={upgradeText}
                    icon={upgradeIcon}
                    color="accentGreen1"
                    hovercursor="pointer"
                    onClick={() => {
                      handleUpgrade();
                    }}
                  />
                </ButtonContainer>
              ) : null}
            </Grid2>
          </Grid2Ct>
        </CardStandard>
      </Grid2>

      {/* Modal For Basic Plan */}
      {openModalForBasicPlan && (
        <SubscribeToBasicPlanModal
          openModalForBasicPlan={openModalForBasicPlan}
          setOpenModalForBasicPlan={setOpenModalForBasicPlan}
          checkoutUrl={checkoutUrl}
          isInFlight={isInFlight}
          errorCode={errorCodeCreatecheckouturl}
          subCurrent={subCurrent}
        />
      )}

      {/* Modal For Premium Plan */}
      {openModalForPremiumPlan && (
        <SubscribeToPremiumPlanModal
          openModalForPremiumPlan={openModalForPremiumPlan}
          setOpenModalForPremiumPlan={setOpenModalForPremiumPlan}
          checkoutUrl={checkoutUrl}
          isInFlight={isInFlight}
          errorCode={errorCodeCreatecheckouturl}
          subCurrent={subCurrent}
        />
      )}

      {/* modal for user clicking "upgrade" */}
      {openUpgradePlanModal && (
        <UpgradeModal
          openUpgradePlanModal={openUpgradePlanModal}
          setOpenUpgradeModal={setOpenUpgradeModal}
          subPendingChange={subPendingChange}
          subStatus={subStatus}
        />
      )}

      {/* modal for user clicking "downgrade" */}
      {openDownGradePlanModal && (
        <DowngradeModal
          openDownGradePlanModal={openDownGradePlanModal}
          setOpenDownGradePlanModal={setOpenDownGradePlanModal}
          subPendingChange={subPendingChange}
        />
      )}

      {/* modal for user clicking "my current plan" */}
      {openCurrentPlanModal && (
        <CurrentplanModal
          openCurrentPlanModal={openCurrentPlanModal}
          setOpenCurrentPlanModal={setOpenCurrentPlanModal}
        />
      )}
    </>
  );
};

// wraps the "Downgrade", "My Current Plan" etc. buttons
interface ButtonContainerProps {
  children: React.ReactNode;
}
const ButtonContainer: React.FC<ButtonContainerProps> = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      mt: 3,
    }}
  >
    {children}
  </Box>
);
