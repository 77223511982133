import React, { Suspense, useLayoutEffect, useState } from 'react';
import {
  Grid2Ct, Grid2, Card, Skeleton, CardContent,
  Box
} from '@languageconvo/wcl';
import { DateTime } from 'luxon';
import { GetUserCurrentPlanData } from './ManageSubscription1';

export const ManageSubscriptionMainContainer = () => {
  /**
   * currentTs holds the current unix timestamp, used by relay query to get
   * the latest data from history table.
   * We are not lazily importing these components which causing these components
   * to re-render twice.
   * If we directly use DateTime.now() without using react state, it sends network
   * requests twice, that's why, it is always best practice to take advantage of
   * react states to tackle this situation.
   */
  const [currentTs, setCurrentTs] = useState(0);

  useLayoutEffect(() => {
    // the current unix timestamp, used by relay query to get history table data 
    setCurrentTs(Math.floor(DateTime.now().toSeconds()));
  }, []);

  return (
    <Box>
      {currentTs !== 0 && (
      <Suspense fallback={<SuspenseLoading />}>
        <GetUserCurrentPlanData currentTs={currentTs} />
      </Suspense>
      )}
    </Box>
  );
};

// loading glimmer
export const SuspenseLoading = () => (
  <Grid2Ct>
    <Grid2 xs={12} xl={10} xlOffset={1}>
      <Grid2Ct>
        <Grid2 xs={12} md={6}>
          <Card cp={{ sx: { height: '300px' } }}>
            <CardContent>
              <Skeleton width="100%" height={50} variant="text" />
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Card cp={{ sx: { height: '300px' } }}>
            <CardContent>
              <Skeleton width="100%" height={50} variant="text" />
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Card cp={{ sx: { height: '150px' } }}>
            <CardContent>
              <Skeleton width="100%" height={50} variant="text" />
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Card cp={{ sx: { height: '150px' } }}>
            <CardContent>
              <Skeleton width="100%" height={50} variant="text" />
            </CardContent>
          </Card>
        </Grid2>
      </Grid2Ct>
    </Grid2>
  </Grid2Ct>
);
