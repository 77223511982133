/**
 * @generated SignedSource<<8b6b17048856552a272b581e65dd1838>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type relaySubscriptionResolver$data = {
  readonly subs: {
    readonly current_plan: number | null;
    readonly current_plan_status: any | null;
    readonly subs_mainplans: {
      readonly discount_amount: any;
      readonly order: any;
    } | null;
    readonly trialstart_ts: any | null;
  } | null;
  readonly " $fragmentType": "relaySubscriptionResolver";
};
export type relaySubscriptionResolver$key = {
  readonly " $data"?: relaySubscriptionResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"relaySubscriptionResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "relaySubscriptionResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "subs",
      "kind": "LinkedField",
      "name": "subs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trialstart_ts",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "current_plan",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "current_plan_status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "subs_mainplans",
          "kind": "LinkedField",
          "name": "subs_mainplans",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "order",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "discount_amount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "2452fa022b54d8d7de43b4cd02ca032c";

export default node;
