import React, { Suspense } from 'react';
import { PayPageLoading } from './common/PayPageLoading';
import { PurchaseMainLayout2 } from './PurchaseMainLayout2';

export const PurchaseMainLayout1 = () => {
  // eslint-disable-next-line
  const x = 1;

  return (
    <Suspense fallback={<PayPageLoading />}>
      <PurchaseMainLayout2 />
    </Suspense>
  );
};
