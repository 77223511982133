import React, { useLayoutEffect, useState } from 'react';
import { Button, Grid2 } from '@languageconvo/wcl';
import { useMutation } from 'react-relay';
import { StuSubsCreatecheckoutlinkForManageSubsPage } from '../../purchase/purchasemanage/relay/StuSubsCreatecheckoutlinkForManageSubsPage';
import { StuSubsCreateportalURLMutation } from '../../purchase/purchasemanage/relay/StuSubsCreateportalURL';

export const StripeTest = () => {
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const [commitStuSubsCreatecheckoutlink] = useMutation(StuSubsCreatecheckoutlinkForManageSubsPage);

  const [portalUrl, setPortalUrl] = useState('');
  const [commitStuSubsCreateportalurl] = useMutation(StuSubsCreateportalURLMutation);

  useLayoutEffect(() => {
    // for create checkout link
    commitStuSubsCreatecheckoutlink({
      variables: {},
      onCompleted: (res: any) => {
        if (res?.stu_subs_createcheckoutlink?.checkoutUrl) {
          // eslint-disable-next-line
          console.log('checkout url', res);
          setCheckoutUrl(res.stu_subs_createcheckoutlink.checkoutUrl);
        } else {
          // eslint-disable-next-line
          console.log("didn't found the checkout url", res);
        }
      },
      onError(error) {
        // eslint-disable-next-line
        console.log('Error in checkout url', error);
      },
    });

    // for manage portal link
    commitStuSubsCreateportalurl({
      variables: {},
      onCompleted: (res: any) => {
        if (res?.stu_subs_createportalurl?.portalUrl) {
          // eslint-disable-next-line
          console.log('portal url', res);
          setPortalUrl(res.stu_subs_createportalurl.portalUrl);
        } else {
          // eslint-disable-next-line
          console.log("didn't found the portal url", res);
        }
      },
      onError(error) {
        // eslint-disable-next-line
        console.log('Error in portal url', error);
      },
    });

    // eslint-disable-next-line
  }, []);

  return (
    <Grid2 container>
      <Grid2 xs={6}>
        <Button
          color="accentOrange1"
          size="large"
          onClick={() => window.open(checkoutUrl, '_blank')}
          disabled={checkoutUrl === ''}
        >
          Subscribe To Group Lesson
        </Button>
      </Grid2>
      <Grid2 xs={6}>
        <Button
          color="accentPurple1"
          size="large"
          disabled={portalUrl === ''}
          onClick={() => window.open(portalUrl, '_blank')}
        >
          Manage Group Lessons Subscription
        </Button>
      </Grid2>
    </Grid2>
  );
};
