import React, {
  Dispatch, SetStateAction, useEffect, useState
} from 'react';
import * as Sentry from '@sentry/react';
import {
  Button, Dialog, Grid2, Ty, Grid2Ct, Box, Alert, Skeleton
} from '@languageconvo/wcl';
import { Trans } from '@lingui/react/macro';
import { useMutation } from 'react-relay';
import { useRecordUserActionToHistoryTable } from '../../hooks/useRecordUserAction';
import { StuSubsGetprorationamountMutation } from '../../relay/StuSubsGetprorationamount';
import { useOurSubscriptions } from '../../../../../common/utils/subscriptions/useOurSubscriptions';
import { SubscriptionPendingChange, SubscriptionStatus } from '../../ManageSubscriptionTypes';
import { Plan2Benefits } from '../PlanBenefits';

interface Props {
  openUpgradePlanModal: boolean;
  setOpenUpgradeModal: Dispatch<SetStateAction<boolean>>;
  subPendingChange: SubscriptionPendingChange;
  subStatus: SubscriptionStatus,
}

export const UpgradeModal = ({
  openUpgradePlanModal,
  setOpenUpgradeModal,
  subPendingChange,
  subStatus,
}: Props) => {
  // #region general

  const {
    recordUserActionToHistoryTable, isLoading, isError
  } = useRecordUserActionToHistoryTable();
  const { plan2Details } = useOurSubscriptions();

  const isCancellationScheduled = subPendingChange === SubscriptionPendingChange.Cancel;

  // if the user is during their trial period or their initial payment is incomplete, we
  // don't need to show proration amount (as the user will know they will be charged the 
  // full price of the premium plan)
  let showProration = true;
  if (subStatus === SubscriptionStatus.Trialing
    || subStatus === SubscriptionStatus.Incomplete) {
    showProration = false;
  }

  // #endregion

  // #region for executing api call to get pro-ration amount

  // state to hold pro-ration amount which we will show to the user in the UI
  // in case of `proRationAmount` is null we will show a simple message to the user.
  const [proRationAmount, setProtationAmount] = useState<number | null>(null);

  // mutation call to get pro-ration amount from BE.
  const [commitGetProRationAmountMutation] = useMutation(StuSubsGetprorationamountMutation);

  // state to which will tell us that mutation call fails to get the pro-ration amount
  // based on this we will show a differnt message intead of pro-ration amount,
  const [failToGetProRationAmount, setFailToGetProRationAmount] = useState<boolean>(false);

  // as component loads we are executing our mutation call to, get the pro-ration amount 
  useEffect(() => {
    commitGetProRationAmountMutation({
      variables: { newPlanId: plan2Details.dbId },
      onCompleted: (res: any) => {
        setProtationAmount(res.stu_subs_getprorationamount.data.amountDue);
        // FE does not need to do any thing
      },
      // note: if the user has a scheduled upcoming cancelation, this proration api call will
      // fail. so we expect this error to occur in that case, and ignore it
      // the user will see our standard proration notif, can click "upgrade", and we will
      // remove their cancelation and attempt to upgrade...the only problem in that flow
      // is we cannot show them the proration amount
      onError(err: any) {
        if (!isCancellationScheduled) {
          Sentry.captureException(err);
        }
        setFailToGetProRationAmount(true);
      },
    });
  }, [commitGetProRationAmountMutation, plan2Details.dbId, isCancellationScheduled]);

  // #endregion

  return (
    <Dialog
      isOpen={openUpgradePlanModal}
      onClose={() => setOpenUpgradeModal(!openUpgradePlanModal)}
      width="sm"
      color="accentGreen1"
    >
      <Grid2Ct>
        <Grid2 xs={12}>
          {/* if the user has canceled their plan (the cancelation is scheduled for the future),
              we show a different note than if they are doing a normal upgrade */}
          {isCancellationScheduled ? (
            <UpgradeAfterCancelation />
          ) : (<NormalUpgrade />)}

          {/* in the three sections below, we note that the user is going to be charged a proration
          amount --- if they have already paid for their subscription */}
          {(showProration) && (
          <Ty cp={{ sx: { mt: 2 } }}>
            <strong><Trans>Prorated Charge</Trans></strong>
          </Ty>
          )}
          {(failToGetProRationAmount && showProration) && (
          <Ty>
            <Trans>
              We&apos;ll charge you a prorated amount for the remainder of this current month&apos;s
              billing cycle.
            </Trans>
          </Ty>
          )}
          {(!failToGetProRationAmount && showProration) && (
            proRationAmount === null ? (
              <Skeleton width="100%" height={30} variant="text" />
            ) : (
              <Ty>
                <Trans>
                  We&apos;ll charge you a prorated amount of ${proRationAmount} for
                  the remainder of this current month&apos;s billing cycle.
                </Trans>
              </Ty>
            )
          )}

          {/* button */}
          <Box display="flex" justifyContent="center">
            <Button
              color="accentGreen1"
              cp={{ sx: { mt: 3, mb: 2 } }}
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() => recordUserActionToHistoryTable(
                plan2Details.dbId,
                openUpgradePlanModal,
                setOpenUpgradeModal
              )}
            >
              <Trans>
                Yes, Upgrade!
              </Trans>
            </Button>
          </Box>

          {/* if there was an error with the mutation */}
          {isError && (
          <Alert severity="error">
            <Trans>
              Something went wrong, please try that again. Your plan may not have been changed!
            </Trans>
          </Alert>
          )}
        </Grid2>
      </Grid2Ct>
    </Dialog>
  );
};

// the user does NOT have a cancelation scheduled, we'll show this
const NormalUpgrade = () => (
  <>
    <Ty v="h2New" align="center"><Trans>Upgrade your plan!</Trans></Ty>

    <Ty cp={{ sx: { mt: 3 } }}>
      <Trans>
        By upgrading, you&apos;ll gain access to these benefits:
      </Trans>
    </Ty>

    {/* benefits the user is going to lose */}
    <Grid2Ct sx={{ mt: 1 }}>
      <Grid2 xs={12}>
        <Plan2Benefits />
      </Grid2>
    </Grid2Ct>
  </>
);

// the user has a cancelation scheduled. we'll show them this
const UpgradeAfterCancelation = () => (
  <>
    <Ty v="h2New" align="center"><Trans>Upgrade your plan!</Trans></Ty>

    <Ty cp={{ sx: { mt: 3 } }}>
      <Trans>
        It looks like you have your subscription scheduled for cancelation. If you upgrade,
        first we will remove your cancelation and then we&apos;ll attempt to upgrade your
        plan. So <strong>importantly,</strong> note that if the upgrade fails (usually that
        happens if the charge to your payment method fails), your plan will no longer be
        scheduled to cancel.
      </Trans>
    </Ty>
  </>
);
