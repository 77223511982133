import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFragment, useMutation } from 'react-relay';
import * as Sentry from '@sentry/react';
import {
  Grid2, Grid2Ct, CardStandard, IcSvgList, Ty, Dialog,
} from '@languageconvo/wcl';
import { useSearchParams } from 'react-router-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
// import { GeneratePayPalToken } from '../relay/mutations/GeneratePayPalToken';
import { GetPackDetails } from '../relay/fragments/GetPackDetails';
// Seprate React Component
import { CartDetails } from './CartDetails';
import { PaymentSuccess } from './PaymentSuccess';
// import { GenerateClientToken } from '../../../../../utils/GenerateClientToken';
import { NonfixableError } from './helper/NonfixableError';
import { PayPalPayButton } from './PayPal/PayPalPayButton';
import { PayPalFields } from './PayPal/PayPalFieldsWrapper';
import { useCapturePayment } from '../hooks/useCapturePayment';
// import { PayPageLoading } from '../../../common/PayPageLoading';
import { PaypageErrors } from './ErrBoundaryPaypage';
import { CreateOrderForPurchase } from '../relay/mutations/CreateOrderForPurchase';
import { ErrorsCreateOrderMutation } from './helper/Errors';
import { useEventLogger, EventdtPurchase } from '../../../../../common/utils/eventmanager/eventmanager';
import { CurrentSubscriptionTy } from '../../../../../common/utils/subscriptions/OurSubscriptionsTypes';
import { PurchasePaySubsdtFragment } from '../relay/queries/PurchasePay';
import { PurchasePaySubsdtFragment$key } from '../relay/queries/__generated__/PurchasePaySubsdtFragment.graphql';

interface Props {
  fragmentReference: any;
  fragrefUserSubscription: PurchasePaySubsdtFragment$key;
}

export const PurchasePayPage1 = ({
  fragmentReference,
  fragrefUserSubscription,
}: Props) => {
  // #region general

  // mutation call for getting the client token from backend
  // this client token is necessary on frontend, because without this
  // we cannot render the paypal hosted fields or buttons
  // const [ClientTokenMutation] = useMutation(GeneratePayPalToken);
  // const [isGenerateTokenMutationInFlight, setIsGenerateTokenMutationInFlight] = useState(true);
  // // in this state we will set the client token value that we get from the backend
  // // because we have to pass that value inside PaypalScriptProvider
  // const [clientToken, setClientToken] = useState();
  // // in this state we will set true, if generateClientToken mutation through any error
  // // this state is required because by using this state variable we will throw an error
  // // from useEffect and show user a page level error
  // const [clientTokenError, setClientTokenError] = useState(false);

  // getting the invoice from the url because we need it to make the query to get
  // order's data
  const [searchParams] = useSearchParams();
  const paypalPackageId = searchParams.get('pid');

  // we will read the traslantions using useTranslation file, 
  // this will only get us the translations from the files which we have passed in it below
  const { t } = useTranslation(['languageLocations']);

  // reading the data using the fragment Reference, because we have to show this data 
  // on the right side card, to tell user what they are paying for, and how much will be the
  // cost for that particular package
  const packDetails = useFragment(GetPackDetails, fragmentReference);
  const packsData = packDetails.packs_connection.edges;
  // if we don't find the pack in our db, we throw an error so that our pagelevel custom error can
  // show a friendly message. if we don't do this here, code below that tries to access packsData
  // will cause an uncaught exception. note that it's very easy for the user to edit the order
  // id in the url and cause this error
  if (packsData.length <= 0) {
    throw new Error(PaypageErrors.PaypagePackNotFound);
  }
  // duration of the package, in hours
  const durationHoursNum = packsData[0].node.duration / 3600;

  const purchasePkgLang = `${t(packsData[0]?.node.location_id)}`;
  // the uuid field of the orders table. we'll get this after inactive order is inserted. its used
  // for events managment event id
  const [orderUuid, setOrderUuid] = useState<string>('');

  // we have created a custom hook for captureMutation because we are using it in multiple places
  // instead of passing the props, it would be good to have to code for this in one single file 
  // and we will extract values from it and use them wherever we need them
  const {
    paymentSuccess, nonFixableErrors, errCode, commitCaptureMutation,
    setOrderInvoiceNumber, setFixableErrors, setErrCode, setIsLoading,
    setNonFixableErrors
  } = useCapturePayment();

  // event js
  const { evtPurchase } = useEventLogger();

  // #endregion

  // #region calculate price, discount amount, etc.

  // get our relaySubscription data; this is data we calculate at the app level about the
  // user's subscription (whether they're subscribed, what plan they have, etc.)
  const userSubscriptionPlanData = useFragment(
    PurchasePaySubsdtFragment,
    fragrefUserSubscription
  );
  const userSubscriptionInfo: CurrentSubscriptionTy = userSubscriptionPlanData.relaySubscription;

  // if the get a discount (boolean) and the amount of discount they get (number)
  const canUserGetDiscount = userSubscriptionInfo.privateDiscountGets;
  const discountPerHourNum = userSubscriptionInfo.privateDiscountAmt;

  // the regular package price, no discount applied
  const totalPriceNoDiscountNum = packsData[0].node.price;

  // the total amount discounted that the user gets and
  // the final, complete price the user will pay. if the user gets a discount, we use the
  // discount amount. if they don't get a discount, obviously we use the unaltered amount
  let discountTotalNum = 0;
  let finalPriceNum = totalPriceNoDiscountNum;
  if (canUserGetDiscount) {
    // the user's total discount is just the number of hours of the package, times the discount
    // per hour that they get
    discountTotalNum = durationHoursNum * discountPerHourNum;
    finalPriceNum = totalPriceNoDiscountNum - discountTotalNum;
  }

  // #endregion

  // #region create order api call

  /** when user clicks on the continue button, we execute this mutation
   * this mutation call will create an order in the backend and the continue button will remain
   * in the disabled as well as loading state until the response from this call returns back
   */
  const [CreateOrderForPurchaseMutation] = useMutation(CreateOrderForPurchase);

  const createOrderMutation = () => new Promise<string | null>((resolve) => {
    let result = '';
    CreateOrderForPurchaseMutation({
      variables: {
        languageId: packsData[0]?.node.location_id,
        packageId: paypalPackageId,
        price: finalPriceNum,
      },
      onCompleted(res: any) {
        if (res?.orders_createpurchaseorder?.data?.paypalPaymentId) {
          setOrderUuid(res.orders_createpurchaseorder.data.uuid);
          result = res.orders_createpurchaseorder.data.paypalPaymentId;
          setOrderInvoiceNumber(res.orders_createpurchaseorder.data.invoiceNumber);
          resolve(result);

          // the backend returned a successful response, but paypalpaymentid was not returned! that
          // is a value that the next step, capture payment, requires. so we cannot allow the code
          // to continue. by returning (resolve(null)) the capture payment step will fail
        } else {
          // logging this error in sentry, because this should never occur 
          Sentry.captureException(
            new Error('Important! Create order api call returned success but did not return paypal payment id in the response'),
            {
              extra: {
                response: res
              }
            }
          );
          setFixableErrors(true);
          setErrCode(ErrorsCreateOrderMutation.FixableNoPaymentId);
          resolve(null);
        }
      },

      // if an error occurs with create order, we resolve(null) so that capture payment will not
      // have a paypal payment id, and thus it will fail. in some cases the error is fixable, in
      // other cases it's not
      onError(err: any) {
        // try/catch because json.parse will throw if the error we got is not a "known" error from
        // our backend
        try {
          const errorObject = JSON.parse(err.message);
          const errorCode = errorObject?.extensions?.code;

          // known errors that are fixable
          if (
            errorCode === ErrorsCreateOrderMutation.FixableExternalAPIFailure
            || errorCode === ErrorsCreateOrderMutation.FixableFailedToCreateInactiveOrder
            || errorCode === ErrorsCreateOrderMutation.FixableFailedToUpdatePaymentId
            || errorCode === ErrorsCreateOrderMutation.FixablePriceNotVerified
          ) {
            setFixableErrors(true);
            setErrCode(errorCode);
            setIsLoading(false);

            // known errors that are not fixable
          } else if (
            errorCode === ErrorsCreateOrderMutation.NonFixablePackageNotFound
          ) {
            setNonFixableErrors(true);
            setErrCode(errorCode);

            // some other unknown error. we'll assume it's fixable
          } else {
            Sentry.captureException(
              new Error('BIG PROBLEM: create order mutation gave some unexpected error response, the code should never come here'),
              {
                extra: {
                  err
                }
              }
            );
            setFixableErrors(true);
            setErrCode(ErrorsCreateOrderMutation.FixableUnknown);
            setIsLoading(false);
          }

          // some error occurred with the code above in the try. likely this is due to the json
          // parse failing but it could be for other reasons. we'll let the user try the buy button 
          // again and log the error
        } catch (e) {
          setFixableErrors(true);
          setErrCode(ErrorsCreateOrderMutation.FixableUnknown);
          setIsLoading(false);
          Sentry.captureException(e);
        }

        // ensures capture payment cannot occur successfully
        resolve(null);
      },
    });
  });

  // #endregion

  // #region security info modal

  // modal state. true=open, false=closed. closed by default
  const [modalState, setModalState] = useState<boolean>(false);
  const handleModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalState(true);
  };
  const handleModalClose = () => {
    setModalState(false);
  };

  // #endregion

  // #region generate client token for paypal fields

  // const mutationExecuted = useRef(false);

  // useEffect(() => {
  //   if (!mutationExecuted.current) {
  //     mutationExecuted.current = true;
  //     // we have created this function to make the code simpler, we're only calling this function
  //     // from here and managing all it's state and errors inside that function.
  //     if (packsData.length > 0) {
  //       GenerateClientToken({
  //         setClientToken,
  //         setClientTokenError,
  //         ClientTokenMutation,
  //         setIsGenerateTokenMutationInFlight
  //       });
  //     }
  //   }

  //   // this useffect doesn't have any dependency because we only want to run this useEffect
  //   // on page load
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // this useEffect will run if the GenerateClientToken mutation returns an error
  // this is because we cannot directly throw error from GenerateClientToken mutation. So, 
  // we have to through it like we are doing here.
  // useEffect(() => {
  //   if (clientTokenError) {
  //     throw new Error('error in generating client token');
  //   }
  // }, [clientTokenError]);

  // #endregion

  // #region full page displays: nonfixable errors and payment successful

  // error that is not fixable. this is a component that takes up the entire page
  if (nonFixableErrors) {
    return (
      <NonfixableError error={errCode} />
    );
  }

  // successful payment!
  if (paymentSuccess) {
    const dt: EventdtPurchase = {
      value: totalPriceNoDiscountNum,
      // this gets set above, before payment success can happen so we can assume it has a nonnull
      // value
      eventuuid: orderUuid!,
    };
    evtPurchase(dt);

    return (
      <PaymentSuccess />
    );
  }

  // #endregion

  return (
    <>
      {/* adding PayPalScriptProvider which is needed to show custom paypal fields */}
      <Grid2Ct>
        {/* make payment */}
        <Grid2 xs={12} md={6} lg={7} xl={8} order={{ xs: 2, md: 1 }}>
          <PayPalScriptProvider
            options={{
              components: 'card-fields,buttons',
              clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID!,
              intent: 'capture',
              vault: false,
            }}
          >
            <CardStandard
              titleText="Pay With Card"
              titleIcon={IcSvgList.creditcard1}
              color="accentGreen1"
              titleIconRight={0}
            >
              {/* pay with card */}
              <PayPalFields
                createOrderMutation={createOrderMutation}
              />
            </CardStandard>

            {/* other payment options (paypal for now) */}
            <Grid2Ct sx={{ mt: 1 }}>
              <Grid2 xs={12}>
                <CardStandard
                  titleText="Other Payment Options"
                  titleIcon={IcSvgList.dollar1}
                  color="accentGreen1"
                  titleIconRight={0}
                >
                  <Grid2Ct sx={{ mt: 2 }}>
                    <Grid2 xs={12} sm={6} md={4}>
                      <PayPalPayButton
                        packsData={packsData}
                        commitCaptureMutation={commitCaptureMutation}
                        createOrderMutation={createOrderMutation}
                      />
                    </Grid2>
                  </Grid2Ct>
                </CardStandard>
              </Grid2>
            </Grid2Ct>
          </PayPalScriptProvider>
        </Grid2>

        {/* shopping cart, security info on md and up */}
        <Grid2 xs={12} md={6} lg={5} xl={4} order={{ xs: 1, md: 2 }}>
          <CartDetails
            timeInHour={durationHoursNum}
            totalPrice={finalPriceNum}
            canUserGetDiscount={canUserGetDiscount}
            discountTotalNum={discountTotalNum}
            purchasePkgLang={purchasePkgLang}
          />

          {/* security explanation, on md and up */}
          <Grid2Ct sx={{ mt: 1, display: { xs: 'none', md: 'flex' } }}>
            <Grid2 xs={12}>
              <SecurityExplanation handleModalOpen={handleModalOpen} />
            </Grid2>
          </Grid2Ct>
        </Grid2>

        {/* security explanation, on xs and sm */}
        <Grid2 xs={12} sx={{ display: { xs: 'block', md: 'none' } }} order={{ xs: 3 }}>
          <SecurityExplanation handleModalOpen={handleModalOpen} />
        </Grid2>
      </Grid2Ct>

      {/* security info modal */}
      <Dialog
        isOpen={modalState}
        onClose={handleModalClose}
        width="sm"
        color="accentBlue1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty>
              Whether you purchase by card or via PayPal, we do not have access to your payment
              information. All payments, including card payments, are processed by PayPal (as
              happens on many websites when you purchase using a card).
              <br /><br />
              We have been a certified PayPal Merchant for more than 10 years; please feel
              free to contact PayPal directly to inquire about our company!
              <br /><br />
              <strong>Note</strong>: you do NOT need a PayPal account, and one is NOT
              created for you when
              you pay by card. They simply process your payment, as happens &quot;behind
              the scenes&quot; when you use your card on many websites.
              <br /><br />
              We take security very seriously, which is why we do not ever handle your
              card information ourselves (even though it would be cheaper for us if
              we did)!
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};

// security explanation small card
const SecurityExplanation = ({ handleModalOpen }: any) => (
  <CardStandard
    titleText="Security"
    titleIcon={IcSvgList.lock1}
    color="accentBlue1"
    titleIconRight={2}
    onClickRightIcon={handleModalOpen}
  >
    <Ty removeMb cp={{ sx: { mt: 1 } }}>
      We do not get or store your card information in any way. Click the ? above to learn more.
    </Ty>
  </CardStandard>
);
