import * as Sentry from '@sentry/react';
import { EventdtAccountCreated, EventdtPurchase } from './eventmanager';

/* VERY IMPORTANT: note on eventID
    Each of the events below is sent to Fb here from our frontend but *also* from a backend
    "reverse ETL" process. So fb needs to be able to deduplicate these events, meaning they
    need to know that the event sent from here is the exact same event sent from the backend.
    They use the eventID to do that. Thus it's very important that we pass a unique eventID
    for each event
*/

/* Note on user id
    FB does not allow sending user id in these "track" calls. Instead, it must be set when
    the FB pixel first loads.
*/

/* FB documentationt
    https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching
*/

// IMPORTANT: DO NOT CHANGE THESE
// IMPORTANT: DO NOT CHANGE THESE (unless you REALLY know what you're doing)
// the names/titles of each event. note that some destinations require special naming.
// these are very important in each tool, do NOT change them unless you know you really
// need to
enum EventTitles {
  // user first created their account. note that in fb this is a specially reserved event
  // name which we must use
  createaccount = 'CompleteRegistration',
  // private lesson purchase
  purchase = 'Purchase',
}

// new user account created
export const accountcreatedFb = (dt: EventdtAccountCreated) => {
  try {
    // @ts-ignore
    window.fbq(
      'track',
      EventTitles.createaccount,
      // this event has not custom metadata
      {},
      // the uuid field from the users table. see note at top of file explaining importance
      {
        eventID: dt.eventuuid,
      }
    );

    // eslint-disable-next-line no-console
    console.log('fb: account created');
  } catch (e) {
    Sentry.captureException(e);
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

// purchase private lessons
export const purchaseFb = (dt: EventdtPurchase) => {
  try {
    // @ts-ignore
    window.fbq(
      'track',
      EventTitles.purchase,
      {
        currency: 'USD',
        value: dt.value
      },
      // the uuid field from the orders table. see note at top of file explaining importance
      {
        eventID: dt.eventuuid,
      }
    );

    // eslint-disable-next-line no-console
    console.log('fb: purchase');
  } catch (e) {
    Sentry.captureException(e);
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
