import React, { Dispatch, SetStateAction } from 'react';
import {
  Dialog, Grid2, Grid2Ct, Ty
} from '@languageconvo/wcl';
import { Trans } from '@lingui/react/macro';

interface Props {
  openCurrentPlanModal: boolean;
  setOpenCurrentPlanModal: Dispatch<SetStateAction<boolean>>;
}

// modal explaining this is the user's current plan
export const CurrentplanModal = ({
  openCurrentPlanModal,
  setOpenCurrentPlanModal,
}: Props) => (
  <Dialog
    isOpen={openCurrentPlanModal}
    onClose={() => setOpenCurrentPlanModal(!openCurrentPlanModal)}
    width="xs"
    color="accentGreen1"
  >
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty align="center"><Trans>You&apos;re subscribed to this plan!</Trans></Ty>
      </Grid2>
    </Grid2Ct>
  </Dialog>
);
