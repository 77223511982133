/**
 * @generated SignedSource<<4a2b61fd7b3dd6fbecf28a044ecae2e1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PurchaseMainLayoutFragment$data = {
  readonly relaySubscription: ReturnType<typeof usersRelaySubscriptionResolverType> | null;
  readonly " $fragmentSpreads": FragmentRefs<"UserSubsDetailsFragment">;
  readonly " $fragmentType": "PurchaseMainLayoutFragment";
};
export type PurchaseMainLayoutFragment$key = {
  readonly " $data"?: PurchaseMainLayoutFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PurchaseMainLayoutFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PurchaseMainLayoutFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "relaySubscriptionResolver"
      },
      "kind": "RelayResolver",
      "name": "relaySubscription",
      "resolverModule": require('./../../../../common/relay/resolvers/users/relaySubscription').relaySubscription,
      "path": "relaySubscription"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserSubsDetailsFragment"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "c8e3000d2cb060aa7fb3675a37a8d1d9";

export default node;
