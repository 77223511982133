import React from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { PurchaseMainLayout3 } from './PurchaseMainLayout3';
import { PurchaseMainLayoutQuery } from './relay/PurchaseMainLayout';

export const PurchaseMainLayout2 = () => {
  const response: any = useLazyLoadQuery(
    PurchaseMainLayoutQuery,
    { },
  );

  // this gives us relaySubscription, the details of the user's current subscription
  const fragrefUserSubscription = response.users_connection.edges[0].node;

  return (
    <PurchaseMainLayout3 fragrefUserSubscription={fragrefUserSubscription} />
  );
};
