/**
 * @generated SignedSource<<7ed6a7e1f58f467703cdc459fa0b890b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageloadQuery$variables = {};
export type PageloadQuery$data = {
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"PageloadLanglearnFragment" | "PageloadRequiredsettingsFragment" | "PageloadSubscriptFragment" | "PageloadTimesettingsFragment">;
      };
    }>;
  };
};
export type PageloadQuery = {
  response: PageloadQuery$data;
  variables: PageloadQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialstart_ts",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current_plan",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current_plan_status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "subs_mainplans",
  "kind": "LinkedField",
  "name": "subs_mainplans",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "order",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discount_amount",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PageloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PageloadLanglearnFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PageloadTimesettingsFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PageloadRequiredsettingsFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PageloadSubscriptFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PageloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lang_learning",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hour_cycle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timezone_set",
                    "storageKey": null
                  },
                  {
                    "name": "relaySubscription",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "subs",
                          "kind": "LinkedField",
                          "name": "subs",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v4/*: any*/),
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "users",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": false
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subs",
                    "kind": "LinkedField",
                    "name": "subs",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v0/*: any*/),
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "901444a5ba05273ea0c0ae18d93bfd18",
    "id": null,
    "metadata": {},
    "name": "PageloadQuery",
    "operationKind": "query",
    "text": "query PageloadQuery {\n  users_connection {\n    edges {\n      node {\n        ...PageloadLanglearnFragment\n        ...PageloadTimesettingsFragment\n        ...PageloadRequiredsettingsFragment\n        ...PageloadSubscriptFragment\n        id\n      }\n    }\n  }\n}\n\nfragment PageloadLanglearnFragment on users {\n  lang_learning\n}\n\nfragment PageloadRequiredsettingsFragment on users {\n  timezone_set\n}\n\nfragment PageloadSubscriptFragment on users {\n  ...relaySubscriptionResolver\n  ...UserSubsDetailsFragment\n}\n\nfragment PageloadTimesettingsFragment on users {\n  hour_cycle\n}\n\nfragment UserSubsDetailsFragment on users {\n  subs {\n    current_plan\n    current_plan_status\n    trialstart_ts\n    subs_mainplans {\n      order\n      discount_amount\n      id\n    }\n    id\n  }\n}\n\nfragment relaySubscriptionResolver on users {\n  subs {\n    trialstart_ts\n    current_plan\n    current_plan_status\n    subs_mainplans {\n      order\n      discount_amount\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a755bb4a1bc49dc96cd8e4cd24b3ff44";

export default node;
