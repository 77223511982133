import { graphql } from 'babel-plugin-relay/macro';
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import {
  CurrentSubscriptionTy, SubscriptionPlanTy, SubscriptionStatusTy,
} from '../../../utils/subscriptions/OurSubscriptionsTypes';

/**
 * This is a derived object, representing the data about the user's subscription.
 * We should use it anywhere in the app we want to know what subscription the user
 * has. See CurrentSubscriptionTy for data type details
 * 
 * @RelayResolver
 * @onType users
 * @fieldName relaySubscription
 * @rootFragment relaySubscriptionResolver
 */
export function relaySubscription(subsKey: any): CurrentSubscriptionTy {
  const data = readFragment(
    graphql`
      fragment relaySubscriptionResolver on users {
        subs {
          trialstart_ts
          current_plan
          current_plan_status
          subs_mainplans {
            order
            discount_amount
          }
        }
      }
    `,
    subsKey
  );

  // by default, the user's plan and status are unknown. if we have data from the subs table
  // then we'll be able to update these defaults
  const ret: CurrentSubscriptionTy = {
    plan: SubscriptionPlanTy.Unknown,
    status: SubscriptionStatusTy.Unknown,
    canDoTrial: false,
    privateDiscountGets: false,
    privateDiscountAmt: 0,
  };

  // if we haven't yet gotten data from the subs table, we cant determine anything about whether
  // the user has a plan or not. 
  if (data?.subs) {
    // if trialstart_ts is null that indicates the user has not ever done a free trial of classes
    // in the past, so we allow them to. note that even though we know we have data from subs we
    // don't necessarily have trialstart_ts, so ? is needed 
    if (data.subs?.trialstart_ts === null) {
      ret.canDoTrial = true;
    }

    // the user has no plan at all
    if (data.subs?.current_plan === null) {
      ret.plan = SubscriptionPlanTy.NoPlan;
      ret.status = SubscriptionStatusTy.NoPlan;

      // TODO: John, need to fix the mess with "useOurSubscriptions" (doesnt need to be a
      //  hook), and then use the values from there in here
      // basic plan
    } else if (data.subs?.subs_mainplans?.order) {
      if (data.subs.subs_mainplans.order === 1) {
        ret.plan = SubscriptionPlanTy.Basic;
      } else if (data.subs.subs_mainplans.order === 2) {
        ret.plan = SubscriptionPlanTy.Premium;
      } else {
        // TODO: John, does sentry error logging work in here? If so, add it
      }

      // we determine the subscription status. again, we can't be certain if we have
      // current_plan_status from the db or not, thus ? is needed
      if (data.subs?.current_plan_status === 1) {
        ret.status = SubscriptionStatusTy.Trialing;
      } else if (data.subs?.current_plan_status === 2) {
        ret.status = SubscriptionStatusTy.Active;
      } else if (data.subs?.current_plan_status === 3) {
        ret.status = SubscriptionStatusTy.PastDue;
      } else if (data.subs?.current_plan_status === 4) {
        ret.status = SubscriptionStatusTy.Incomplete;
      }

      // now we determine if they get a discount on private lessons, how much that discount is.
      // currently, even if the user is trialing, they get the discount
      if (data.subs?.current_plan_status === 1 || data.subs?.current_plan_status === 2) {
        if (data?.subs?.subs_mainplans?.discount_amount) {
          ret.privateDiscountGets = true;
          ret.privateDiscountAmt = data.subs.subs_mainplans.discount_amount;
        } else {
          // TODO: log an erorr here, the user should have a discount amount if they're able to
          // get a discount
        }
      }
    }
  }

  return ret;
}
