import React, {
  Dispatch, SetStateAction, useCallback, useEffect, useState
} from 'react';
import * as Sentry from '@sentry/react';
import {
  Button, Box,
  Dialog, Grid2, Grid2Ct, Ty
} from '@languageconvo/wcl';
import { useMutation } from 'react-relay';
import { StuSubsCreateportalURLMutation } from '../../relay/StuSubsCreateportalURL';

interface Props {
    openGoToStripePortalModal: boolean,
    setOpenGoToStripePortalModal: Dispatch<SetStateAction<boolean>>,
}
export const StripePortalModal = ({
  openGoToStripePortalModal,
  setOpenGoToStripePortalModal
}: Props) => {
  // #region for getting stripe portal url

  // state variable to hold stripe portalUrl we will be using these url in the 
  // `Continue` button which will take the user to the stripe portal. 
  const [stripPortalURL, setStripePortalURL] = useState<string>('');

  // mutation call to get stripe port url.
  const [commitCreatePortalURLMutation] = useMutation(StuSubsCreateportalURLMutation);

  // `fetchStripePortalURL` responsible for getting stripe portal url,
  // this mutation will executes as the modal opens and after every 4 mintues.
  // reason: Stripe portal url got expired with in 5 minutes so we need to re-fetch the 
  // portal url.

  // we use useCallback to memoize the fetchStripePortalURL function, 
  // ensuring it doesn't get recreated on every render. Without this, the 
  // function reference would change on each render, causing the dependency 
  // array in useEffect to detect a change and trigger unnecessary re-executions 
  // (e.g., repeated mutation calls or interval setups).
  // by using useCallback, the function is only recreated when its dependencies 
  // (here, commitCreatePortalURLMutation) change, which improves performance 
  // and avoids unwanted side effects like multiple API calls.
  const fetchStripePortalURL = useCallback(() => {
    commitCreatePortalURLMutation({
      variables: {},
      onCompleted: (res: any) => {
        if (res?.stu_subs_createportalurl?.portalUrl) {
          setStripePortalURL(res.stu_subs_createportalurl.portalUrl);
        } else {
          Sentry.captureException(
            new Error("IMPORTANT! Mutation returned 200 but didn't contain a Stripe portal URL."),
            { extra: { error: res } }
          );
        }
      },
      onError: (err: any) => {
        Sentry.captureException(err);
      },
    });
  }, [commitCreatePortalURLMutation]);

  useEffect(() => {
    // executing the mutaion call
    fetchStripePortalURL();

    // Set up interval to fetch URL every 4 minutes
    const intervalId = setInterval(fetchStripePortalURL, 240000);

    // cleanup interval on unmount/modal close
    return () => clearInterval(intervalId);
  }, [fetchStripePortalURL, openGoToStripePortalModal]);
  // #endregion

  return (
    <Dialog
      isOpen={openGoToStripePortalModal}
      onClose={() => setOpenGoToStripePortalModal(!openGoToStripePortalModal)}
      width="xs"
      color="accentBlue1"
    >
      <Grid2Ct>
        <Grid2 xs={12}>
          <Ty>
            Note: this will take you to Stripe, the payment processing service we use to
            manage your subscription:
          </Ty>
          <Box sx={{
            display: 'flex', justifyContent: 'center', alignItem: 'center', mt: 3
          }}
          >
            <Button
              color="accentGreen1"
              disabled={stripPortalURL === ''}
              onClick={() => { window.location.href = stripPortalURL; }}
            >
              Continue
            </Button>
          </Box>
        </Grid2>
      </Grid2Ct>
    </Dialog>
  );
};
