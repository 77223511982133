// the user's current plan details
export interface SubscriptionCurrentPlan {
  // the database id (order field) of the user's current plan. null if they don't have
  // a plan
  planDbId: 1 | 2 | null;
  // true if the user can do a free trial, false otherwise
  canDoTrial: boolean;
}

// the overall status of the user's plan
export enum SubscriptionStatus {
  Unknown = 'Unknown', // should never happen, a default setting
  NoPlan = 'NoPlan', // user does not have a subscription
  Trialing = 'Trialing', // current_plan_status = 1
  Active = 'Active', // current_plan_status = 2
  PastDue = 'PastDue', // current_plan_status = 3
  Incomplete = 'Incomplete' // current_plan_status = 4
}

// if the user has a pending change to their plan, what type of change do they have scheduled
export enum SubscriptionPendingChange {
  None = 'None',
  Downgrade = 'Downgrade', // user has a downgrade scheduled
  Upgrade = 'Upgrade', // user has an upgrade scheduled
  Cancel = 'Cancel', // user has a cancelation scheduled
}
