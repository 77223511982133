import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  CardStandard, Ty, Grid2Ct, Grid2, Link, IcSvgList,
} from '@languageconvo/wcl';
import { sitedata } from '../../../../../utils/sitedata';
import { VideoPlaceholderImage } from './HowClassesWorkNotsubbed.style';

interface Props {
  isSubscribed: boolean;
  handleModalOpenGroup: any;
}

// how it works and free trial cards that we show to users who are not subscribed
export const HowClassesWorkNotsubbed = ({ isSubscribed, handleModalOpenGroup }: Props) => {
  // if the user is not subscribed, we'll show a how it works and free trial
  // section
  if (!isSubscribed) {
    return (
      <Grid2Ct>
        <HowItWorks handleModalOpenGroup={handleModalOpenGroup} />
        <NotsubbedFreeTrial />
      </Grid2Ct>
    );
  }

  // if they are subscribed, we will *not* show the how it works section
  return (
    <div />
  );
};

const HowItWorks = ({ handleModalOpenGroup }: any) => {
  // image for the video thumbnail
  const imgUrl = `${process.env.REACT_APP_IMAGESCDNURL}/uis/components/HowConversationalClassesWork1.png`;

  return (
    <Grid2 xs={12}>
      <CardStandard
        titleText="How Bite-Sized Conversational Classes Work"
        titleIcon={IcSvgList.group1}
        color="accentGreen1"
        cp={{ sx: { width: '100%' } }}
        titleIconRight={0}
        onClickEntireCard={handleModalOpenGroup}
        hovercursor="pointer"
      >
        <Grid2Ct sx={{ mt: 2 }}>
          {/* image. justifyContent centers the image */}
          <Grid2
            xs={12}
            sx={{
              pt: 0, pb: 0, display: 'flex', justifyContent: 'center'
            }}
          >
            <VideoPlaceholderImage src={imgUrl} alt="How Conversationl Classes Work" />
          </Grid2>
        </Grid2Ct>
      </CardStandard>
    </Grid2>
  );
};

const NotsubbedFreeTrial = () => {
  // link to purchase page
  const clickableTypeObjPurchpg = {
    to: sitedata.url.app.manageSubscriptionDt.pathFull,
    component: NavLink,
  };

  return (
    <Grid2 xs={12}>
      <CardStandard
        titleText="Free Trial"
        titleIcon={IcSvgList.star1}
        color="accentGreen1"
        cp={{ sx: { width: '100%' } }}
        clickableLink1={{ ...clickableTypeObjPurchpg }}
      >
        <Link
          to={sitedata.url.app.manageSubscriptionDt.pathFull}
          component={NavLink}
          linkStyle="nostyle"
        >
          <Grid2Ct>
            <Grid2 xs={12} display="flex">
              <Ty removeMb>
                Try unlimited bite-sized conversational classes, free!
              </Ty>
            </Grid2>
          </Grid2Ct>
        </Link>
      </CardStandard>
    </Grid2>
  );
};
