// in this funciton attempt to get the error code from our backend. this will
import { Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/react';
import { ErrorsStuSubsCreatecheckoutlink } from '../Errors/Errors';

// only work if the error is a known, expected error
export const handleErrorForCreateCheckoutLink = (
  error: any,
  setErrorCode: Dispatch<SetStateAction<
    ErrorsStuSubsCreatecheckoutlink | null>>,
) => {
  try {
    const errorObject = JSON.parse(error.message);
    const errCode = errorObject?.extensions?.code;

    if (errCode === ErrorsStuSubsCreatecheckoutlink.TryAgain) {
      setErrorCode(errCode);
    } else if (errCode === ErrorsStuSubsCreatecheckoutlink.NotAllowed) {
      setErrorCode(errCode);
    } else {
      // IMPORTANT! this should never occur, logging here so we now our code is not
      // working as expected
      Sentry.captureException(
        new Error('IMPORTANT! Some thing Unexpected happend, that we are not expecting'),
        {
          extra: {
            ec: errCode,
          }
        }
      );

      // IMPORTANT! this should never occur
      setErrorCode(
        ErrorsStuSubsCreatecheckoutlink.UnexpectedOrTimeout
      );
    }

    // if the error is an unexpected error then our catch block will execute
  } catch (e) {
    Sentry.captureException(e);
    setErrorCode(ErrorsStuSubsCreatecheckoutlink.UnexpectedOrTimeout);
  }
};
