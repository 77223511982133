import React, { useState, useEffect } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import {
  DataGrid, Button, Box, IcSvg, IcSvgList,
} from '@languageconvo/wcl';
import { DateTime } from 'luxon';
import { usePaginationFragment } from 'react-relay';
import { useTranslation } from 'react-i18next';
import { Orders$key } from './__generated__/Orders.graphql';

/** This fragment gets private lesson purchases that are:
 * - regular, normal purchases that are active
 * - regular, normal purchases that have been refunded
 * - gifts that I *redeemed*
 *    these will show cost = 0. note that if I purchase a gift and redeem
 *    it myself, it *will* show up in this table. also note that gifts I *purchase* will *not* 
 *    show up in this table (unless I redeem it too); the "gift purchases" table is for showing 
 *    gift purchases that I made that either have not been redeemed yet, or were redeemed by
 *    a different user
 * 
 * things this fragment does NOT get:
 *  
 * - gifts that I purchased, but have not been redeemed by anyone yet
 *    these will *not* show in this table, because we only want gifts that this user *redeemed* to
 *    show in this table. this is easily accomplished -- if a gift has not been redeemed yet
 *    then is_active = 0
 * - gifts that I purchased, but were redeemed by someone else
 *    these will *not* show in this table, because the customer_id will not be equal to my id.
 *    recall that when the other user redeems the gift, their id gets set to the customer_id field
 * - gift purchases that have been refunded
 *    we do not want to show these here, because they are not gifts that this user has redeemed.
 *    to accomplish this, we have to ensure that when we get is_refunded = 1 purchases we do
 *    not include gifts (so we add is_gift = null when getting refunded purchases)
 * 
 * Notes on how gift purchases work:
 *  step 1: click on "visa" button
 *    this inserts a row where is_active = 0, gift_is_active = null, both customer_id and
 *    gift_purchaser = the user who is making the purchase
 *  step 2: payment is made
 *    updates gift_is_active = 1 (importantly, note that is_active still = 0)
 *  step 3: a user redeems the gift
 *    updates is_active = 1. sets customer_id = to the user that redeemed the gift. sets
 *    gift_is_redeemed = 1 and also gift_redeemed_timstamp
 *  
 * defining here the paginated refetchable fragment
 * Purpose: relay support cursor base pagination, to implement cursor base pagination
 * we need to define refetchable query which will be refetched based on the unique connection
 * we don't need to define pagination info on each refetchable query as relay will handle it
 * via its below specified defined sysntax and on each refetch, first and last cursor automatically
 * set by relay.
 * We are using this fragment in relay hook (i-e usePaginationFragment) whcih will take 2 arguments
 * first one is refetchable fragment and 2nd one is query whcih contains where cluse information.
 * More Info: https://relay.dev/docs/api-reference/use-refetchable-fragment/
 */
const fragment = graphql`
  fragment Orders on query_root
  @argumentDefinitions(first: {type: "Int"}, after: {type: "String"}, userId: {type: "bigint"})
  @refetchable(queryName: "OrdersPaginatedFragment") {
    orders_connection(where: {
      customer_id: {_eq: $userId},
      _or: [
        # regular purchases the user has made, and gifts that this user has redeemed. recall that
        # when a user redeems a gift, their id gets set to customer_id and is_active gets set
        # = 1
        {is_active: {_eq: 1}},
        # a regular purchase the user made, which has been refunded. we do NOT want to include
        # gift purchases, thus the _is_gift=null statement
        {refunded: {_eq: 1}, is_gift: {_is_null: true}},
      ]
    },
    order_by: {created_at: desc}, 
    after: $after, first: $first)
    @connection(key: "Orders_orders_connection") {
      edges {
        node {
          actual_purchase_price
          admincreate
          admincreate_description
          created_at
          duration
          is_gift
          gift_id_random
          gift_is_redeemed
          paypal_invoice_number
          refunded
          valid_to
          locations {
            pk
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const Orders = ({
  fragmentRef, pageSize, handleClick, handleClickNoteIcon
}: any) => {
  // #region general

  // hold current page info...
  // As we are using MUI dataGrid and to show the paginated current page count, we need
  // to update this variable each time user click on next and previous button.
  const [page, setPage] = useState(0);

  // this is a helping hand to disable/enable next and previous buttons inside MUI
  // dataGrid if totalPages count equals to the current page.
  // updating this variable when there is no "hasNext" record from "usePaginationFragment"
  const [totalPages, setTotalPages] = useState(-1);

  // "usePaginationFragment" query returns accoumulated data for all the pages instead of
  // the current page but in MUI dataGrid we need to show data page by page that's why
  // below startIndex and endIndex are helping us to extract page specific data from
  // accoumulated data array returned from "usePaginationFragment"
  const [startIndex, setStartIndex] = useState<number>(0); // starting index of the visible rows
  const [endIndex, setEndIndex] = useState<number>(pageSize); // ending index of the visible rows

  // using state to store the order's data to ensure that the table remains visible
  // even when new data is being fetched. this approach prevents the table from
  // briefly disappearing when the order array is temporarily empty(when the network call is 
  // being made) during the  data fetch. the table will now continue to display the 
  // previous data until the new data is fetched and the state is updated.
  const [rowsData, setRowsData] = useState([]);

  const { t } = useTranslation(['languageLocations']);

  // #endregion

  // #region pagination

  /**
   * data: this readOnly variable holds accoumulated data of all pages instead of current
   * page, relay team is working on this advance use case to update it with only current
   * page data instead of accoumulated all previous pages data.
   *
   * don't need loadPrevious, as "data" variable always accoumulate previous records too.
   */
  const {
    data,
    isLoadingNext,
    loadNext,
    hasNext,
    // TODO: need to replace type with OrderHistoryTableQuery type.
  } = usePaginationFragment<any, Orders$key>(
    fragment,
    fragmentRef,
  );

  useEffect(() => {
    // At ths point, you know relay accoumulate the data in single readOnly object (data)
    // returned from "usePaginationFragment" and relay cache this data.
    // It means when user changes the route and came back relay will not send API call as
    // it already have data cached, so "hasNext" value can be false if we get its falsy
    // value, we set the totalPages by dividing the page lenght with page Size so user
    // are able to click on next page button and can see data there.
    if (!hasNext) {
      setTotalPages(Math.floor(
        data.orders_connection.edges.length / pageSize
      ));
    } else {
      setTotalPages(-1);
    }
  }, [hasNext, page, totalPages,
    data.orders_connection.edges.length, pageSize]);

  // This callback will trigger on page change...
  const handlePageChange = (newPage: number) => {
    const start = newPage * pageSize; // calculating new starting index...
    const end = start + pageSize;
    setStartIndex(start);
    setEndIndex(end); // calculating new ending index...
    // Only load new records in case of next page as it accoumulate prevous page records too...
    if (newPage > page) {
      loadNext(pageSize);
    }
    setPage(newPage); // updating current page ...
  };

  // #endregion

  // #region rows and columns

  // MUI: preparing columns for dataGrid...
  const columns: any = [
    {
      field: 'locations',
      headerName: 'Language',
      flex: 1,
      headerAlign: 'center',
      sortable: false,
      align: 'center',
      minWidth: 100
    },
    {
      field: 'created_at',
      headerName: 'Purchased',
      flex: 1,
      headerAlign: 'center',
      sortable: false,
      align: 'center',
      minWidth: 200
    },
    {
      field: 'validTill',
      headerName: 'Expiration Date',
      flex: 1,
      headerAlign: 'center',
      sortable: false,
      align: 'center',
      minWidth: 200
    },
    {
      field: 'duration',
      headerName: 'Hours',
      flex: 1,
      headerAlign: 'center',
      sortable: false,
      align: 'center',
      minWidth: 80
    },
    {
      field: 'price',
      headerName: 'Cost',
      flex: 1,
      headerAlign: 'center',
      sortable: false,
      align: 'center',
      minWidth: 70,
      renderCell: (params: any) => (
        <span>{`$${params.value}`}</span>
      ),
    },
    {
      field: 'refNumber',
      headerName: 'ID #',
      flex: 1,
      headerAlign: 'center',
      sortable: false,
      align: 'center',
      minWidth: 140
    },
    {
      field: 'note',
      headerName: 'Notes',
      flex: 1,
      headerAlign: 'center',
      sortable: false,
      align: 'center',
      renderCell: (params: { row: { note: string; }; }) => {
        if (params.row.note === 'gift') {
          return (
            <Box sx={{ cursor: 'pointer' }} onClick={(event) => handleClickNoteIcon(event, params.row.note)}>
              <IcSvg icon={IcSvgList.gift1} width="16px" height="16px" />
            </Box>
          );
        }

        if (params.row.note === 'admincreated') {
          return (
            <Box sx={{ cursor: 'pointer' }} onClick={(event) => handleClickNoteIcon(event, params.row.note)}>
              <IcSvg icon={IcSvgList.customersupport1} width="16px" height="16px" />
            </Box>
          );
        }

        if (params.row.note === 'refunded') {
          return (
            <Box sx={{ cursor: 'pointer' }} onClick={(event) => handleClickNoteIcon(event, params.row.note)}>
              <IcSvg icon={IcSvgList.refund1} width="16px" height="16px" />
            </Box>
          );
        }

        return null;
      },
      minWidth: 80
    },
    {
      headerName: 'Detail',
      flex: 1,
      headerAlign: 'center',
      sortable: false,
      align: 'center',
      renderCell: (params: { row: { refNumber: number; }; }) => (
        <Button
          size="small"
          color="accentPurple1"
          onClick={(event) => handleClick(event, params.row.refNumber)}
        >
          More Detail
        </Button>
      ),
      minWidth: 120,
    },
  ];

  useEffect(() => {
    // Filtering the visible rows for each page as "data" have accoumulated recoreds instead
    // of page specific records.
    // we are filtering here the page specific records from whole data.
    // This useEffect is preventing any potential flickering or layout shift that could 
    // occur if the DOM updates were delayed. By using `useEffect`, we ensure that 
    // the visible rows are ready for display in sync with the rendering, providing a smoother 
    // and more responsive user experience.
    const tempOrders: any = [];

    if (data && data.orders_connection?.edges.length) {
      data.orders_connection?.edges?.forEach((info: any, index: number) => {
        // the "notes" column has three possibilities, created here
        let noteColumn = '';
        if (info.node.refunded === 1) {
          noteColumn = 'refunded';
        } else if (info.node.is_gift === 1) {
          noteColumn = 'gift';
        } else if (info.node.admincreate === 1) {
          noteColumn = 'admincreated';
        } else {
          noteColumn = '';
        }

        // build the data
        if (index >= startIndex && index < endIndex) {
          let pricepaid = info.node.actual_purchase_price;
          // some very very old orders dont have any price in the db at all
          if (info.node.actual_purchase_price === null) {
            pricepaid = 0;

            // gifts that this user redeemed, cost them $0. note that if the user purchased the
            // gift and redeemed it themselves, we will be showing them a 0 price here. in the
            // gifts table though, we'll show them the actual price they paid for it 
          } else if (info.node.is_gift === 1) {
            pricepaid = 0;
          }

          const newObj = {
            id: index + 1,
            price: pricepaid,
            admincreate: info.node.admincreate,
            admincreate_description: info.node.admincreate_description,
            created_at: DateTime.fromSeconds(info.node.created_at).toLocaleString({ dateStyle: 'medium' }),
            duration: (info.node.duration / 3600),
            refNumber: info.node.paypal_invoice_number,
            validTill: DateTime.fromSeconds(info.node.valid_to).toLocaleString({ dateStyle: 'medium' }),
            is_gift: info.node.is_gift,
            note: noteColumn,
            // ignore lingui eslint err, this uses react-i18next not lingui
            // eslint-disable-next-line
            locations: t(`${info.node.locations.pk}`),
          };
          tempOrders.push(newObj);
        }
      });

      if (tempOrders.length) {
        setRowsData(tempOrders);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page,
    data.orders_connection.edges.length,
    pageSize]);

  // #endregion

  return (
    <DataGrid
      rows={rowsData}
      columns={columns}
      autoHeight
      paginationMode="server"
      loading={isLoadingNext}
      handlePageChange={handlePageChange}
      currentPage={page}
      totalPages={totalPages}
      // server-side pagination for an unknown number of items
      // https://github.com/mui/mui-x/issues/409
      rowCount={-1}
    />

  );
};
