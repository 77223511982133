import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Ic,
  ListItemTextCustom,
  ListItemButtonCustom,
} from '@languageconvo/wcl';
import { useTranslation } from 'react-i18next';
import { NavItemProps } from '../../../../types/nav-section.type';
import {
  ListItemStyleParent,
  ListItemStyleChild,
  ListItemTextStyleParent,
  ListItemTextStyleChild,
  ListItemIconStyle,
  ListItemSvgStyle,
} from './style';
import { removeJWTFromLocalStorageAndLogoutUser } from '../../../../utils/logout';
import useAuth from '../../../../hooks/useAuth';

// list item for parent/header, the main "sections" of the navbar: Home, Schedule, Purchase, etc.
// note that for example Home does not have any children pages, while other sections like
// Purchase do have child pages, so this must handle each of those situations
export const NavbarInnerListItemParent = ({
  item, isCollapse, open = false, active, onOpen, onMouseEnter, onMouseLeave, navRef
}: NavItemProps) => {
  const {
    title, path, icon, children
  } = item;
  const { t } = useTranslation('cpNavbar');

  // builds the inner item text
  const renderContent = (
    <>
      {
        icon && (
        <ListItemIconStyle>
          <ListItemSvgStyle src={icon} />
        </ListItemIconStyle>
        )
      }
      <ListItemTextStyleParent
        disableTypography
        // ignore the lingui eslint errors, this uses react-i18next not lingui
        // eslint-disable-next-line
        primary={t(`${title.toLowerCase()}.title`)}
        isCollapse={isCollapse}
      />
      {/* on desktop if the drawer isn't collapsed, and the section has child pages, 
        this adds an icon at the end to show the user they can expand/collapse the  */}
      {!isCollapse && children && <Ic iconName={open ? 'angle-down' : 'angle-right'} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyleParent
        isCollapse={isCollapse}
        onClick={onOpen}
        activeRoot={active}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={navRef}
      >
        {renderContent}
      </ListItemStyleParent>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyleParent href={path} target="_blank" rel="noopener">
      {renderContent}
    </ListItemStyleParent>
  ) : (
    <ListItemStyleParent
      component={RouterLink}
      to={path}
      activeRoot={active}
      isCollapse={isCollapse}
    >
      {renderContent}
    </ListItemStyleParent>
  );
};

/**
 * list items for inside dropdown only (so, when the navbar is expanded)
 */
export const NavbarInnerListItemChildDropdown = ({
  item, active = false, parent, isCollapse, isPopoverChild, newtab
}: NavItemProps) => {
  const {
    path,
    title,
  } = item;
  const { t } = useTranslation('cpNavbar');
  const { setLogoutInProgress } = useAuth();

  // builds the inner item text
  const renderContent = (
    <ListItemTextStyleChild
      disableTypography
      // ignore the lingui eslint errors, this uses react-i18next not lingui
      // eslint-disable-next-line
      primary={t(`${parent?.toLowerCase()}.children.${title}`)}
    />
  );

  // if the item happens to be a logout link
  if (isLogoutLink(path)) {
    return (
      <ListItemStyleChild
        subItem
        isPopoverChild={isPopoverChild}
        onClick={
          () => removeJWTFromLocalStorageAndLogoutUser(setLogoutInProgress)
        }
      >
        {renderContent}
      </ListItemStyleChild>
    );
  }

  // if the item happens to be a link to an external page
  return isExternalLink(path) ? (
    <ListItemStyleChild
      subItem
      isPopoverChild={isPopoverChild}
      href={path}
      rel="noopener"
      target="_blank"
    >
      {renderContent}
    </ListItemStyleChild>
  ) : (
    // this is for most items: if the item is not a logout link, and not an external link,
    // this is built
    <ListItemStyleChild
      subItem
      isPopoverChild={isPopoverChild}
      component={RouterLink}
      to={path}
      activeSub={active}
      isCollapse={isCollapse}
      // if we want to open this page in a new tab
      {...(newtab ? { target: '_blank' } : { })}
    >
      {renderContent}
    </ListItemStyleChild>
  );
};

/**
 * list items for inside popover only (so, only when navbar is collapsed on desktop).
 * notice we use our custom listitemtext and listitembutton from the wcl, instead of
 * the ones locally here in style file. the wcl custom was built specifically for popover
 * lists where we want items to be hoverable and have a standard bg color
 */
export const NavbarInnerListItemChildPopover = ({
  item, active = false, parent, newtab,
}: NavItemProps) => {
  const {
    path,
    title,
  } = item;
  const { t } = useTranslation('cpNavbar');
  const { setLogoutInProgress } = useAuth();

  // builds the inner item text
  const renderContent = (
    <ListItemTextCustom
      size="large"
      disableTypography
      // ignore the lingui eslint errors, this uses react-i18next not lingui
      // eslint-disable-next-line
      primary={t(`${parent?.toLowerCase()}.children.${title}`)}
    />
  );

  // if the item happens to be a logout link
  if (isLogoutLink(path)) {
    return (
      <ListItemButtonCustom
        color="primary"
        size="large"
        onClick={
          () => removeJWTFromLocalStorageAndLogoutUser(setLogoutInProgress)
        }
      >
        {renderContent}
      </ListItemButtonCustom>
    );
  }

  // if the item happens to be a link to an external page
  return isExternalLink(path) ? (
    <ListItemButtonCustom
      color="primary"
      size="large"
      href={path}
      rel="noopener"
      target="_blank"
    >
      {renderContent}
    </ListItemButtonCustom>
  ) : (
    // this is for most items: if the item is not a logout link, and not an external link,
    // this is built
    <ListItemButtonCustom
      color="primary"
      size="large"
      component={RouterLink}
      to={path}
      selected={active}
      // if we want to open this page in a new tab
      {...(newtab ? { target: '_blank' } : { })}
    >
      {renderContent}
    </ListItemButtonCustom>
  );
};

function isLogoutLink(path: string) {
  return path?.includes('logout');
}

function isExternalLink(path: string) {
  return path?.includes('http');
}
