import React from 'react';
import { Trans } from '@lingui/react/macro';
import {
  Ic, Ty
} from '@languageconvo/wcl';

// benefits of plan 1. made a component because it's used in multiple places
export const Plan1Benefits = () => (
  <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
    <li key="plan1-1">
      <Ty><Ic iconName="check" iconStyle="solid" color="accentGreen1" size="rel-lg" />&nbsp;&nbsp;<Trans>Join 1 bite-sized conversational class each week</Trans></Ty>
    </li>
    <li key="plan1-3">
      <Ty><Ic iconName="check" iconStyle="solid" color="accentGreen1" size="rel-lg" />&nbsp;&nbsp;<Trans>Unlimited access to our online study and practice tools</Trans></Ty>
    </li>
    <li key="plan1-2">
      <Ty><Ic iconName="check" iconStyle="solid" color="accentGreen1" size="rel-lg" />&nbsp;&nbsp;<Trans><strong>Save $1/hour</strong> on private lessons</Trans></Ty>
    </li>
    <li key="plan1-4">
      <Ty><Ic iconName="check" iconStyle="solid" color="accentGreen1" size="rel-lg" />&nbsp;&nbsp;<Trans>Private lesson credits never expire</Trans></Ty>
    </li>
  </ul>
);

// benefits of plan 2. made a component because it's used in multiple places
export const Plan2Benefits = () => (
  <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
    <li key="plan2-1">
      <Ty><Ic iconName="check" iconStyle="solid" color="accentGreen1" size="rel-lg" />&nbsp;&nbsp;<Trans>Join <strong>unlimited</strong> bite-sized conversational classes!</Trans></Ty>
    </li>
    <li key="plan2-3">
      <Ty><Ic iconName="check" iconStyle="solid" color="accentGreen1" size="rel-lg" />&nbsp;&nbsp;<Trans>Unlimited access to our online study and practice tools</Trans></Ty>
    </li>
    <li key="plan2-2">
      <Ty><Ic iconName="check" iconStyle="solid" color="accentGreen1" size="rel-lg" />&nbsp;&nbsp;<Trans><strong>Save $2/hour</strong> on private lessons</Trans></Ty>
    </li>
    <li key="plan2-4">
      <Ty><Ic iconName="check" iconStyle="solid" color="accentGreen1" size="rel-lg" />&nbsp;&nbsp;<Trans>Private lesson credits never expire</Trans></Ty>
    </li>
  </ul>
);
