import React from 'react';
import * as Sentry from '@sentry/react';
import { usePostHog } from 'posthog-js/react';
import { useFragment } from 'react-relay';
import { fbExternalId } from '../eventmanagerNonhook';
import { useEventsManager } from './useEventsManager';
import { EventsDataFragment } from './relay/EventsData';
import { EventsDataFragment$key } from './relay/__generated__/EventsDataFragment.graphql';

// will tell us if we've done the initialization of our tools, once during app load
let didInit = false;

export const EventsManagerComponent = ({ fragmentRefApplevelData }: any) => {
  // TODO: what if this data doesnt exist...? the query its coming from is at the app level, and
  // that data could be garbage collected at any time. seems like our applevel data query is
  // dangerous in that regard, will eventually cause problems
  const fragRef = fragmentRefApplevelData.users_connection.edges[0].node;
  const eventData: EventsDataFragment$key = useFragment(EventsDataFragment, fragRef);
  const posthog = usePostHog();

  // for many tools e.g. facebook, google analytics, we must initialize the tool only once.
  // so the application loads, and we initialize the tool. as user changes routes
  // we do *not* init again
  if (!didInit) {
    didInit = true;

    // @ts-ignore
    const theUid = eventData.random_id_public;

    // #region facebook

    try {
      // VERY important
      // here we have to lowercase, the hash (sha256) the user's random_id_public before we send
      // it to fb. this is because all of our other tools (reverse etl, mainly) that send data
      // to fb also send the user's random id hashed in this way. if we fail to do this, matching
      // in fb ads will not work
      const fbUserId = fbExternalId(theUid);

      // first, build the user data we're going to send to fb
      // for first name, last name, and email, fb wants them lowercase
      // @ts-ignore
      const theFn = eventData.first_name.trim().toLowerCase();
      // @ts-ignore
      const theLn = eventData.last_name.trim().toLowerCase();
      // @ts-ignore
      const theEm = eventData.email.trim();

      /* eslint-disable */
      //@ts-ignore
      !function(f,b,e,v,n,t,s)
        //@ts-ignore
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          //@ts-ignore
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            //@ts-ignore
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            //@ts-ignore
            n.queue=[];t=b.createElement(e);t.async=!0;
            //@ts-ignore
            t.src=v;s=b.getElementsByTagName(e)[0];
            //@ts-ignore
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
      /* eslint-enable */

      const pixelid = process.env.REACT_APP_FBPIXEL;

      // init sets up the fb pixel, note that THIS is the only place we can pass user data to
      // fb, we cannot do so in pageview or other events
      // @ts-ignore
      window.fbq(
        'init',
        pixelid,
        {
          fn: theFn,
          ln: theLn,
          // VERY important to use the hashed id otherwise our event tracking wont work
          // correctly in facebook advertising
          external_id: fbUserId,
          em: theEm,
        }
      );
    } catch (e) {
      Sentry.captureException(e);
    }

    // #endregion

    // #region posthog

    try {
      // note: posthog recommends adding ? in case posthog has not yet initialized
      posthog?.identify(theUid);
    } catch (e) {
      Sentry.captureException(e);
    }

    // #endregion
  }

  useEventsManager(fragmentRefApplevelData);

  return (
    <div />
  );
};
