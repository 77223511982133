/**
 * @generated SignedSource<<c411aca3282c29946fb090a05a59add7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PurchasePayQuery$variables = {
  paypal_payment_id: number;
};
export type PurchasePayQuery$data = {
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"PurchasePaySubsdtFragment" | "UserSubsDetailsFragment">;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"GetPackDetails">;
};
export type PurchasePayQuery = {
  response: PurchasePayQuery$data;
  variables: PurchasePayQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "paypal_payment_id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialstart_ts",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current_plan",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current_plan_status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "subs_mainplans",
  "kind": "LinkedField",
  "name": "subs_mainplans",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "order",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discount_amount",
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PurchasePayQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "paypal_payment_id",
            "variableName": "paypal_payment_id"
          }
        ],
        "kind": "FragmentSpread",
        "name": "GetPackDetails"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PurchasePaySubsdtFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserSubsDetailsFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PurchasePayQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "paypal_payment_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "pk"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "packsConnection",
        "kind": "LinkedField",
        "name": "packs_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "packsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "packs",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "location_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duration",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "name": "relaySubscription",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "subs",
                          "kind": "LinkedField",
                          "name": "subs",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/),
                            (v5/*: any*/),
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "users",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": false
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subs",
                    "kind": "LinkedField",
                    "name": "subs",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "27df917388362ab23940a3902aa4ddac",
    "id": null,
    "metadata": {},
    "name": "PurchasePayQuery",
    "operationKind": "query",
    "text": "query PurchasePayQuery(\n  $paypal_payment_id: Int!\n) {\n  ...GetPackDetails_jF91r\n  users_connection {\n    edges {\n      node {\n        ...PurchasePaySubsdtFragment\n        ...UserSubsDetailsFragment\n        id\n      }\n    }\n  }\n}\n\nfragment GetPackDetails_jF91r on query_root {\n  packs_connection(where: {pk: {_eq: $paypal_payment_id}}) {\n    edges {\n      node {\n        location_id\n        price\n        duration\n        id\n      }\n    }\n  }\n}\n\nfragment PurchasePaySubsdtFragment on users {\n  ...relaySubscriptionResolver\n}\n\nfragment UserSubsDetailsFragment on users {\n  subs {\n    current_plan\n    current_plan_status\n    trialstart_ts\n    subs_mainplans {\n      order\n      discount_amount\n      id\n    }\n    id\n  }\n}\n\nfragment relaySubscriptionResolver on users {\n  subs {\n    trialstart_ts\n    current_plan\n    current_plan_status\n    subs_mainplans {\n      order\n      discount_amount\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "32abe4b609cee6c53223b5a5d42af045";

export default node;
