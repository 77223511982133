import React from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import { i18n as i18nLingui } from '@lingui/core';
import { I18nProvider as I18nProviderLingui } from '@lingui/react';
import * as Sentry from '@sentry/react';
import * as atatus from 'atatus-spa';
import { PostHogProvider } from 'posthog-js/react';
import { GlobalStyleContainer } from '@languageconvo/wcl';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { I18nextProvider } from 'react-i18next';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import i18n from './translations/i18n';
import { ThemeProvider } from './theme';
import AppEnvironment from './relay/AppEnvironment';
import { Router } from './routes';
import { AppLevelError } from './common/components/errorfallback/AppLevelError';
import { SettingsProvider } from './context/SettingsProvider';
import { AuthProvider } from './context/AuthProvider';
import { CollapseDrawerProvider } from './context/CollapseDrawerContext';
import { errorsToIgnore } from './common/components/errors/IgnoreErrors';
import { messages as enMessages } from './locales/en/messages';
import { messages as esMessages } from './locales/es/messages';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // to differentiate local, dev and production environments...
  environment: process.env.REACT_APP_SENTRY_ENV,
  // Don't need to send errors on sentry, in case of development...
  enabled: process.env.NODE_ENV !== 'development',
  // array of errors that will NOT be logged to sentry
  ignoreErrors: errorsToIgnore,
});

// to monitor real-time insights we are using atatus.
atatus.config(
  process.env.REACT_APP_ATATUS_UID as string,
  { disableErrorTracking: true }
).install();

// lingui setup
i18nLingui.load({
  en: enMessages,
  es: esMessages,
});
i18nLingui.activate('en');

const App = () => {
  // #region install js tools

  /* js tools like rudderstack and facebook need to be installed only once, when
      the app first loads. we do that here. things like page views for rudderstack, google
      analytics, etc. need to happen on every page view; those actions are called further
      down in the dashboard component
  */

  // #region rudderstack

  try {
  // env vars have type  string | undefined
  // .load() method requires two strings, so here we ensure we pass it strings
    const writeKey = process.env.REACT_APP_RUDDERSTACK_WRITEKEY ?? '';
    const dataPlane = process.env.REACT_APP_RUDDERSTACK_DATAPLANE ?? '';

    // set up rudderstack
    const analyticsInstance = new RudderAnalytics();
    analyticsInstance.load(writeKey, dataPlane);
    window.rudderanalytics = analyticsInstance;
  } catch (e) {
    Sentry.captureException(e);
  }

  // #endregion

  // #region posthog

  // during local development we usually dont want to send events to posthog, so that we dont
  // eat up our quota. if you want to capture events for testing locally, set the REACT_APP_PH_LOCAL
  // env var to 1
  let autocaptureVal = true;
  let posthogKey = process.env.REACT_APP_PH_KEY;
  if (process.env.REACT_APP_ENV === 'local' && process.env.REACT_APP_PH_LOCAL !== '1') {
    posthogKey = 'fakekey';
    autocaptureVal = false;
  }

  const posthogHoptions = {
    api_host: 'https://us.i.posthog.com',
    autocapture: autocaptureVal,
    // since this is an SPA, posthog can't automatically capture page views. we turn that feature
    // off and will manually capture pageview events. but we DO want posthog to capture leaves
    capture_pageview: false,
    capture_pageleave: true,
    // we will manually record only the sessions were interested in
    disable_session_recording: true,
  };

  // #endregion

  // #endregion

  return (
    <Sentry.ErrorBoundary fallback={({ error }) => <AppLevelError error={error} />}>
      <HelmetProvider>
        {/* preparing relay environment. it is responsible for creating the relay env. */}
        <RelayEnvironmentProvider environment={AppEnvironment}>
          {/* context for user login state */}
          <AuthProvider>
            {/* lingui language provider */}
            <I18nProviderLingui i18n={i18nLingui}>
              {/* langnauge translation provider */}
              <I18nextProvider i18n={i18n}>
                {/* overall site setting like dark and light mode etc */}
                <SettingsProvider>
                  {/* this is coming from wcl which initialize MUI and our custom theme */}
                  <ThemeProvider>
                    {/* context for drawer isCollapse (open/close) in desktop view */}
                    <CollapseDrawerProvider>
                      {/* applying global styling managed by wcl (i-e overflow: hidden etc.) */}
                      <GlobalStyleContainer>
                        <PostHogProvider
                          apiKey={posthogKey}
                          options={posthogHoptions}
                        >
                          {/* all routes initialize form here. */}
                          <Router />
                        </PostHogProvider>
                      </GlobalStyleContainer>
                    </CollapseDrawerProvider>
                  </ThemeProvider>
                </SettingsProvider>
              </I18nextProvider>
            </I18nProviderLingui>

            {/* react-toastify for notifications */}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            {/* </BrowserRouter> */}
          </AuthProvider>
        </RelayEnvironmentProvider>
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  );
};
export default App;
