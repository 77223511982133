import * as Sentry from '@sentry/react';
import { sha256 } from 'js-sha256';
import { logoutInStep1, logoutInStep2 } from './intercom';
import { logoutPgStep1, logoutPgStep2 } from './posthogNonhook';
import { logoutRdStep1, logoutRdStep2 } from './rudderstack';
import { sleep } from '../sleep';

/* IMPORTANT NOTE
    Why did we create this file when we already have eventmanager.ts? The reason is, the
    logout event is called from removeJWTFromLocalStorageAndLogoutUser, which itself
    is called by the Relay app environtment file. For some Relay related reason we cannot
    make that thing a custom hook, which means we cannot use our eventmanager custom hook.
    To solve this, we've created this non-hook version of event manager but *only* for
    the logout event. We should not put other events here, they should be put in
    eventmanager
*/

// user logs out
export const evtLogout = async () => {
  try {
  // first, we call our "track" events to actually track the logout as a specific event. we call
  // this then wait a little bit so that when these track events are called, the tools still
  // have user data in them, allowing the track can be tied to a specific user
    logoutInStep1();
    logoutPgStep1();
    logoutRdStep1();

    // we wait just a tiny bit, to ensure that those "track" functions above actually got sent to
    // our js tools. reason being, when we call the Step2 functions all the user data is deleted
    // from the js tools. if that happens too fast, the tools can't send the "track" functions with
    // user data in them
    await sleep(300);

    // then we call the built-in "reset" functions, which clear the user's data
    logoutInStep2();
    logoutPgStep2();
    logoutRdStep2();

    // a tiny sleep just to ensure those two events above have occurred
    await sleep(200);
  } catch (e) {
    Sentry.captureException(e);
  }
};

// convert random_id_public to a hashed value for fb events
// https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching
export const fbExternalId = (randomIdPublic: string) => {
  const lowercaseUid = randomIdPublic.toLowerCase();
  const fbUserId = sha256(lowercaseUid);
  return fbUserId;
};
