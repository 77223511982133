import React, {
  useLayoutEffect, useState, Suspense, useContext,
} from 'react';
import * as Sentry from '@sentry/react';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { ClassroomSetupContext } from '../../GroupClassroom';
import { Setup3RoomLayoutSubscription } from './Setup3RoomLayoutSubscription';

interface Props {
  setmodalviewContents: any
  setmodalviewState: any
}

// Spreading all fragments inside this mutation so, we can read data in the relavent components.
const StuApptgroupClassroomenterMutation = graphql`
  mutation Setup2GetClassroomDataMutation($lessonUuid: String!) {
    stu_apptgroup_classroomenter(lessonUuid: $lessonUuid) {
      ...LessonDetailFragment
      ...LessonTimeCalculationFragment
      ...InformationModalFragment
      ...ClassroomViewFragment
      ...PusherInfoFragment
    }
  }
`;

// Here we are executing mutation call and storing the fragementRef for later use.
export const Setup2GetClassroomData = ({
  setmodalviewContents,
  setmodalviewState,
}: Props) => {
  // #region general

  // change pageloading from "getting device access..." to "connecting to classroom..."
  // since the user has given device permissions
  const csSetupContext = useContext(ClassroomSetupContext);
  useLayoutEffect(() => {
    csSetupContext.setPageviewState(201);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this state will contain the reference returned from initialClassroomDataRef Mutation
  const [initialClassroomDataRef, setInitialClassroomDataRef] = useState(null);
  const [searchParams] = useSearchParams();

  //  getting the lesson uuid from params
  const uuid = searchParams.get('uuid');

  // we'll set this to true if there's an error getting classroom data
  const [getDataError, setGetDataError] = useState(false);

  // #endregion

  // #region enter classroom api call, get classroom general data

  // enter classroom mutation
  const [ClassenterMutation] = useMutation(StuApptgroupClassroomenterMutation);
  useLayoutEffect(() => {
    // here we are actually executing the mutation.
    ClassenterMutation({
      variables: {
        lessonUuid: uuid,
      },
      onCompleted(data: any) {
        setInitialClassroomDataRef(data.stu_apptgroup_classroomenter);
      },
      onError(err: any) {
        // this will stop the next components from loading. we'll show the user a major
        // error in the main card on the page, they cannot enter the class
        setGetDataError(true);
        // display graceful error to the user
        classroomenterErrorHandler(err, csSetupContext);
      }
    });
    // eslint-disable-next-line
  }, []);

  // getting classroom data
  if (!initialClassroomDataRef) {
    return <div />;
  }

  // return nothing if error occurred. we use our pageview stuff above to display an error
  // to the user
  if (getDataError) {
    return <div />;
  }

  // #endregion

  return (
    <Suspense>
      <Setup3RoomLayoutSubscription
        uuid={uuid!}
        initialClassroomDataRef={initialClassroomDataRef}
        setmodalviewContents={setmodalviewContents}
        setmodalviewState={setmodalviewState}
      />
    </Suspense>
  );
};

// #region error handler for StuApptgroupClassroomenterMutation

enum ClassroomenterErrorCodes {
  GeneralError = 'GeneralError',
  LessonNoLongerExists = 'LessonNoLongerExists',
  LessonEnded = 'LessonEnded',
  LessonTooFarInAdvance = 'LessonTooFarInAdvance',
  NoReservationLessonEndsSoon = 'NoReservationLessonEndsSoon',
  FailedToCreateReservation = 'FailedToCreateReservation',
  LimitReachedForCurrentWeek = 'LimitReachedForCurrentWeek',
}

// display graceful error to the user
export const classroomenterErrorHandler = (
  err: any,
  csSetupContext: any,
) => {
  const error = JSON.parse(err.message);
  const errCode = error.extensions.code;

  try {
    if (errCode === ClassroomenterErrorCodes.GeneralError) {
      csSetupContext.setPageviewState(120);
    } else if (errCode === ClassroomenterErrorCodes.LessonNoLongerExists) {
      csSetupContext.setPageviewState(121);
    } else if (errCode === ClassroomenterErrorCodes.LessonEnded) {
      csSetupContext.setPageviewState(122);
    } else if (errCode === ClassroomenterErrorCodes.LessonTooFarInAdvance) {
      csSetupContext.setPageviewState(123);
    } else if (errCode === ClassroomenterErrorCodes.NoReservationLessonEndsSoon) {
      csSetupContext.setPageviewState(124);
    } else if (errCode === ClassroomenterErrorCodes.FailedToCreateReservation) {
      csSetupContext.setPageviewState(125);
    } else if (errCode === ClassroomenterErrorCodes.LimitReachedForCurrentWeek) {
      csSetupContext.setPageviewState(126);
    } else {
      // log unknown errors and display the general error
      Sentry.captureException(
        new Error('classroomenterErrorHandler detected an unknown error'),
        {
          extra: {
            error: err,
          }
        }
      );
      csSetupContext.setPageviewState(120);
    }
  } catch (e) {
    // log unexpeted error and display general error
    Sentry.captureException(
      e,
      {
        extra: {
          note: 'An unexpected error occurred in classroomenterErrorHandler',
        }
      }
    );
    csSetupContext.setPageviewState(120);
  }
};

// #endregion
