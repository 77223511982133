import React from 'react';
import {
  CardSmallColor, Grid2, Grid2Ct, IcSvgList, Link, Ty, Box, Ic,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';
import { Trans } from '@lingui/react/macro';
import { sitedata } from '../../../utils/sitedata';
import { SmallcardMaxWidthContain } from '../common/PurchaseCommon';

interface Props {
  isSubscribed: boolean;
}

// conversational classes card contents
export const PurchaseGroup1 = ({
  isSubscribed
}: Props) => {
  // ui when user is not currently subscribed
  if (!isSubscribed) {
    return (
      <Grid2Ct>
        {/* text */}
        <Grid2 xs={12} sx={{ mt: { xs: 1, sm: 1 } }}>
          <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
            <li key="plan2-0">
              <Ty><Ic iconName="check" iconStyle="solid" color="accentGreen1" size="rel-lg" />&nbsp;&nbsp;<Trans>A monthly subscription, $9.99/month and $29.99/month options</Trans></Ty>
            </li>
            <li key="plan2-1">
              <Ty><Ic iconName="check" iconStyle="solid" color="accentGreen1" size="rel-lg" />&nbsp;&nbsp;<Trans>Join <strong>unlimited</strong> classes 7 days a week</Trans></Ty>
            </li>
            <li key="plan2-2">
              <Ty><Ic iconName="check" iconStyle="solid" color="accentGreen1" size="rel-lg" />&nbsp;&nbsp;<Trans><strong>Save $2/hour</strong> on your private lessons</Trans></Ty>
            </li>
            <li key="plan2-3">
              <Ty><Ic iconName="check" iconStyle="solid" color="accentGreen1" size="rel-lg" />&nbsp;&nbsp;<Trans>Fun, engaging conversational classes with a teacher and a few other students! Click the ? above to learn more</Trans></Ty>
            </li>
          </ul>
        </Grid2>

        {/* button link to manage page */}
        <Grid2 xs={12} sx={{ mt: 1 }}>
          <SmallCardContainer>
            <Link
              to={sitedata.url.app.manageSubscriptionDt.pathFull}
              component={NavLink}
              linkStyle="nostyle"
            >
              <CardSmallColor
                text="Learn More, Try Free!"
                icon={IcSvgList.star1}
                color="accentGreen1"
                hovercursor="pointer"
              />
            </Link>
          </SmallCardContainer>
        </Grid2>
      </Grid2Ct>
    );
  }

  // ui when user is subscribed
  return (
    <Grid2Ct>
      {/* text */}
      <Grid2 xs={12} sx={{ mt: { xs: 1, sm: 2 } }}>
        <Ty>
          You&apos;re subscribed to classes!
          <br /><br />
          Click the button below to manage your subscription, view payment history,
          cancel/restart, or change your billing information:
        </Ty>
      </Grid2>

      {/* button link to manage page */}
      <Grid2 xs={12} sx={{ mt: 1 }}>
        <SmallCardContainer>
          <Link
            to={sitedata.url.app.manageSubscriptionDt.pathFull}
            component={NavLink}
            linkStyle="nostyle"
          >
            <CardSmallColor
              text="Manage Subscription"
              icon={IcSvgList.settings1}
              color="accentGreen1"
              hovercursor="pointer"
            />
          </Link>
        </SmallCardContainer>
      </Grid2>
    </Grid2Ct>
  );
};

// container for the smallcard/button that links user to the manage page
const SmallCardContainer = ({ children }: any) => (
  <Box display="flex" justifyContent="center">
    <SmallcardMaxWidthContain>
      {children}
    </SmallcardMaxWidthContain>
  </Box>
);
