import {
  Dialog, Grid2Ct, Grid2, Ty, Box, Button, Alert,
} from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import React, { Dispatch, SetStateAction } from 'react';
import { Trans } from '@lingui/react/macro';
import { useRecordUserActionToHistoryTable } from '../../hooks/useRecordUserAction';
import { Plan1Benefits, Plan2Benefits } from '../PlanBenefits';
import { SubscriptionCurrentPlan, SubscriptionPendingChange } from '../../ManageSubscriptionTypes';
import { useOurSubscriptions } from '../../../../../common/utils/subscriptions/useOurSubscriptions';

interface Props {
  openCancelPlanModal: boolean;
  setOpenCancelPlanModal: Dispatch<SetStateAction<boolean>>;
  subCurrent: SubscriptionCurrentPlan;
  subPendingChange: SubscriptionPendingChange;
}

// user has a plan, and has clicked the cancel card
export const CancelModal = ({
  openCancelPlanModal,
  setOpenCancelPlanModal,
  subCurrent,
  subPendingChange,
}: Props) => {
  const {
    recordUserActionToHistoryTable,
    isLoading,
    isError
  } = useRecordUserActionToHistoryTable();
  // if the user already has a cancelation scheduled, we wont let them cancel again
  const isCancellationScheduled = subPendingChange === SubscriptionPendingChange.Cancel;

  // standard plan data
  const { plan1Details, plan2Details } = useOurSubscriptions();

  // here we are determing the `isDowngradeScheduled` if yes we are not allowing 
  // student to again scheduled a downgrade.
  const isDowngradeScheduled = subPendingChange === SubscriptionPendingChange.Downgrade;

  // the user already has a cancelation scheduled, we'll just show a note
  if (isCancellationScheduled) {
    return (
      <Dialog
        isOpen={openCancelPlanModal}
        onClose={() => setOpenCancelPlanModal(!openCancelPlanModal)}
        width="xs"
        color="accentRed1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty>
              <Trans>
                Your subscription will be canceled at the end of this billing cycle. Continue to
                enjoy your subscription&apos;s benefits until then!
              </Trans>
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    );
  }

  // if the user is on the lower plan, we only show a cancel option (no downgrade option) 
  if (subCurrent.planDbId === plan1Details.dbId) {
    return (
      <Dialog
        isOpen={openCancelPlanModal}
        onClose={() => setOpenCancelPlanModal(!openCancelPlanModal)}
        width="sm"
        color="accentRed1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty v="h2New" align="center"><Trans>Completely cancel your plan?</Trans></Ty>

            <Ty cp={{ sx: { mt: 3 } }}>
              <Trans>
                We would hate to see you go! If you cancel, you&apos;ll lose out on all
                of these benefits at the end of your current billing cycle:
              </Trans>
            </Ty>

            {/* Showing user benfits they are going to loose based on their current plan */}
            <Plan1Benefits />

            {/* button */}
            <Box display="flex" justifyContent="center" sx={{ mt: 3, mb: 2 }}>
              <Button
                color="accentRed1"
                disabled={isLoading}
                isLoading={isLoading}
                onClick={() => recordUserActionToHistoryTable(
                  null,
                  openCancelPlanModal,
                  setOpenCancelPlanModal
                )}
              >
                <Trans>
                  Cancel My Subscription
                </Trans>
              </Button>
            </Box>

            {/* if mutation fails, show an error  */}
            {isError && (
            <Alert severity="error">
              <Trans>
                Something went wrong, please try that again. Your plan may not have been canceled!
              </Trans>
            </Alert>
            )}
          </Grid2>
        </Grid2Ct>
      </Dialog>
    );
  }

  // the user is on the higher plan, and does not have a downgraade scheduled. we'll show them an
  // option to downgrade
  if (subCurrent.planDbId === plan2Details.dbId && !isDowngradeScheduled) {
    return (
      <Dialog
        isOpen={openCancelPlanModal}
        onClose={() => setOpenCancelPlanModal(!openCancelPlanModal)}
        width="xl"
        color="accentRed1"
      >
        <Grid2Ct>
          {/* downgrade to plan 1 */}
          <Grid2 md={5}>
            <Ty v="h2New"><Trans>Downgrade Plan</Trans></Ty>

            <Ty cp={{ sx: { mt: 2 } }}>
              <Trans>
                Instead of canceling, consider our really low cost plan! It comes with
                these benefits:
              </Trans>
            </Ty>

            {/* display plan 1 benefits */}
            <Plan1Benefits />

            {/* downgrade button */}
            <Box sx={{ mt: 3, mb: 2 }}>
              <Button
                color="accentPurple1"
                disabled={isLoading}
                isLoading={isLoading}
                onClick={() => recordUserActionToHistoryTable(
                  1,
                  openCancelPlanModal,
                  setOpenCancelPlanModal
                )}
              >
                <Trans>
                  Downgrade
                </Trans>
              </Button>
            </Box>
          </Grid2>

          {/* completely cancel */}
          <Grid2 md={5} mdOffset={1}>
            <Ty v="h2New"><Trans>Completely Cancel</Trans></Ty>

            <Ty>
              <Trans>
                By canceling, you&apos;ll lose these benefits at the end of your current billing
                cycle:
              </Trans>
            </Ty>

            <Plan2Benefits />

            <Box sx={{ mt: 3, mb: 2 }}>
              <Button
                color="accentRed1"
                disabled={isLoading}
                isLoading={isLoading}
                onClick={() => recordUserActionToHistoryTable(
                  null,
                  openCancelPlanModal,
                  setOpenCancelPlanModal
                )}
              >
                <Trans>
                  Completely Cancel
                </Trans>
              </Button>
            </Box>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    );
  }

  // the user is on the higher plan, and DOES have a downgrade scheduled. we'll show just the option
  // to cancel
  if (subCurrent.planDbId === plan2Details.dbId) {
    return (
      <Dialog
        isOpen={openCancelPlanModal}
        onClose={() => setOpenCancelPlanModal(!openCancelPlanModal)}
        width="sm"
        color="accentRed1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty v="h2New"><Trans>Completely Cancel</Trans></Ty>

            <Ty>
              <Trans>
                By canceling, you&apos;ll lose these benefits at the end of your current billing
                cycle:
              </Trans>
            </Ty>

            <Plan2Benefits />

            {/* button */}
            <Box sx={{ mt: 3, mb: 2 }}>
              <Button
                color="accentRed1"
                isLoading={isLoading}
                disabled={isLoading}
                onClick={() => recordUserActionToHistoryTable(
                  null,
                  openCancelPlanModal,
                  setOpenCancelPlanModal
                )}
              >
                <Trans>
                  Completely Cancel
                </Trans>
              </Button>
            </Box>

            {/* if mutation fails, show an error  */}
            {isError && (
            <Alert severity="error">
              <Trans>
                Something went wrong, please try that again. Your plan may not have been canceled!
              </Trans>
            </Alert>
            )}
          </Grid2>
        </Grid2Ct>
      </Dialog>
    );
  }

  // if code ever gets here, we made a mistake. we'll log an error and also show the option
  // to cancel, just so we always make sure users have that option
  Sentry.captureException(
    new Error(
      'CancelModal got an unexpected situation'
    ),
    {
      extra: {
        planId: subCurrent.planDbId,
        dg: isDowngradeScheduled,
        cl: isCancellationScheduled,
        pd: subPendingChange,
      },
    }
  );
  return (
    <Dialog
      isOpen={openCancelPlanModal}
      onClose={() => setOpenCancelPlanModal(!openCancelPlanModal)}
      width="sm"
      color="accentRed1"
    >
      <Grid2Ct>
        <Grid2 xs={12}>
          <Ty v="h2New"><Trans>Completely Cancel</Trans></Ty>

          <Ty>
            <Trans>
              By canceling, you&apos;ll lose all of your subscription benefits at the end
              of this billing cycle. We would hate to see you go!
            </Trans>
          </Ty>

          {/* button */}
          <Box sx={{ mt: 3, mb: 2 }}>
            <Button
              color="accentRed1"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={() => recordUserActionToHistoryTable(
                null,
                openCancelPlanModal,
                setOpenCancelPlanModal
              )}
            >
              <Trans>
                Completely Cancel
              </Trans>
            </Button>
          </Box>

          {/* if mutation fails, show an error  */}
          {isError && (
          <Alert severity="error">
            <Trans>
              Something went wrong, please try that again. Your plan may not have been canceled!
            </Trans>
          </Alert>
          )}
        </Grid2>
      </Grid2Ct>
    </Dialog>
  );
};
