import {
  Box, styled, WCLThemeProps,
} from '@languageconvo/wcl';

export const SmallcardMaxWidthContainWidths = {
  xs: '380px',
  smUp: '320px',
};

// a container for our small card color components that are used in a few components on the
// purchase main laout
export const SmallcardMaxWidthContain = styled(Box)(({
  theme,
}: WCLThemeProps) => ({
  // set a maximum width so the small cards never get really wide
  width: SmallcardMaxWidthContainWidths.smUp,
  maxWidth: SmallcardMaxWidthContainWidths.smUp,

  // width on xs
  [theme!.breakpoints.only('xs')]: {
    width: SmallcardMaxWidthContainWidths.xs,
    maxWidth: SmallcardMaxWidthContainWidths.xs,
  },
}));

// TODO: these should really be an Alert or Pill in our wcl
export const SmallSavingsPillSuccess = styled(Box)(({
  theme,
}: WCLThemeProps) => ({
  padding: '5px 15px 5px 15px',
  width: 'fit-content',
  borderRadius: `${theme?.ourTheme.borders.borderRadius.medium}px`,
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentGreen1}`,
}));
export const SmallSavingsPillWarn = styled(Box)(({
  theme,
}: WCLThemeProps) => ({
  padding: '5px 15px 5px 15px',
  width: 'fit-content',
  borderRadius: `${theme?.ourTheme.borders.borderRadius.medium}px`,
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentYellow1}`,
}));
