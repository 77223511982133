import * as Sentry from '@sentry/react';
import { loadQuery, useMutation } from 'react-relay';
import { Dispatch, SetStateAction, useState } from 'react';
import { SaveUserActionMutation } from '../relay/SaveUserActionMutation';
import { StuSubsChangesubscriptionstripe } from '../relay/StuSubsChangesubscriptionstripe';
import AppEnvironment from '../../../../relay/AppEnvironment';
import { GetUserRecentActionQuery } from '../relay/GetUserRecentAction';

/**
 * custom hook to report user action to our BE.
 * this hook is used to record user action happening in manage subscription
 * page for showing user their recent action. Like UpGrade/downgrade/cancellation etc.
 */

export const useRecordUserActionToHistoryTable = () => {
  // we are showing an alert in the dialog to inform student that something went wrong
  // in case of our mutation fails or BE does not return us suceess response
  const [isError, setIsError] = useState(false);

  // muttaion call to insert user action to `subs_mainupdate_history` table
  const [commitSaveUserActionMutation] = useMutation(SaveUserActionMutation);

  // state to enable/disable button on the UI when mutation call is in flight.
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // mutation call to stripe after inserting user action to history table.
  // this will be executed when
  const [commitStuSubsChangeSubscriptionStripeMutation] = useMutation(
    StuSubsChangesubscriptionstripe
  );

  const recordUserActionToHistoryTable = (
    planId: number | null,
    openDownGradePlanModal?:boolean,
    setOpenDownGradePlanModal?: Dispatch<SetStateAction<boolean>>,
    openUpgradePlanModal?:boolean,
    setOpenUpgradeModal?: Dispatch<SetStateAction<boolean>>,
    openCancelPlanModal?:boolean,
    setOpenCancelPlanModal?:Dispatch<SetStateAction<boolean>>
  ) => {
    // as function invokes set the loading state to true to disable the 
    // I Agree and continue button in the upgrade/downgrade dialogs
    setIsLoading(true);
    try {
      commitSaveUserActionMutation({
        variables: { newPlanId: planId },
        onCompleted: (res: any) => {
          const isSuccess = res.stu_subs_changesubscription.success;
          if (isSuccess === true) {
            // making qurey so it fetch the fresh data, reason for making this 
            // call we need fresh data from our BE to show latest notification to the
            // user
            loadQuery(
              AppEnvironment,
              GetUserRecentActionQuery,
              {},
              { fetchPolicy: 'network-only' }
            );

            // enable the button again
            setIsLoading(false);

            // closing the dilaog 
            if (openDownGradePlanModal && setOpenDownGradePlanModal) {
              setOpenDownGradePlanModal(!openDownGradePlanModal);
            }

            if (openUpgradePlanModal && setOpenUpgradeModal) {
              setOpenUpgradeModal(!openUpgradePlanModal);
            }
            if (openCancelPlanModal && setOpenCancelPlanModal) {
              setOpenCancelPlanModal(!openCancelPlanModal);
            }

            // as our first mutation call succeeds inserting row into `subs_mainupdate_history` 
            // table we are executing the next mutation call
            // FE does not need to wait for 2nd mutation success or failure 
            commitStuSubsChangeSubscriptionStripeMutation({
              variables: {},
              onCompleted: () => {
                // FE does not need to do any thing
              },
              onError(err: any) {
                // logging the error to sentry
                Sentry.captureException(err);
              },
            });
          } else {
            // IMPORTANT! this should never occur, logging here so we now our code is not
            // working as expected
            Sentry.captureException(
              new Error(
                'IMPORTANT! Inserting user action to history table mutation returing that is not expected on FE '
              ),
              {
                extra: {
                  error: res,
                },
              }
            );
            setIsLoading(false);
          }
        },
        onError(errInInsertingRowToDB) {
          Sentry.captureException(errInInsertingRowToDB);
          // making error state to true to show error on the UI
          setIsError(true);
          // enabling the button again so user can retry if something went wrong.
          setIsLoading(false);
        },
      });
    } catch (errorInCathBlock) {
      // logging the error to sentry
      Sentry.captureException(errorInCathBlock);
    }
  };

  return {
    recordUserActionToHistoryTable, isLoading, isError
  };
};
