import React from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
import { PurchasePayPage1 } from './PurchasePayPage1';
import { CapturePaymentProvider } from '../context/CapturePayment';
import { PurchasePayQuery } from '../relay/queries/PurchasePay';

export const PurchasePayPage0 = () => {
  // getting the invoice from the url
  // because we need it to make the query to get
  // order's data
  const [searchParams] = useSearchParams();
  const paypalPaymentId = searchParams.get('pid');

  // the pack id should never be null, if it's null that means
  // the user has directly entered the url and doesn't provide the query string,
  // so we will not show the page to that user.
  if (paypalPaymentId === null) {
    throw new Error("Pack id doesn't found");
  }

  // fragmentRef is actually part of relay store ref. later on other components
  // where we wanted to use GetOrderDetails data, we can use this (fragmentRef)
  // and via fragment can read data from relay store.
  const response: any = useLazyLoadQuery(PurchasePayQuery, {
    paypal_payment_id: parseInt(paypalPaymentId!, 10),
  });

  // this gives us relaySubscription, the details of the user's current subscription
  const fragrefUserSubscription = response.users_connection.edges[0].node;

  return (
    <CapturePaymentProvider>
      <PurchasePayPage1
        fragmentReference={response}
        fragrefUserSubscription={fragrefUserSubscription}
      />
    </CapturePaymentProvider>
  );
};
