import React from 'react';
import { usePayPalCardFields } from '@paypal/react-paypal-js';
import {
  Button, Grid2Ct, Grid2, Box
} from '@languageconvo/wcl';
import { useCapturePayment } from '../../hooks/useCapturePayment';
import { ErrorsClickingOnSubmitButton } from '../helper/Errors';
import { FixableError } from '../helper/FixableError';

export const SubmitPayment = () => {
  const {
    isLoading, setIsLoading, setFixableErrors, setErrCode, // commitCaptureMutation,
    fixableErrors, errCode
  } = useCapturePayment();
  // Here declare the variable containing the hostedField instance
  const hostedFields: any = usePayPalCardFields();

  // user clicks the "buy" button
  const SubmitHandler = async () => {
    // this condition is to make sure that the submit method and getState method are available
    // because we have to utilize these function here in this handler
    // validate that `submit()` exists before using it
    // if (typeof hostedFields?.cardFields?.submit !== 'function'
    //   || typeof hostedFields?.cardFields?.getState !== 'function') return;

    // checking the hostedField values if these are valid, then we will call the submit method,
    // otherwise we will just show user a generic message because if the hostedField values is not
    // valid, we don't want to call the submit button otherwise we will get errors in the catch
    // let isFormInvalid = Object.values(hostedFields.cardFields.getState().fields).some(
    //   (field: any) => !field.isValid
    // );

    let isFormInvalid = false;
    const formState = await hostedFields.cardFieldsForm.getState();
    if (!formState.isFormValid) {
      isFormInvalid = true;
    }

    // if the form is invalid, in this case we wanted to show the alert on the screen and don't
    // excute the function further
    if (isFormInvalid) {
      setFixableErrors(true);
      setErrCode(ErrorsClickingOnSubmitButton.FixableInvalidCardFields);
      return;
    }

    // if the form is valid then we hide any previous errors that occurred, set the button loading
    // state. note that we do NOT attempt to capture the payment here; instead, that occurs
    // in the onApprove of the PayPalCardFieldsProvider
    setFixableErrors(false);
    setIsLoading(true);
    hostedFields.cardFieldsForm.submit().then(() => {
      // this kicks off our backend api call to capture the user's payment, then another api call
      // to activate their order
      // commitCaptureMutation();

      // based on paypal's recommendation, we do nothing here. we instead rely on the capture
      // payment api call to give us errors that we can display to the user
      // https://github.com/paypal/paypal-js/issues/554#issuecomment-2299181923
    }).catch(() => {
      // do nothing, just make the button active again
      setIsLoading(false);
    });
  };

  return (
    <Grid2Ct sx={{ mt: 1 }}>
      {/* Based on errCode showing the user an appropriate messgae */}
      {fixableErrors && (
      <Grid2 xs={12}>
        <FixableError errCode={errCode} />
      </Grid2>
      )}

      {/* pay button */}
      <Grid2 xs={12} sx={{ mt: { xs: 0, sm: 2 } }}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="accentGreen1"
            onClick={SubmitHandler}
            isLoading={isLoading}
            disabled={isLoading}
            size="large"
          >Buy Lessons!
          </Button>
        </Box>
      </Grid2>
    </Grid2Ct>
  );
};
