/**
 * @generated SignedSource<<83a58513f90e76093781ac02392f8035>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LessonsQuery$variables = {};
export type LessonsQuery$data = {
  readonly subs_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"LessonsGroupclasssubFragment">;
      };
    }>;
  };
};
export type LessonsQuery = {
  response: LessonsQuery$data;
  variables: LessonsQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LessonsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "subsConnection",
        "kind": "LinkedField",
        "name": "subs_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subs",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "LessonsGroupclasssubFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LessonsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "subsConnection",
        "kind": "LinkedField",
        "name": "subs_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subs",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "current_plan",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "current_plan_status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trialstart_ts",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5aebfd2e300c8edab353c8578b9ca55d",
    "id": null,
    "metadata": {},
    "name": "LessonsQuery",
    "operationKind": "query",
    "text": "query LessonsQuery {\n  subs_connection {\n    edges {\n      node {\n        ...LessonsGroupclasssubFragment\n        id\n      }\n    }\n  }\n}\n\nfragment LessonsGroupclasssubFragment on subs {\n  current_plan\n  current_plan_status\n  trialstart_ts\n}\n"
  }
};

(node as any).hash = "c21276f81b621d0a9a1e16d98f6c158b";

export default node;
