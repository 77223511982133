// representation of subscription plans in our database
export interface OurSubPlanDetails {
  dbId: 1 | 2;
  title: string;
  price: number;
}

// representation of the user's current plan. note that this is available in our relay store
// in users.relaySubscription. we should use that when getting the user's plan anywhere
// in the app
export interface CurrentSubscriptionTy {
  // which subscription plan the user has
  plan: SubscriptionPlanTy;
  // the status of their subscription
  status: SubscriptionStatusTy;
  // tells us if the user can try subscription free trial. 
  canDoTrial: boolean;
  // if the user is subscribed to group classes, they get an hourly discount on private lessons.
  // privateDiscountGets tells us if the user gets a discount (true if they do), and then
  // privateDiscountAmt tells us the amount of hourly discount they get; it will be 0 if 
  // privateDiscountGets is false
  privateDiscountGets: boolean;
  privateDiscountAmt: number;
}

// the subscription plan the user has (or of course may not be subscribed)
export enum SubscriptionPlanTy {
  // we don't know if the user has a subscription or not, meaning we haven't yet gotten the
  //  necessary data from the db to tell us 
  Unknown = 'Unknown',
  NoPlan = 'NoPlan', // user does not have a subscription
  Basic = 'Basic', // our basic, $10/month plan that lets user take 1 class per week
  Premium = 'Premium', // our $30/month plan, unlimited classes
}

// the overall status of the user's plan
export enum SubscriptionStatusTy {
  // we don't know the status of the user's subscription yet, meaning we haven't yet gotten
  //  the necessary data from the db to tell us
  Unknown = 'Unknown',
  NoPlan = 'NoPlan', // user does not have a subscription, so their status is this
  Trialing = 'Trialing', // current_plan_status = 1
  Active = 'Active', // current_plan_status = 2
  PastDue = 'PastDue', // current_plan_status = 3
  Incomplete = 'Incomplete' // current_plan_status = 4
}
