import * as Sentry from '@sentry/react';

// #region create reservation functions

// error codes that can occur when making the create reservation api call
export enum CreateReservationErrorCodes {
  GeneralError = 'GeneralError',
  LessonTooFarInAdvance = 'LessonTooFarInAdvance',
  StudentBlockedFromGroupLessons = 'StudentBlockedFromGroupLessons',
  LessonNoLongerExists = 'LessonNoLongerExists',
  TooManyReservationsThisLesson = 'TooManyReservationsThisLesson',
  NoActiveSubscription = 'NoActiveSubscription',
  ReserveLimitReached = 'ReserveLimitReached',
  LessonEndsSoon = 'LessonEndsSoon',
  OtherLessonOverlap = 'OtherLessonOverlap',
  LessonFull = 'LessonFull',
  LimitReachedForCurrentWeek = 'LimitReachedForCurrentWeek',
}

// handle erorrs that occur when user attempts to create a reservation
export const createReservationErrorHandle = (
  err: any,
  setResrvModalState: any,
  setResrvModalError: any,
) => {
  try {
    const error = JSON.parse(err.message);
    const errCode = error.extensions.code;

    if (errCode === CreateReservationErrorCodes.GeneralError) {
      setResrvModalError(CreateReservationErrorCodes.GeneralError);
      setResrvModalState(true);
    } else if (errCode === CreateReservationErrorCodes.LessonTooFarInAdvance) {
      setResrvModalError(CreateReservationErrorCodes.LessonTooFarInAdvance);
      setResrvModalState(true);
    } else if (errCode === CreateReservationErrorCodes.StudentBlockedFromGroupLessons) {
      setResrvModalError(CreateReservationErrorCodes.StudentBlockedFromGroupLessons);
      setResrvModalState(true);
    } else if (errCode === CreateReservationErrorCodes.LessonNoLongerExists) {
      setResrvModalError(CreateReservationErrorCodes.LessonNoLongerExists);
      setResrvModalState(true);
    } else if (errCode === CreateReservationErrorCodes.TooManyReservationsThisLesson) {
      setResrvModalError(CreateReservationErrorCodes.TooManyReservationsThisLesson);
      setResrvModalState(true);
    } else if (errCode === CreateReservationErrorCodes.NoActiveSubscription) {
      setResrvModalError(CreateReservationErrorCodes.NoActiveSubscription);
      setResrvModalState(true);
    } else if (errCode === CreateReservationErrorCodes.ReserveLimitReached) {
      setResrvModalError(CreateReservationErrorCodes.ReserveLimitReached);
      setResrvModalState(true);
    } else if (errCode === CreateReservationErrorCodes.LessonEndsSoon) {
      setResrvModalError(CreateReservationErrorCodes.LessonEndsSoon);
      setResrvModalState(true);
    } else if (errCode === CreateReservationErrorCodes.OtherLessonOverlap) {
      setResrvModalError(CreateReservationErrorCodes.OtherLessonOverlap);
      setResrvModalState(true);
    } else if (errCode === CreateReservationErrorCodes.LimitReachedForCurrentWeek) {
      setResrvModalError(CreateReservationErrorCodes.LimitReachedForCurrentWeek);
      setResrvModalState(true);
    } else if (errCode === CreateReservationErrorCodes.LessonFull) {
      setResrvModalError(CreateReservationErrorCodes.LessonFull);
      setResrvModalState(true);

    // an unknown error occurred. log, but still have the modal display. in the modal
    // we display a note so the user knows *something* went wrong
    } else {
      // log unkonwn errors
      Sentry.captureException(
        new Error('createReservationErrorHandle detected an unknown create reservation error'),
        {
          extra: {
            errorCode: err,
          }
        }
      );
      setResrvModalError('unknown');
      setResrvModalState(true);
    }
  } catch (e) {
    Sentry.captureException(
      e,
      {
        extra: {
          note: 'An unexpected error occurred in createReservationErrorHandle',
        }
      }
    );
    setResrvModalError('unknown');
    setResrvModalState(true);
  }
};

// #endregion

// #region cancel reservation functions

// error codes that can occur when making the cancel reservation api call
export enum CancelReservationErrorCodes {
  GeneralError = 'GeneralError',
  LessonAlreadyStarted = 'LessonAlreadyStarted',
  LessonNotReserved = 'LessonNotReserved',
}

// handle errors that occur when user attempts to cancel a reservation
export const cancelReservationErrorHandle = (
  err: any,
  setCancelModalState: any,
) => {
  try {
    const error = JSON.parse(err.message);
    const errCode = error.extensions.code;

    // if any known error occurred, just display the modal that tells the student
    // something went wrong with their cancelation
    if (errCode === CancelReservationErrorCodes.GeneralError
      || errCode === CancelReservationErrorCodes.LessonAlreadyStarted
      || errCode === CancelReservationErrorCodes.LessonNotReserved
    ) {
      setCancelModalState(true);

    // an unknown error occurred. log, but still have the modal display
    } else {
      // log unkonwn errors
      Sentry.captureException(
        new Error('cancelReservationErrorHandle detected an unknown cancel reservation error'),
        {
          extra: {
            errorCode: errCode,
          }
        }
      );
      setCancelModalState(true);
    }
  } catch (e) {
    Sentry.captureException(
      e,
      {
        extra: {
          note: 'An unkown error occurred in cancelReservationErrorHandle',
        }
      }
    );
    setCancelModalState(true);
  }
};

// #endregion
