import React from 'react';
import {
  Grid2Ct, Grid2, Ty, Card, Box
} from '@languageconvo/wcl';
import { VideoPlaceholderImage } from './PurchaseHowSubsWork.style';

interface Props {
  isSubscribed: boolean;
  handleModalOpenGroup: any;
}

export const PurchaseHowSubsWork = ({ isSubscribed, handleModalOpenGroup }: Props) => {
  // image for the video thumbnail
  const imgUrl = `${process.env.REACT_APP_IMAGESCDNURL}/uis/components/HowConversationalClassesWork1.png`;

  // if the user is subscribed, don't show conversational class box
  if (isSubscribed) {
    return (null);
  }

  return (
    <Grid2Ct sx={{ mt: 1 }}>
      <Grid2 xs={12}>
        <Card onClick={handleModalOpenGroup} cp={{ sx: { cursor: 'pointer' } }}>
          <Box sx={{
            width: '100%', pt: '20px', pb: '20px', pl: '20px', pr: '20px'
          }}
          >
            {/* this flex aligns the contents vertically centered */}
            <Grid2Ct sx={{ display: 'flex', alignItems: 'center' }}>
              {/* text. width 100% needed when using flex */}
              <Grid2 xs={12} sm={6} sx={{ width: '100%' }}>
                <Ty removeMb align="center">
                  Click here to learn all about bite-sized conversational classes!
                </Ty>
              </Grid2>

              {/* image. width 100% needed when using flex. justifyContent centers the image
                horizontally */}
              <Grid2
                xs={12}
                sm={6}
                sx={{
                  pt: 0, pb: 0, display: 'flex', justifyContent: 'center', width: '100%'
                }}
              >
                <VideoPlaceholderImage src={imgUrl} alt="How Conversationl Classes Work" />
              </Grid2>
            </Grid2Ct>
          </Box>
        </Card>
      </Grid2>
    </Grid2Ct>
  );
};
