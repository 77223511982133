/**
 * @generated SignedSource<<5c8d74d7f9c198e4282a519d80f1715a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PurchasePaySubsdtFragment$data = {
  readonly relaySubscription: ReturnType<typeof usersRelaySubscriptionResolverType> | null;
  readonly " $fragmentType": "PurchasePaySubsdtFragment";
};
export type PurchasePaySubsdtFragment$key = {
  readonly " $data"?: PurchasePaySubsdtFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PurchasePaySubsdtFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PurchasePaySubsdtFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "relaySubscriptionResolver"
      },
      "kind": "RelayResolver",
      "name": "relaySubscription",
      "resolverModule": require('./../../../../../../../common/relay/resolvers/users/relaySubscription').relaySubscription,
      "path": "relaySubscription"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "0b61b327bfcec9834c41b8745c9c8c6e";

export default node;
