import {
  styled, Box, WCLThemeProps,
} from '@languageconvo/wcl';
import { SmallcardMaxWidthContainWidths } from '../../common/PurchaseCommon';

export const ChoosePackageContainer = styled(Box)(({ theme }: WCLThemeProps) => ({
  width: '100%',
  maxWidth: SmallcardMaxWidthContainWidths.smUp,
  [theme!.breakpoints.only('xs')]: {
    maxWidth: SmallcardMaxWidthContainWidths.xs,
  },
  padding: '14px 20px 14px 20px',
  borderRadius: theme?.ourTheme.borders.borderRadius.medium,
  borderStyle: 'solid',
  borderWidth: '0.8px',
  borderColor: theme?.ourTheme.colors.cp.divider.thin,
  // background color
  ...(theme?.themeMode === 'light' && {
    backgroundColor: `hsla(${theme?.ourTheme.colors.app.page}, .7)`,
  }),
  ...(theme?.themeMode === 'dark' && {
    backgroundColor: `hsla(${theme?.ourTheme.colors.app.page}, .6)`,
  }),
  '&:hover': {
    cursor: 'pointer',
    borderColor: theme?.ourTheme.colors.cp.divider.base,
  },
}));
