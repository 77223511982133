import React, {
  useEffect, useState
} from 'react';
import { useFragment } from 'react-relay';
import { DateTime } from 'luxon';
import { Step3Display } from './Step3Display';
import { GroupLessonsFragment } from '../../relay/GroupLessons';
import { DaysSelector, calculateAndSetDaysSelector } from '../../GroupSchedule2';

interface Props {
  fragmentRef: any;
  setEndOfDay: any;
  tsSettings: any;
  daysSelector: DaysSelector;
  setDaysSelector: any;
  isSubscribed: boolean;
}

export const Step2DisplaySetup = ({
  fragmentRef,
  setEndOfDay,
  tsSettings,
  daysSelector,
  setDaysSelector,
  isSubscribed,
}: Props) => {
  // currentTime is used extensively here and in child components. we use a setInterval to update
  // it every 5 seconds. it's value is...the current time!
  const [currentTime, setCurrentTime] = useState(DateTime.now());

  // reading data from relay store.
  const storeLessonsData = useFragment(GroupLessonsFragment, fragmentRef);
  const rawData = storeLessonsData.appt_group_connection.edges;
  const groupLessons = filterPastLessons({
    lessons: rawData,
    currentTime,
  });

  /* Every 5 seconds, we do a few very important things:
      - Update the currentTime
      - Check to see if the end of the day has occurred
  */
  useEffect(() => {
    const interval = setInterval(() => {
      // set our react state currentTime var to now
      const currTime = DateTime.now();
      setCurrentTime(() => currTime);

      // Here we check to see if the current day has ended. If it has, we'll
      // set our "endOfDay" state var; that will cause the subscription to
      // re-run (and get +1 extra day in future of lessons). We also call
      // our method to update the day selector in the header.
      //  Note: setInterval's closure only accesses the time variable in the first render,
      //  it doesn't have access to the new time value in the subsequent render
      //  because the useEffect() is not invoked the second time. That's why, we need a
      //  callback inside setEndOfDay that will update the state
      setEndOfDay((prevTime: any) => {
        // the "endOfDay" state var holds the time of the end of teh current day. so if
        // the time now is greater than that, a new day has started.
        if (currTime > prevTime) {
          calculateAndSetDaysSelector(0, daysSelector, setDaysSelector);
          return DateTime.now().endOf('day');
        }
        return prevTime;
      });
    }, 5000);

    // reset interval on component unmount.
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Step3Display
      groupLessons={groupLessons}
      currentTime={currentTime}
      tsSettings={tsSettings}
      daysSelector={daysSelector}
      isSubscribed={isSubscribed}
    />
  );
};

// #region help functions

interface FilterPastLessons {
  lessons: any
  currentTime: any
}
const filterPastLessons = ({
  lessons,
  currentTime,
}: FilterPastLessons) => {
  if (lessons.length) {
    const data = lessons.filter((item: any) => {
      const lessonEndTime = DateTime.fromISO(item.node.ends_at);
      if (lessonEndTime >= currentTime) {
        return true;
      }
      return false;
    });
    return data;
  }
  return [];
};

// #endregion
